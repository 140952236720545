@import '../../main.module.scss';

.icon-cell {
  text-overflow: initial;
}

.container {
  @include breakpoint(laptop-and-up) {
    width: 100%;
  }

  padding-bottom: 200px;
}

.selection-note {
  color: $default-text-color;
  font-size: $regular-text-size;
  font-weight: $default-font-weight;
  margin-left: 108px;
  padding-top: 28px;
  min-height: 20px;
  position: relative;

  & > span {
    color: $link-color;
    cursor: pointer;
  }

  .selected-check {
    left: -31px;
    position: absolute;
    top: 30px;
    width: 18px;
  }
}

.enterprises {
  margin-top: 22px;
}

@include breakpoint(phone) {
  .enterprises {
    margin-top: 0;
  }
}

.grid {
  padding-bottom: 50px;
  padding-left: 108px;
  width: 1048px;
}

@include breakpoint(laptop-and-down) {
  .grid {
    width: 900px;
  }
}

.options-container {
  display: flex;
  height: 66px;
  justify-content: space-between;
  margin-left: 106px;
  margin-right: 108px;
  margin-top: 34px;
}

.options-container > * {
  display: inline-block;
  vertical-align: top;
}

.table {
  margin-left: 108px;
  margin-right: 41px;
}

.switcher {
  @include breakpoint(tablet-and-down) {
    display: none;
  }

  float: right;
}

@include breakpoint(phone-large-and-down) {
  .selection-note {
    margin-left: 48px;
  }

  .options-container {
    margin-left: 16px;
    margin-right: 16px;
  }

  .table {
    margin-left: 16px;
    margin-right: 16px;
  }

  .text-row > div > div {
    justify-content: left;
  }
}

.no-search-results-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 88px;
  padding-top: 60px;
  text-align: center;
  width: 50%;

  h2 {
    color: $focused-text-color;
    font-size: $large-text-size;
    font-weight: 400;
    height: 24px;
    line-height: 24px;
    margin: 0;
    position: relative;
    top: -41px;
  }
}

.search-input {
  max-width: 265px;
}

.enterprises-bg {
  background: url('/assets/public/enterprises-bg.svg') no-repeat 50% 100%;
  background-size: cover;
}

/* stylelint-disable */
:export {
  laptopMinWidth: 960;
  laptopMax: 1399;
}
/* stylelint-enable */
