@import '../../../../../../main.module';

.radio {
  box-sizing: content-box;
  z-index: inherit;
}

.title-padding {
  margin-bottom: 25px;
}

.description {
  color: $label-color;
  font-size: $xs-text-size;
  line-height: 17px;
  margin-bottom: 20px;
}

.audio-area {
  padding-bottom: 1.5em;
  padding-top: 1.5em;
}

.small-title {
  color: $default-text-color;
  font-size: $regular-text-size;
  font-weight: $title-font-weight;

  &--disabled {
    color: $disabled-input-color;
  }
}
