@import '../../main.module.scss';

.row {
  border-bottom: $global-border-normal solid $color-smoke;
  min-height: 60px;
  overflow: hidden;
  position: initial;
}

.action-cell {
  align-self: center;
}

.name-container {
  width: 100%;
}

.user-container {
  float: left;
  max-width: 240px;
  width: 22%;
}

.description {
  margin-bottom: 17px;
}

.user-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
