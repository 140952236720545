@import '../../../main.module';

.activity-status {
  float: right;
  margin-right: 10px;
}

.activity-status-text {
  color: $label-color;
  float: left;
  margin-right: 10px;
  margin-top: -3px;
}
