@import '../../../main.module.scss';

.top {
  position: fixed;
  width: 100%;
  z-index: 100;

  & .button-area {
    position: fixed;
    right: 24px;
    top: 55px;
    width: 45px;

    @include breakpoint(tablet-and-down) {
      top: 115px;
    }

    & .edit-button {
      margin-left: 0;
    }
  }

  .buttons-bar {
    height: 20px;
    position: absolute;
    z-index: 1;
  }

  .title-menu {
    cursor: pointer;
    height: 8px;
    position: fixed;
    right: 35px;
    top: 8px;

    @include breakpoint(tablet-and-down) {
      top: 68px;
    }

    @include breakpoint(phone) {
      right: 20px;
    }

    svg {
      & path {
        fill: $white;
      }
    }
  }

  .background {
    background-color: $focused-text-color;
    height: 51px;
    left: -17px;
    position: absolute;
    top: -16px;
    width: 100%;
  }

  .user-name {
    color: $white;
    font-size: $small-title-text-size;
    font-weight: $title-font-weight;
    line-height: 33px;
    overflow: hidden;
    padding-left: 30px;
    position: absolute;
    text-align: center;
    text-overflow: ellipsis;
    top: -8px;
    white-space: nowrap;
    width: 360px;

    @include breakpoint(phone) {
      font-size: $xs-title-text-size;
      margin-left: -20px;
      padding-left: 0;
      width: 100%;
    }
  }
}
