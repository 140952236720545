@import '../../main.module.scss';

.row {
  min-height: 60px;
  position: relative;
}

.action-cell {
  border: $global-border-normal solid $color-smoke;
  border-width: 0 0 1px;
  margin-left: 2px;
  padding-bottom: 38px;
}
