@import '../../../../main.module.scss';

.group-priority-selector {
  text-align: left;

  & .title-area {
    display: flex;
    padding-bottom: 17px;
  }

  & .title {
    font-size: $regular-text-size;
    font-weight: $title-font-weight;
    padding-right: 20px;
  }

  & .in-use-field {
    display: flex;
  }

  & .group-priority-not-in-use {
    padding-bottom: 12px;
  }

  & .input-field {
    margin-bottom: 0;
    margin-top: -7px;
    padding-left: 10px;
    width: 115px;
  }

  :global(.ea-input__addon--auto-status) {
    display: none;
  }

  .radio {
    box-sizing: content-box;
  }
}
