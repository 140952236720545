@import '../../main.module.scss';

.enterprise-container {
  padding-bottom: 600px;
  padding-right: 10em;

  .top-section {
    border-bottom: 2px solid $border-color;
    border-top: 2px solid $border-color;
    height: 214px;
  }

  .content {
    margin-left: 107px;
    padding-top: 58px;
  }

  .location-deleted-failure {
    background-color: $error-background-color;
  }

  .title-container {
    color: $section-title-color;
    font-size: $medium-title-text-size;
    font-weight: $title-font-weight;
    height: 115px;

    img {
      float: left;
      margin-right: 44px;
    }

    .container {
      display: table;
      height: 115px;
    }

    table {
      display: table-cell;
      vertical-align: middle;
    }

    .button-cell {
      vertical-align: bottom;
    }
  }

  .divider {
    border-bottom: 1px solid $border-color;
  }

  .sub-section {
    margin-bottom: 49px;
    margin-top: 95px;
  }
}

.enterprise-bg {
  background: url('/assets/public/enterprise-bg.svg') no-repeat 53% 100%;
  background-size: cover;
}
