@import '../../main.module.scss';

/* Online First wants to modify React Patterns components styles. This is unnecessary for WIRI-UI
 since we're not going to view the list below tablet width at the moment. */
.header {
  display: none;

  :focus {
    outline: 0;
  }

  .ea-input {
    margin: -1px 0 0;
    padding: 0;
    width: auto;

    .ea-input__wrapper {
      background-color: transparent;
      border: 0 none;
      margin-right: 16px;

      .ea-input__input {
        padding: 0;

        span {
          color: $color-grey;
          font-size: 13px;
        }
      }
    }

    .ea-combobox {
      cursor: pointer;

      &--dropdown {
        .ea-combobox__input::after {
          font-size: 16px;
        }
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@include breakpoint(laptop-and-up) {
  .header {
    border: 0 none;
    color: $default-text-color;
    display: flex;
    font-size: $xs-text-size;
    font-weight: $title-font-weight;
    margin-bottom: 0;
    padding: 0;

    .cell-content {
      min-height: 24px;
    }

    .first {
      padding-left: 5px;
    }

    .cell-container {
      flex-basis: auto;
      flex-grow: 1;
      margin-top: $global-baseline * 1.5;
      min-width: 78px;
      padding-bottom: $global-baseline * 1.5;

      &.border {
        border-bottom: $global-border-normal solid $color-smoke;
      }

      &.x-small {
        max-width: 60px;
        min-width: 60px;
      }

      &.small {
        max-width: 78px;
      }

      &.medium {
        flex-basis: 15%;
      }

      &.large {
        flex-basis: 25%;
      }

      &.x-large {
        flex-basis: 30%;
      }

      &.last {
        padding-right: 0;
      }

      & > div {
        span {
          display: inline-block;
          line-height: 13px;
          margin-left: $global-baseline / 2;

          &::before {
            height: 13px;
            width: 13px;
          }
        }
      }

      &.sortable {
        cursor: pointer;

        &:hover {
          span {
            visibility: visible;
          }
        }
      }
    }
  }
}

.hidden {
  visibility: hidden;
}

.flipped {
  transform: scaleY(-1);
}

.font-reset {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.table-order {
  &::before {
    background: transparent url("/assets/public/ea-icon-table-order.svg") center center no-repeat;
    background-size: contain;
    content: "";
    display: inline-block;
    height: 25px;
    margin-right: 1.5rem;
    vertical-align: middle;
    width: 25px;
  }
}
