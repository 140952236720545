@import '../../main.module';

.title {
  font-weight: $xl-title-font-weight;
}

.target-selection {
  width: 18em;
}

.forwarding-selection {
  .search-area {
    width: 22em;
  }

  :global(.ds-combobox--input-clear) {
    visibility: hidden;
  }
}
