@import '../../main.module.scss';

.modal {
  width: 80%;
  max-height: 80%;
  margin: auto;
  overflow: auto;
}

.onboarding-container {
  overflow: auto;
  padding: 2em;

  .bottom-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2em;
  }

  .checkbox {
    &--text {
      color: $default-text-color;
    }
  }

  .large-content {
    font-size: $small-title-text-size;
    font-weight: 300;
    padding-top: 30px;
    text-align: start;
    width: 100%;
  }

  .button-area {
    margin-top: 20px;
    text-align: center;
    width: 100%;
    display: flex;

    .paging-text {
      padding: 1em;
    }

    .onboarding-button {
      font-size: $xs-text-size;
    }
  }
}
