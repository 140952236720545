@import '../../../../main.module';

.container {
  z-index: 101;

  .content {
    background-color: $white;
    border: 2px solid $border-color;
    border-radius: 2px;
    box-shadow: 0 0 0 9999px $light-shadow-color, 0 0 15px $light-shadow-color;
    box-sizing: border-box;
    height: auto;
    left: 10%;
    margin-top: -8px;
    padding-right: 15px;
    padding-top: 15px;
    position: fixed;
    top: 10%;
    z-index: 102;
    width: 850px;

    @include breakpoint(phone) {
      left: 0;
    }
  }
}

.error {
  color: $invalid-data-color;
}

.subheader {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 5px;
}

.infotext {
  font-weight: 100;
  margin-top: 5px;
  margin-bottom: 5px;
}

.flex-container {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  column-gap: 34px;
}

.legend-container {
  span {
    display: inline-block;
  }
}

.legend-marker {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 5px;
}

.legend-marker-selected-date {
  background-color: $link-color;
}

.legend-marker-holiday {
  background-color: #ffd3dd;
}

.legend-marker-unofficial-holiday {
  box-shadow: 0 0 0 2px #ffd3dd;
}

.legend-marker-exceptional-hours {
  background-color: #ffce85;
}

.legend-marker-current-date {
  box-shadow: 0 0 0 2px $link-color;
}

.box {
  padding-bottom: 30px;
  padding-left: 23px;
  padding-top: 20px;

  p {
    &:last-of-type {
      margin-bottom: 25px;
      overflow-x: hidden;
    }
  }

  .buttons {
    padding-top: 10px;
    text-align: right;
  }
}
