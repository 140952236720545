@import '../../../main.module.scss';

.container {
  height: 65px;
  margin-left: 106px;
  max-width: 1047px;
}

.header-area {
  @include breakpoint(tablet-and-up) {
    display: flex;
    width: 100%;
  }
}

.header-container {
  display: block;
  height: 180px;
  padding-bottom: 25px;
  padding-top: 25px;
}

.title {
  color: $default-text-color;
  font-family: $default-font;
  font-size: $small-title-text-size;
  font-weight: $default-font-weight;
  line-height: 36px;
  margin-bottom: 11px;
  margin-top: 0;
}

.description {
  float: left;
}

.content-container {
  display: block;
  padding-bottom: 200px;
}

.search-field {
  width: 320px;
}

.create-button {
  font-size: 1rem;
}
