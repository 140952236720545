@import '../../main.module.scss';

.enterprise-name {
  font-family: $large-heading-font;
  font-size: 17px !important;
}

a[href="/adminui"] > div {
  color: red;
}

:global(.ds-header .ds-navigation-top-bar) {
  padding-bottom: 1em;
  padding-top: 1em;
}

.priority-bulletin {
  & p,
  h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 55vw;

    @include breakpoint(laptop-and-down) {
      max-width: 60vw;
    }

    @include breakpoint(phone-large-and-down) {
      max-width: 80vw;
    }
  }
}

.page-introduction-button {
  position: absolute !important;
  right: 0;
  margin: 10px;

  & span {
    margin-right: 10px;
  }
}
