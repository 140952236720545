@import '../../main.module.scss';

.input-container-top {
  @include breakpoint(phone-large-and-down) {
    max-width: 300px;
  }

  margin-top: 30px;
}

.input-container-bottom {
  @include breakpoint(phone-large-and-down) {
    max-width: 300px;
  }

  margin-top: 20px;
}
