@import '../../../../../../main.module';

.title {
  font-weight: $title-font-weight;
}

.audio-field {
  align-items: flex-start;
}

.description {
  color: $label-color;
  font-size: $xs-text-size;
  line-height: 17px;
  margin-bottom: 20px;
}

.small-title {
  color: $default-text-color;
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
}

.audio-container {
  margin-bottom: 20px;
}

.add-button {
  margin-bottom: 40px;
}
