@import '../../main.module.scss';

.container {
  background: url('/assets/public/404-bg.svg') no-repeat center;
  background-size: cover;
  height: 100vh;
}

.error {
  color: $focused-text-color;
  left: 10%;
  position: absolute;
  top: 7%;

  h1 {
    display: block;
    font-family: $large-heading-font;
    font-size: $xl-title-text-size;
    font-weight: 900;
    line-height: 91px;
    margin-bottom: 8px;
  }

  h2,
  span {
    display: block;
    font-size: $small-title-text-size;
    font-weight: normal;
    line-height: 33px;
    margin-bottom: 26px;
    margin-top: 0;
  }

  span {
    cursor: pointer;
    margin-top: -25px;
    text-decoration: underline;
  }

  p {
    display: block;
    font-size: $large-text-size;
    font-weight: $title-font-weight;
    line-height: 24px;
    margin-top: 0;
  }
}
