@import '../../../main.module.scss';

.container {
  @include breakpoint(tablet-and-down) {
    height: 130px;
  }

  @include breakpoint(phone-large-and-down) {
    height: 86px;
    width: 25px;
  }

  background-color: $focused-text-color;
  cursor: pointer;
  height: 204px;
  opacity: 0.25;
  position: absolute;
  width: 51px;
  z-index: 75;

  .arrow {
    @include breakpoint(tablet-and-down) {
      top: 46px;
    }

    @include breakpoint(phone-large-and-down) {
      font-size: 20px;
      left: 3px;
      top: 32px;
    }

    color: $white;
    font-size: 34px;
    left: 7px;
    position: relative;
    top: 85px;
    z-index: 76;
  }
}
