@import 'src/main.module';
@import 'src/scenes/users/UserDetails/Internal/InternalUserDetails.module';

.container {
  &--open {
    padding: 0;
  }
}

.info {
  padding-left: 30px;
  width: 100%;

  & .field-area {
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    padding-top: 33px;
  }

  & .edit-area {
    display: flex;
    justify-content: center;
    margin-left: -300px;
    padding-bottom: 73px;

    & .field-area {
      margin-top: 33px;
    }

    @include breakpoint(tablet-and-down) {
      flex-flow: column wrap;
      margin-left: 0;
      padding-top: 25px;

      & .avatar-container {
        text-align: center;
      }
    }

    & .button-area {
      background-color: $light-background-color;
      bottom: 0;
      box-shadow: 0 3px 22px 0 $light-shadow-color;
      padding-bottom: 6px;
      padding-right: 250px;
      padding-top: 6px;
      position: fixed;
      text-align: right;
      width: 100%;
      z-index: 101;

      & .cancel-button {
        background-color: $light-background-color;
        padding-right: 40px;
      }

      @include breakpoint(tablet-and-down) {
        padding-right: 33px;
      }
    }
  }

  &--open {
    padding-left: 0;
  }

  & .form-section {
    padding-left: 0;
    padding-right: 2em;
    overflow-x: hidden;

    & h2 {
      padding-bottom: 20px;
    }

    & :global(.ea-input) {
      margin-bottom: 28px;
      width: 406px;

      @include breakpoint(phone) {
        width: 100%;
      }
    }

    @include breakpoint(phone) {
      width: 100%;
    }
  }
}

.remove-button {
  margin-top: 52px;
}
