@import '../../../main.module.scss';

.search {
  box-sizing: initial;
  float: right;

  & .search-input {
    width: 15em;
  }
}

.spinner {
  margin-top: 50px;
}

.section-title {
  color: $default-text-color;
  font-family: $default-font;
  font-size: $small-title-text-size;
  font-weight: $default-font-weight;
  line-height: 36px;
  margin-bottom: 0;
  margin-top: 0;
}

.button-row {
  float: right;
  margin-top: 50px;
  padding-bottom: 4px;
  padding-top: 4px;
}

.box-button {
  margin-left: 10px;
  margin-right: 10px;
}

:global(.ds-search__input) {
  padding-right: 16px;
}

:global(.ds-search__options[data-show="true"]) {
  z-index: 100;
}
