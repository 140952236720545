@import '../../main.module.scss';

.link {
  color: unset;
}

$padding: $breakpoint-phone-min-margin;

.row {
  border: $global-border-normal solid $color-smoke;
  border-width: 0 0 1px;
  min-height: 60px;
  position: relative;

  .hover-button-container {
    align-items: center;
    display: flex;
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;

    .hover-button {
      background-color: $white;
      color: $link-color;
      cursor: pointer;
      display: none;
      font-weight: $title-font-weight;
      line-height: 24px;
      padding: 10px;
      pointer-events: initial;
      text-transform: uppercase;
    }
  }

  .check-container {
    display: flex;
    height: 100%;
    left: -30px;
    position: absolute;

    .check {
      display: none;
    }
  }

  &.hover-enabled {
    &:hover {
      color: $link-color;
    }

    &:hover > .check-container > .check {
      display: block;
    }

    &:hover > .hover-button-container > .hover-button {
      display: block;
    }
  }
}

.row > .cells {
  display: flex;
  margin-bottom: 0;
  padding: 0;

  div {
    .label {
      color: $color-grey;
    }

    :first-child {
      hyphens: auto;
      word-break: break-all;
    }
  }

  .emphasized {
    font-weight: bold;
  }

  .first {
    padding-left: 5px;
  }

  .cell {
    .ea-icon {
      ::before {
        height: 25px;
        left: $global-baseline * 2;
        position: absolute;
        top: $global-baseline * 1.5;
        width: 25px;
      }
    }
  }
}

@include breakpoint(tablet-and-down) {
  .row {
    border: 0;
  }
}

@include breakpoint(phone-small-and-up) {
  .row > .cells {
    display: flex;
    flex-flow: wrap;
    padding: 14px 0 0;

    & > div {
      cursor: default;
      flex-grow: 1;
      margin-bottom: 0;
      min-width: 78px;
      padding: 0;

      &.x-small {
        max-width: 60px;
        min-width: 60px;
      }

      &.small {
        max-width: 78px;
      }

      &.medium {
        flex-basis: 60%;
      }

      &.large {
        flex-basis: 40%;
      }

      &.x-large {
        flex-basis: 60%;
      }
    }
  }
}

@include breakpoint(tablet-and-up) {
  .row > .cells {
    display: flex;
    flex-flow: wrap;
    padding: 10px 0 0;

    & > div {
      cursor: default;
      flex-grow: 1;
      margin-bottom: 0;
      min-width: 78px;
      padding: 0;

      &.x-small {
        max-width: 60px;
        min-width: 60px;
      }

      &.small {
        max-width: 78px;
      }

      &.medium {
        flex-basis: 80%;
      }

      &.large {
        flex-basis: 40%;
      }

      &.x-large {
        flex-basis: 60%;
      }
    }
  }
}

@include breakpoint(laptop-and-up) {
  .row > .cells {
    border: 0 none;
    color: $color-dark-grey;
    display: flex;
    margin-bottom: 0;
    padding: 0;

    &:focus {
      outline: 0;
    }

    & > div {
      cursor: default;
      flex-grow: 1;
      margin-bottom: 0;
      min-width: 78px;
      padding: 0;

      &.x-small {
        max-width: 60px;
        min-width: 60px;
      }

      &.small {
        max-width: 78px;
      }

      &.medium {
        flex-basis: 15%;
      }

      &.large {
        flex-basis: 25%;
      }

      &.x-large {
        flex-basis: 30%;
      }
    }
  }

  .cell {
    display: flex;
    overflow-x: hidden;
    white-space: nowrap;
  }

  .cell-content {
    display: inline-block;
    line-height: $global-baseline * 2;
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
    width: 100%;

    .ea-icon {
      padding-left: $global-baseline * 2 + 25;
    }

    .icon-after {
      padding-right: 25px;

      & + span {
        position: relative;

        &::before {
          left: -25px + $global-baseline;
          top: 25px - $global-baseline * 2;
        }

        .ea-icon--northeast-arrow {
          &::before {
            height: 13px;
            left: -25px + $global-baseline;
            top: 4px;
            width: 13px;
          }
        }
      }
    }
  }
}
