@import '../../../../../../main.module.scss';

.weekCalendar {
  background-color: $white;
  position: relative;
  box-sizing: border-box;
  width: 800px;
  height: 100%;
  overflow: auto;
  padding-left: 125px;
  padding-top: 28px;
  color: $light-gray-color;
  margin-left: -40px;
}

.weekCalendar__header {
  position: absolute;
  height: 28px;
  line-height: 28px;
  z-index: 10;
  top: 0;
  padding-left: 125px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.weekCalendar__scaleHeader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
  width: 125px;
  height: 28px;
  line-height: 28px;
  color: $light-gray-color;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}

.weekCalendar__headerWrapper {
  height: 100%;
  color: $light-gray-color;
}

.weekCalendar__headerColumn {
  height: 100%;
  display: table-cell;
}

.weekCalendar__scaleColumn {
  position: absolute;
  z-index: 9;
  left: 0;
  padding-top: 28px;
  width: 125px;
  text-align: center;
}

.weekCalendar__scaleCell {
  &:first-of-type {
    margin-top: -10px;
  }

  &:nth-of-type(n + 9):not(:nth-of-type(n + 18)) {
    font-weight: bold;
  }
}

.calendarBody__cell {
  border-bottom: 1px solid #e0e0e1;
  box-sizing: border-box;

  &:nth-of-type(odd) {
    background-color: whitesmoke;
    line-height: 20px;
  }
}

.weekCalendar__content {
  position: relative;
  width: auto;
  overflow: hidden;
  max-height: 650px;
}

.weekCalendar__overlay {
  position: absolute;
}

.weekCalendar__overlay_status_selection {
  background-color: rgb(249 105 14 / 20%);
}

.weekCalendar__status_selection .weekCalendar__overlay {
  pointer-events: none;
}

.calendarBody {
  position: relative;
  display: table;
  table-layout: fixed;
  min-width: 100%;
}

.calendarBody__row {
  display: table-row;
}

.calendarBody__column {
  display: table-cell;
  border-top: 1px solid $content-border-color;
  border-right: 1px solid $content-border-color;
  background-color: $light-background-color;

  &:first-of-type {
    border-left: 1px solid $content-border-color;
  }
}

.dayCell {
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dayCell:hover {
  cursor: pointer;
  background-color: $selected-detail-close-color;
}

.event {
  text-align: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 13px;
  background-color: #006dfc;
  border: 1px solid $light-background-color;
  border-radius: 4px;
}

.calendarModal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: auto;
  z-index: 100;
}

.calendarModal__backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 60%);
}

.calendarModal__content {
  position: fixed;
  top: 250px;
  left: 350px;
  margin: auto;
  border: 1px solid #b7b7b7;
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
}

.customModal__button {
  display: inline-block;
  padding: 3px 6px;
  color: #fff;
  border: 1px solid $link-color;
  margin-bottom: 5px;
  background-color: $link-color;
}

.customModal__button:focus,
.customModal__button:hover {
  cursor: pointer;
  background-color: rgb(249 105 14 / 60%);
}

.customModal__button_float_right {
  float: right;
}
