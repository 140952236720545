@import '../main.module.scss';

svg {
  &.triangle {
    fill: $white;
    overflow: visible;
    position: relative;
  }
}

.triangle-side-line-paths {
  stroke: $border-color;
  stroke-width: 2px;
}

.triangle-bottom-line-path {
  stroke: $white;
  stroke-width: 4px;
}
