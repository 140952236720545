@import '../../main.module.scss';

.contact-hidden-container {
  border-radius: 50%;
  position: absolute;
  height: 24px;
  width: 24px;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 5px;
  left: -12px;
}
