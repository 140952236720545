@import '../../main.module.scss';

.language-button {
  background: none;
  border: 0;
  cursor: pointer;
  font: inherit;
  font-size: $regular-text-size;
  outline: none;
  text-transform: uppercase;

  &:focus {
    outline: 3px solid $hover-color;
  }

  & span {
    font-size: $regular-text-size;
  }
}

.language-button-container {
  color: $light-text-color;
  float: right;
  font-size: $regular-text-size;
  line-height: 18px;

  /* stylelint-disable no-descending-specificity */
  & button > span,
  & span {
    &:last-of-type {
      display: inline;
      font-size: $regular-text-size;
    }
  }
  /* stylelint-enable no-descending-specificity */
}

.active-language {
  color: $focused-text-color;
}
