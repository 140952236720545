@import '../../main.module';

$calendar-color-bank-holiday: #ffd3dd;
$calendar-color-bank-holiday-text: #e0022a;
$calendar-color-unofficial-holiday: transparent;
$calendar-color-unofficial-holiday-border: #ffd3dd;
$calendar-color-unofficial-holiday-text: $default-text-color;
$calendar-color-weekend: #e6e8f0d4;
$calendar-color-highlight: #ffce85;
$calendar-color-highlight-text: $default-text-color;

.calendar-area {
  :global(.react-datepicker) {
    min-width: 22em;
    width: 100%;
    border: 0;
    border-radius: 0;
  }

  :global(.react-datepicker__header) {
    background-color: $white;
    border-bottom: 0;
  }

  :global(.react-datepicker-popper) {
    box-shadow: 1px 2px 6px #00000040;
    border-radius: 8px;
    background-color: white;
  }

  :global(.react-datepicker__input-container > input) {
    line-height: 36px;
  }

  :global(.react-datepicker__calendar-icon) {
    padding-top: 15px;
  }

  :global(.react-datepicker__month) {
    border-bottom: 0;
  }

  :global(.react-datepicker__month-container) {
    box-sizing: initial;
    font-size: $regular-text-size;
    padding-top: 5px;
    width: 100%;
  }

  :global(.react-datepicker__day) {
    height: 1.55em;
    padding-top: 7px;
    padding-bottom: 1px;
    padding-left: 1px;
    padding-right: 1px;
    width: 32px;
    margin: 0.2em;
    border-radius: 5px;
  }

  :global(.react-datepicker__day--holidays) {
    background-color: $calendar-color-bank-holiday;
    color: $calendar-color-bank-holiday-text;
    font-weight: bold;
    border-radius: 50%;
  }

  :global(.react-datepicker__day--unofficial-holiday) {
    background-color: $calendar-color-unofficial-holiday;
    box-shadow: 0 0 0 2px $calendar-color-unofficial-holiday-border;
    color: $calendar-color-unofficial-holiday-text;
  }

  :global(.react-datepicker__day--weekend) {
    background-color: $calendar-color-weekend;
  }

  :global(.react-datepicker__day--highlighted) {
    background-color: $calendar-color-highlight;
    color: $calendar-color-highlight-text;
    border-radius: 50%;
  }

  :global(.react-datepicker__day--outside-month) {
    opacity: 0.2;
  }

  :global(.react-datepicker__current-month) {
    &::first-letter {
      text-transform: uppercase;
    }

    font-size: $xs-title-text-size;
    font-family: "Jost Medium", sans-serif;
  }

  :global(.react-datepicker__navigation) {
    height: 0.5em;
    width: 0.5em;
    overflow: initial;
    top: 40px;
    transform: scale(0.8);
    margin-top: -12px;
  }

  :global(.react-datepicker__navigation--next) {
    right: 3em;
  }

  :global(.react-datepicker__navigation--previous) {
    left: 3em;
  }

  :global(.react-datepicker__navigation-icon::before) {
    border-color: #00239d;
  }

  :global(.react-datepicker__day-names) {
    padding-bottom: 12px;
    padding-top: 1em;
  }

  :global(.react-datepicker__day-name) {
    width: 2.7em;
    font-size: $xs-text-size;
    text-transform: uppercase;
    color: #70707a;
  }

  :global(.react-datepicker__day--in-range) {
    @include breakpoint(phone-large-and-down) {
      margin: 4px 0;
      width: 40px;
    }

    @include breakpoint(tablet-and-up) {
      margin: 15px 0;
      width: 62px;
    }

    background-color: $light-selection-color !important;
    border-radius: 0;
    color: $default-text-color;
    height: 32px;
    padding-bottom: 17px;
    padding-top: 7px;

    &.stretch-date--end {
      &:not(:global(.react-datepicker__day--selecting-range-start)) {
        @include breakpoint(phone-large-and-down) {
          width: 70px !important;
        }

        @include breakpoint(tablet-and-up) {
          width: 92px !important;
        }

        margin-right: -30px !important;
        padding-right: 30px !important;
      }
    }

    &.stretch-date--start {
      @include breakpoint(phone-large-and-down) {
        width: 70px !important;
      }

      @include breakpoint(tablet-and-up) {
        width: 92px !important;
      }

      margin-left: -30px !important;
      padding-left: 30px !important;
    }
  }

  /* stylelint-disable no-descending-specificity */

  :global(.react-datepicker__day--in-selecting-range) {
    @include breakpoint(phone-large-and-down) {
      margin: 4px 0;
      width: 40px;
    }

    @include breakpoint(tablet-and-up) {
      margin: 15px 0;
      width: 62px;
    }

    background-color: $light-selection-color;
    border-radius: 0;
    color: $default-text-color;
    height: 32px;
    padding-bottom: 17px;
    padding-top: 7px;

    &:hover {
      @include breakpoint(phone-large-and-down) {
        margin: 4px !important;
      }

      @include breakpoint(tablet-and-up) {
        margin: 15px !important;
      }

      border-radius: 50%;
      color: $white;
      width: 32px !important;
    }

    &.stretch-date--end {
      &:not(:global(.react-datepicker__day--selecting-range-start)) {
        @include breakpoint(phone-large-and-down) {
          width: 70px !important;
        }

        @include breakpoint(tablet-and-up) {
          width: 92px !important;
        }

        margin-right: -30px !important;
        padding-right: 30px !important;
      }
    }

    &.stretch-date--start {
      &:not(:global(.react-datepicker__day--selecting-range-end)) {
        @include breakpoint(phone-large-and-down) {
          width: 70px !important;
        }

        @include breakpoint(tablet-and-up) {
          width: 92px !important;
        }

        margin-left: -30px !important;
        padding-left: 30px !important;
      }
    }
  }

  /* stylelint-enable no-descending-specificity */

  :global(.react-datepicker__day--today) {
    color: $default-text-color;
    font-weight: $title-font-weight;
    box-shadow: 0 0 0 2px $link-color;
    border-radius: 50% !important;
  }

  :global(.react-datepicker__day--selected) {
    background-color: $link-color;
    border-radius: 50%;
    color: $white;
    font-weight: $title-font-weight;
    height: 1.55em;
  }

  :global(.react-datepicker__day--selected:hover) {
    background-color: $disabled-input-color;
  }

  :global(.react-datepicker__day--selecting-range-start) {
    @include breakpoint(phone-large-and-down) {
      margin: 4px;
    }

    @include breakpoint(tablet-and-up) {
      margin: 15px;
    }

    background-color: $link-color;
    border-radius: 50%;
    color: $white;
    font-weight: $title-font-weight;
    height: 32px;
    padding-top: 7px;
    width: 32px;
  }

  :global(.react-datepicker__day--range-start) {
    @include breakpoint(phone-large-and-down) {
      margin: 4px;
    }

    @include breakpoint(tablet-and-up) {
      margin: 15px;
    }

    background-color: $link-color !important;
    border-radius: 50%;
    color: $white;
    font-weight: $title-font-weight;
    height: 32px;
    padding-top: 7px;
    width: 32px;

    &:hover {
      border-radius: 50%;
    }
  }

  :global(.react-datepicker__day--range-end) {
    @include breakpoint(phone-large-and-down) {
      margin: 4px;
    }

    @include breakpoint(tablet-and-up) {
      margin: 13px 15px;
    }

    background-color: $link-color !important;
    border-radius: 50%;
    color: $white;
    font-weight: $title-font-weight;
    height: 32px;
    padding-top: 7px;
    width: 32px;

    &:hover {
      border-radius: 50%;
    }
  }

  :global(.react-datepicker__day--selecting-range-end) {
    @include breakpoint(phone-large-and-down) {
      margin: 4px;
    }

    @include breakpoint(tablet-and-up) {
      margin: 13px 15px;
    }

    background-color: $link-color !important;
    border-radius: 50%;
    width: 32px;
  }

  :global(input[type='time']::-webkit-inner-spin-button) {
    display: none;
  }

  :global(input[type='time']::-webkit-clear-button) {
    display: none;
  }

  :global(.react-datepicker__day--highlighted:hover) {
    background-color: $disabled-input-color;
  }
}
