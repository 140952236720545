@import '../../../main.module.scss';

.user-error-container {
  padding-bottom: 31px;
  padding-top: 31px;
  text-align: center;

  & .text-area {
    color: $label-color;
    font-size: $xs-text-size;
    padding-bottom: 29px;
    padding-top: 8px;
  }
}
