@import '../../../../../../main.module';

.container {
  display: block;
  width: 700px;
}

.calendar-details {
  margin-bottom: 25px;
}

.header {
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
}

.value {
  font-size: $regular-text-size;
  min-height: 24px;
}
