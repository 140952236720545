@import '../../main.module.scss';

@include breakpoint(laptop-and-up) {
  .details-row {
    border-bottom: $global-border-normal solid $color-smoke;
    float: right;
    width: 76%;

    &:last-child {
      border-bottom-width: 0;
    }
  }

  .reason-container {
    float: left;
    width: 52%;
  }

  .destination-container {
    float: right;
    width: 47%;
  }
}

@include breakpoint(phone-large-and-tablet) {
  .details-row {
    border: $global-border-normal solid $color-smoke;
    border-width: 0 0 1px;
    overflow: hidden;
  }
}

@include breakpoint(tablet) {
  .details-row {
    border: $global-border-normal solid $color-smoke;
    border-width: 0 0 1px;
    display: flex;
    overflow: hidden;
  }

  .destination-area {
    float: right;
    width: 40%;
  }

  .reason-container {
    float: left;
    padding-right: 15px;
    width: 60%;
  }
}

@include breakpoint(phone-large) {
  .details-row {
    width: 100%;
  }
}

@include breakpoint(phone) {
  .details-row {
    border: $global-border-normal solid $color-smoke;
    border-width: 0 0 1px;
    width: 100%;

    &:last-child {
      border-width: 0;
    }
  }
}
