@import '../../main.module.scss';

.section-enabled {
  margin-bottom: 25px;
  overflow: hidden;

  .title {
    color: $section-title-color;
    font-size: $small-title-text-size;
    line-height: 31px;
    margin-bottom: 16px;
  }

  .column-title-right {
    color: $label-color;
    font-size: $xs-text-size;
    margin: 0;
    text-align: right;
  }
}

.section-disabled {
  margin-bottom: 25px;
  overflow: hidden;

  .title {
    color: $light-text-color;
    font-size: $small-title-text-size;
    line-height: 31px;
    margin-bottom: 16px;
  }

  .column-title-right {
    color: $light-text-color;
    font-size: $xs-text-size;
    margin: 0;
    text-align: right;
  }
}

.section-hidden {
  visibility: hidden;
}

.error-container {
  color: $error-text-color;
  margin-bottom: 20px;
  margin-top: 40px;
}

.no-content {
  color: $label-color;
  font-style: italic;
}
