@import '../../main.module.scss';

.base-container {
  & .main-content {
    margin-bottom: 500px;
    padding-left: $menu-bar-width;
  }
}

@include breakpoint(tablet-and-down) {
  .base-container {
    & .overlay {
      visibility: hidden;
    }

    & .main-content {
      padding-left: 0;
      width: 100%;

      .header {
        background-color: $green;
        height: 60px;
        width: 100%;
      }

      .menu {
        background-color: $blue-variant;
        height: 100%;
        position: fixed;
        right: 0;
        top: 60px;
        width: 320px;
        z-index: 1000;
      }
    }
  }
}

@include breakpoint(phone) {
  .base-container {
    .main-content {
      .menu {
        width: 100%;
      }
    }
  }
}
