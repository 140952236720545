@import './_variables.scss';
@import './_breakpoints.scss';
@import './Responsive.module.scss';

$white: #fff;
$dark-gray: #404140;
$black: #000;
$green: #00a950;

$dark-background-color: #002447;
$light-background-color: #fafafa;

$new-default-text-color: #171c43;
$default-text-color: #404041;
$focused-text-color: #0019af;
$light-gray-color: #6b6b6b;
$light-text-color: #b0b0b1;
$label-color: #767677;
$highlight-color: #e6e9f5;
$disabled-input-color: #d3d3d3;
$disabled-input-bg-color: #fafafa;
$section-title-color: #002447;
$link-color: #005fed;
$blue-variant: rgb(0 109 252 / 80%);
$gray-variant: #efeff0;
$bg-color: #f4f4fd;
$hover-color: #006dfc;
$content-background-color: #fafafa;
$content-border-color: #e0e0e1;
$selected-detail-border-color: #006dfc;
$selected-detail-close-color: rgb(106 106 106 / 50%);
$grid-line-color: #0019af;
$bg-blue: #0168f0;
$brand-blue: #00239d;

$light-selection-color: rgb(0 109 252 / 10%);
$shadow-variant-color: rgb(106 106 106 / 50%);
$dark-shadow-color: rgb(0 0 0 / 15%);
$black-medium-shadow-color: rgb(0 0 0 / 11%);
$medium-shadow-color: rgb(0 25 175 / 6%);
$alternative-shadow-color: rgb(0 25 175 / 13%);
$light-shadow-color: rgb(0 0 0 / 8%);
$blue-overlay-color: rgb(0 109 252 / 95%);
$blue-overlay-color-2: rgb(0 109 252 / 80%);

$border-color: #e0e0e1;
$dark-border-color: #e8e8ea;
$light-border-color: #f0e7e7;
$transparent-border-color: rgb(0 25 175 / 5%);
$error-text-color: #e44;
$error-background-color: #e44;
$invalid-data-color: #d0021b;
$notification-info-background-color: #006dfc;

$content-width: 1263px;
$medium-content-width: 815px;
$small-content-width: 577px;

$menu-bar-width: 180px;
$app-width: $content-width + $menu-bar-width;
$app-min-height: 1100px;
$top-bar-height: 60px;

$xl-title-text-size: 64px;
$large-title-text-size: 36px;
$medium-title-text-size: 30px;
$title-text-size: 28px;
$icon-font-size: 25px;
$small-title-text-size: 24px;
$xsmall-title-text-size: 22px;
$xs-title-text-size: 20px;
$large-text-size: 18px;
$regular-text-size: 16px;
$small-text-size: 14px;
$xs-text-size: 13px;
$xxs-text-size: 12px;

$default-font-weight: 400;
$light-font-weight: 300;
$title-font-weight: 600;
$xl-title-font-weight: 700;

$large-heading-font: 'Jost Regular', sans-serif;
$large-heading-font-variant: 'Jost Bold', sans-serif;
$large-heading-bold-font: 'Jost Medium', sans-serif;
$default-font: 'Open Sans', sans-serif;

body {
  font-family: $default-font;
  font-weight: $default-font-weight;
  min-height: 100%;
  position: relative;
}

html {
  height: 100%;

  :focus {
    outline: 0;
  }
}

.main-content {
  min-height: 100%;
  position: initial !important;
}

%generic-overlay {
  background-color: $light-shadow-color;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 50;
}

.overlay {
  @extend %generic-overlay;
}

:global(.ea-combobox) {
  cursor: pointer;
}

:global(.ea-icon--font-reset) {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

:global(.ea-input__addon--auto-status) {
  visibility: hidden;
}

:global(.ea-input .ea-icon--input-clear) {
  display: none;
}

.main-container {
  box-sizing: border-box;
  min-width: 320px;
  width: 100%;
}

:global(input::-ms-clear) {
  display: none;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

a {
  text-decoration: none;
}

@include breakpoint(phone) {
  a {
    &:hover {
      color: $default-text-color;
      text-decoration: none;
    }

    &:active {
      color: $default-text-color;
      text-decoration: none;
    }
  }
}

:global(input[type='search']) {
  box-sizing: border-box !important;
}

:global(.ds-dropdown__button) {
  word-break: break-word;
}

:global(.ds-combobox__listbox) {
  z-index: 300 !important;
}

:global(.ds-button--size-s) {
  font-size: 0.6875em !important;
}

:global(.ds-button--size-m) {
  font-size: 0.8125em !important;
}

:global(.ds-button--size-l) {
  font-size: 0.875em !important;
}
