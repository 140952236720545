@import '../../../../main.module';

i {
  float: left;
}

.header {
  & h4 {
    color: $default-text-color;
    font-family: $default-font;
    font-size: $xs-title-text-size;
    font-weight: $title-font-weight;
    margin-left: 0;
  }
}

.address-row {
  display: flex;
  flex-direction: row;
  padding-bottom: 91px;

  & .address {
    width: 300px;

    & h4 {
      font-family: $default-font;
      font-size: $regular-text-size;
      font-weight: $title-font-weight;
      margin-bottom: 0;
      padding-bottom: 12px;
    }
  }
}

.address-icon {
  padding-right: 12px;
  padding-top: 20px;
}
