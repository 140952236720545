@import '../../main.module.scss';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.text {
  color: $label-color;
  margin-bottom: 5px;
}
