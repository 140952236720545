@import '../../main.module.scss';

.phonebook-header {
  :global(.ds-hero__background__image) {
    max-height: 380px;
  }

  :global(.ds-hero__gradient) {
    background-image: linear-gradient(90deg, #fafafb 0%, #fafafb 33%, #fafafb00 100%);
  }
}

.content-area {
  position: relative;
}

.mobile-padded {
  @include breakpoint(phone-large-and-down) {
    padding: 10px;
  }
}

.mobile-hidden {
  @include breakpoint(phone-large-and-down) {
    display: none;
  }
}

.info-text-large {
  margin-top: 80px;
}

:global(#pbPaginationDropdownListbox) { /* stylelint-disable-line selector-id-pattern */
  bottom: 40px;
}

.phonebook-area {
  padding: 5em;

  @include breakpoint(phone-large-and-down) {
    padding: 0;
  }

  & .search-input {
    width: 20em;
    display: inline-block;
    margin-bottom: 0;

    @include breakpoint(phone-large-and-down) {
      width: 100%;
    }
  }

  .search-info-label {
    font-size: 14px;
    color: #70707a;
  }

  & .phonebook-dropdown {
    width: 20em;

    @include breakpoint(phone-large-and-down) {
      width: 100%;
    }
  }

  & .highlight-area {
    background: yellow;
  }

  .column-select-button {
    border: none;
    color: $link-color;
    font-size: 14px;
    margin-left: 20px;

    @include breakpoint(phone-large-and-down) {
      display: none;
    }
  }

  .number-of-results-area {
    font-size: 14px;
    margin-top: 25px;
  }

  .result-table {
    margin-top: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #70707a;
  }
}
