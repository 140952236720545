@import '../../../main.module.scss';

.fwd-description {
  color: $default-text-color;
}

.fwd-description-extended {
  color: $default-text-color;
  font-weight: $title-font-weight;
  margin: 0;
}
