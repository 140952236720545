@import '../../../main.module.scss';

.history-actions-table {
  font-size: $regular-text-size;
  margin-bottom: 95px;
  margin-top: 32px;
  width: 1047px;

  thead {
    td {
      border: 0;
    }
  }

  .icon-column,
  .icon-header-column {
    border: 0;
  }

  .icon-column {
    width: 59px;

    & > * {
      height: 23px;
      margin-left: 10px;
      width: 23px;
    }
  }

  tbody {
    td {
      border-top: 1px solid $border-color;
      height: 84px;
      vertical-align: middle;
    }

    &:last-child {
      td {
        border-bottom: 1px solid $border-color;
      }

      .icon-column {
        border: 0;
      }
    }
  }

  .action-header-column,
  .action-timestamp-header-column {
    font-size: $xs-text-size;
    font-weight: $title-font-weight;
    padding-bottom: 10px;
  }

  .action-column {
    width: 760px;

    span {
      display: block;
    }

    .action-text {
      color: $default-text-color;
      font-weight: $title-font-weight;
    }

    .userid-text {
      color: $label-color;
      font-size: $xs-text-size;
    }
  }

  .action-timestamp-column {
    color: $label-color;
    font-size: $xs-text-size;
  }
}
