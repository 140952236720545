@import '../../main.module.scss';

.result {
  cursor: pointer;
  outline: none;
  padding: 12px 10px;
}

.selected {
  background-color: $light-background-color;
}
