@import '../../main.module.scss';

.tab-selector {
  border-bottom: 1px solid $border-color;
  margin-top: 13px;
  padding-bottom: 5px;
  padding-left: 106px;

  @include breakpoint(laptop-and-down) {
    margin-top: 40px;
  }
}
