@import '../main.module.scss';

.breadcrumb {
  color: $focused-text-color;
  font-size: $small-text-size;
  font-weight: $default-font-weight;

  .segment {
    .divider {
      margin: 0 5px;
    }

    %generic-name {
      cursor: pointer;
      outline: none;
    }

    .name {
      @extend %generic-name;
    }

    .highlight {
      @extend %generic-name;

      color: $label-color;
    }
  }
}
