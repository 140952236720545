@import '../../../../../main.module';

.destination-area {
  padding-top: 41px;

  @include breakpoint(phone-large-and-down) {
    padding-top: 29px;
  }

  .target {
    margin-top: 23px;
  }
}

.input-container input {
  width: 4em;
}

.description-text {
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
  margin: 40px 0 12px;
}
