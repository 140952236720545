@import '../../../main.module.scss';

$department-browser-column-width: 311;
$department-browser-column-height: 450;
$department-browser-columns: 2;
$department-browser-width: 1049;

.breadcrumb-container {
  padding-bottom: 29px;
  padding-top: 20px;
}

.browser {
  border-bottom: 1px solid $border-color;
  font-size: $regular-text-size;
  height: $department-browser-column-height + px;
  max-width: 100%;
  overflow-x: scroll;
}

/* stylelint-disable */
:export {
  columns: $department-browser-columns;
  columnWidth: $department-browser-column-width;
  browserWidth: $department-browser-width;
}
/* stylelint-enable */
