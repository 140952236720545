@import '../../../../main.module';

.detail-area {
  border-bottom: 1px solid $content-border-color;
  border-left: 5px solid $selected-detail-border-color;
  border-right: 1px solid $content-border-color;
  border-top: 1px solid $content-border-color;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-top: 47px;
  padding-bottom: 61px;

  @include breakpoint(desktop) {
    min-width: 1032px;
  }

  @include breakpoint(laptop-and-up) {
    margin-right: 2em;
    padding: 40px 47px 61px;
  }

  .close-button {
    color: $selected-detail-close-color;
    cursor: pointer;
    font-size: 20px;
    z-index: 101;
  }

  &--wide {
    max-width: initial;
  }
}
