@import '../../../main.module.scss';

.department-option-info-row {
  font-size: $small-text-size;
  margin-top: -10px;
  padding-bottom: 3px;
  padding-left: 40px;
}

.department-option {
  box-sizing: content-box;
  padding-top: 30px;

  label {
    padding-bottom: 0;
  }
}

.department-selection {
  padding-top: 25px;
}

.department-people-link {
  background: none;
  border: 0;
  color: $focused-text-color;
  cursor: pointer;
  font-family: $default-font;
  font-size: $regular-text-size;
  height: 21px;
  line-height: 21px;
  outline: inherit;
  padding: 0 0 0 5px;
}

.buttons {
  float: left;
}

.buttons-row {
  padding-top: 93px;
}

.cancel-button {
  padding-left: 50px;
  padding-top: 15px;
}

.header {
  font-family: $default-font;
  font-size: $xs-title-text-size;
  font-weight: $default-font-weight;
  padding-bottom: 23px;
}

.dropdown {
  margin-left: 30px;
}
