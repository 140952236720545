@import '../../../main.module.scss';

.container {
  margin-left: -60px;

  & .add-button {
    margin-bottom: 40px;
    margin-top: 40px;
  }
}
