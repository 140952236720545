@import '../../main.module.scss';

.container {
  display: inline-block;
  position: relative;
  width: 100%;

  .table {
    padding-top: 15px;
    width: 100%;
  }

  @include breakpoint(tablet) {
    border-top: 1px solid $content-border-color;
    padding-top: 24px;

    .table {
      padding-top: 0;
    }
  }

  @include breakpoint(phone-large-and-down) {
    .table {
      border: $global-border-normal solid $color-smoke;
      border-width: 1px 0 0;
      padding-right: 0;
      padding-top: 0;
    }
  }

  .corner {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 49px;
  }
}

.user-avatar {
  float: left;
}
