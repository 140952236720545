@import '../../../../main.module';

.details {
  display: block;
  float: left;
  justify-content: space-between;
  margin-left: 60px;
  margin-right: 67px;
  width: 330px;
}
