@import '../main.module.scss';

.result-view-content {
  padding-top: 93px;
  text-align: center;
  width: 100%;

  & .confirm-button {
    margin-top: 31px;
  }

  & .cancel-button {
    margin-top: 31px;
  }

  & .secondary-info {
    margin-top: 11px;
  }

  & h1 {
    font-size: 34px;
    font-weight: $title-font-weight;
    margin-bottom: 41px;
  }

  & h2 {
    font-size: $small-title-text-size;
  }

  & h3 {
    font-size: $large-text-size;
    margin-bottom: 0;
    margin-top: 0;
  }
}
