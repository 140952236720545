@import '../../../../../../main.module';

.title-padding {
  margin-bottom: 25px;
}

.description {
  color: $label-color;
  font-size: $xs-text-size;
  line-height: 17px;
  margin-bottom: 20px;
}

.small-title {
  color: $default-text-color;
  font-size: $regular-text-size;
  font-weight: $title-font-weight;

  &--disabled {
    color: $disabled-input-color;
  }
}

.container {
  margin-bottom: 50px;

  :global(.ds-inputhelp) {
    white-space: pre-line;
  }
}
