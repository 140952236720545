@import '../../../main.module.scss';

.modify-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 40px;
  margin-top: 40px;
}

.move-container {
  margin-top: 30px;
  min-height: 200px;

  .move-title {
    font-weight: $title-font-weight;
  }

  .move-field {
    font-weight: $title-font-weight;
    margin-top: -15px;
  }
}

.label {
  color: $default-text-color;
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
}

.delete-button {
  margin-left: 20px;
  margin-right: 20px;

  &:disabled {
    border-color: $border-color;
    color: $light-text-color;

    &:hover {
      border-color: $border-color;
      color: $light-text-color;
    }
  }
}

.cancel-button {
  margin-left: 20px;
}

.button-container {
  margin-top: 60px;
}

.input {
  display: flex;

  & button {
    margin: 24px 0 0 24px;
  }
}

.static-dropdown {
  & :global(.ea-combobox__options) {
    position: static;
    width: 300px;
  }
}
