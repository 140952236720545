@import '../../../../../../main.module';

.title {
  color: $default-text-color;
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
  padding-right: 15px;
}

.subtitle {
  margin-bottom: 5px;
}

.description {
  color: $label-color;
  font-size: $xs-text-size;
  line-height: 17px;
  margin-bottom: 20px;
}

.new-step {
  color: $link-color;
}

.title-area {
  display: flex;
}

.next-step-container {
  display: inline-block;
  padding-bottom: 20px;
}

.option-next-step-input {
  float: left;
  margin-right: 20px;
  width: 300px;

  :global(.ds-dropdown__button) {
    overflow-x: hidden !important;
  }
}

.input-area {
  display: contents;
}

.new-step-name-input {
  width: 300px;
}
