@import '../../../../main.module';

.tab {
  background-color: white;
  padding-left: 4em;
}

.user-name {
  color: #171c43;
  font-size: 1.5em;
  padding-left: 1em;
}

.notifications {
  text-align: center;
}

@include breakpoint(tablet-and-up) {
  .user-name {
    color: #171c43;
    font-size: 3.5em;
    line-height: 2em;
    padding-left: 1em;
  }

  .form {
    padding-left: 4em;
    padding-right: 4em;
  }

  :global(.ds-tabnavigation-tab-container) {
    margin-top: 0;
  }

  :global(.ds-tabnavigation-tab-container .ds-tab-item::after) {
    padding: 0 1em 0.75em;
  }

  :global(.ds-app-carousel--navigation) {
    background-color: white;
    padding-left: 0;
  }
}
