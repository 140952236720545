@import '../../main.module.scss';

.link {
  color: unset;
}

.row {
  cursor: pointer;
  display: flex;
  overflow-x: hidden;
  padding-bottom: 22px;

  & .text-area {
    &--empty-title {
      padding-top: 0.8em;
    }

    & .name-field {
      line-height: $regular-text-size;
    }

    & .info-field {
      color: $light-text-color;
      font-size: $xs-text-size;
    }
  }
}
