@import '../main.module.scss';

.box {
  background: $light-background-color;
  border: 1px solid $border-color;
  margin-left: -35px;
  margin-top: 23px;
  max-width: 868px;
  padding: 35px;

  @include breakpoint(tablet-and-down) {
    margin-left: 0;
    width: 100%;
  }
}
