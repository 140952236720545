@import '../../../../../main.module';

.field-group-section {
  margin-bottom: 25px;

  & :global(.ea-input) {
    width: 86px;
  }
}

.title {
  color: $default-text-color;
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
  line-height: 24px;
  margin-bottom: 5px;
}

.tooltip {
  color: $default-text-color;
  font-weight: $default-font-weight;
  margin-left: 10px;
}

.description {
  color: $label-color;
  font-size: $xs-text-size;
  line-height: 17px;
  margin-bottom: 20px;
}

.input-description {
  color: $label-color;
  font-size: $xs-text-size;
  line-height: 17px;
}

.input {
  display: flex;
  font-size: $regular-text-size;
  line-height: 24px;
}

.integer-input {
  & :global(input) {
    width: 86px;
  }
}

.input-postfix {
  padding-left: 10px;
  padding-top: 10px;
}

.duration-fields {
  display: flex;

  .duration-divider {
    padding: 10px;
  }
}
