@import '../../../../main.module';

.substitutes {
  margin-left: 43px;

  & fieldset {
    margin-bottom: 0;
  }
}

.radio {
  box-sizing: content-box;
  margin-bottom: 7px;

  &:last-of-type {
    margin-bottom: 0;
  }
}
