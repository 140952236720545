@import '../../../../main.module';

.container {
  justify-content: space-between;
}

.topic-icon {
  margin-left: 0;
}

.title {
  font-size: $small-title-text-size;
  margin-bottom: 46px;
  margin-left: 60px;
  margin-top: -40px;
}

.modify-button {
  float: left;
  margin-left: 60px;
  margin-top: 25px;
  width: 100%;
}

.subtitle {
  font-size: $regular-text-size;
  font-weight: bold;
  margin-left: 60px;
}

.description {
  margin-bottom: 50px;
  margin-left: 60px;
}

.content {
  display: flex;
  margin-left: 60px;
}

.retry-error {
  margin-top: 15px;
}

.button-area {
  background-color: $light-background-color;
  bottom: 0;
  box-shadow: 0 3px 22px 0 $light-shadow-color;
  padding-bottom: 6px;
  padding-right: 250px;
  padding-top: 6px;
  position: fixed;
  right: 0;
  text-align: right;
  width: 100%;
  z-index: 10;
}
