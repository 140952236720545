@import '../main.module.scss';

.modal-overlay {
  @extend %generic-overlay;

  overflow: auto;
  position: fixed;
  z-index: 101;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  background-color: $white;
  color: $default-text-color;
  padding: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  max-width: 90%;
  overflow: auto;
}
