@import '../../../../../main.module';

.container {
  margin-bottom: 25px;
}

.title {
  color: $default-text-color;
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
  line-height: 24px;
  margin-bottom: 5px;
}

.tooltip {
  color: $default-text-color;
  font-weight: $default-font-weight;
  margin-left: 10px;
}
