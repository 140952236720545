@import '../../main.module.scss';

.user-locked {
  background-color: $content-background-color;
  border: 1px solid $border-color;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 22px;

  & .top-part {
    text-align: center;

    & .title {
      font-size: $large-text-size;
      font-weight: $title-font-weight;
      padding-bottom: 13px;
      padding-top: 10px;
    }

    & .description {
      padding-bottom: 35px;
    }
  }

  & .button-area {
    text-align: center;
  }

  & .send-reset-button {
    font-size: 12px;
    margin-top: 41px;
  }
}
