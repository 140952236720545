@import '../../../../main.module';

.specific-validation-error {
  color: $error-text-color;
  font-size: $regular-text-size;
  margin-bottom: 12px;
}

.option-area {
  display: flex;

  & :global(.ea-fgrid--center) {
    height: 0;
  }

  & :global(.ea-loading) {
    height: 20px;
    margin-top: 0;
    width: 20px;
  }
}

.hidden {
  display: none;
}

.external-target {
  width: 350px;
}

.label-container {
  display: flex;
}

.tooltip {
  padding-left: 20px;
}

.loading-spinner {
  padding-left: 18em;
  padding-top: 4em;
  position: absolute !important;
  z-index: 400;
}

.forwarding-target-area {
  .search-area {
    width: 22em;
  }

  :global(.ds-combobox) {
    white-space: nowrap;
  }

  :global(.ds-combobox--input-clear) {
    visibility: hidden;
  }
}
