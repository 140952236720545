@import '../../main.module.scss';

.requirement {
  font-size: 13px;
  margin-top: -10px;

  img {
    margin-left: 12px;
  }
}

.invalid {
  color: $invalid-data-color;
}
