@import '../../main.module.scss';

.notification-error {
  background-color: $error-background-color;
}

.notification-info {
  background-color: $notification-info-background-color;
}

.notification {
  .cross-icon {
    cursor: pointer;
  }

  span {
    float: right;
  }
}
