@import '../../main.module.scss';

.small-padding-fix,
:global(.ea-button--small) {
  padding-left: 24px;
}

.button {
  font-size: $regular-text-size !important;

  &:disabled {
    border-color: $dark-border-color;
    background-color: $dark-border-color;
  }
}

.lighter {
  background-color: $link-color;
  height: 3em;
}

.image-container {
  align-items: center;
  display: inline;
  float: left;
  justify-content: center;
  margin-right: 1.5em;
  width: 0.6em;
}
