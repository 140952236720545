@import '../../../../../main.module';

.error-container {
  margin-left: -260px;
  text-align: center;
  width: 100%;

  & > *:first-child {
    padding-bottom: 0;
    padding-top: 50px;
  }
}
