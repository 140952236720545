@import '../../../../../../main.module';

.container {
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
}

.remove-button {
  float: right;
}

.step-list {
  float: left;
}

.tooltip {
  margin-bottom: 10px;
}
