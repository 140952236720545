@import '../../main.module';

.number-selection {
  max-width: 515px;

  .search-area {
    width: 100%;
  }

  .margin-bottom-medium {
    margin-bottom: 30px;
  }

  .number-item {
    padding: 8px;
  }

  .dropdown-item-default {
    color: #7b7b7b;
    font-style: italic;
  }

  .dropdown-item {
    .dropdown-text {
      color: #7b7b7b;
    }
  }

  .presence-item {
    display: flex;
    margin: 0 0 24px;

    .presence-name {
      display: flex;
      align-items: center;
      width: 30%;
      max-width: 140px;
    }

    .number-dropdown {
      width: 405px;
      margin: 0;
    }
  }

  .delete-button {
    cursor: pointer;
    margin: 8px;
    vertical-align: top;
  }

  & .loading-spinner {
    padding-left: 25em;
    padding-top: 5.5em;
    position: absolute !important;
    z-index: 400;
  }

  :global(.ds-combobox__listbox[data-show="true"]) {
    position: relative;
  }

  :global(.ds-combobox--input-clear) {
    visibility: hidden;
  }
}
