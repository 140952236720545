@import '../../main.module.scss';

.selected {
  border: 2px solid $link-color;

  .overlay {
    cursor: default;
  }
}

.card {
  background-color: $white;
  border: 1px solid $border-color;
  height: 300px;
  position: relative;
  width: 300px;

  .overlay {
    background-color: $blue-overlay-color;
    cursor: pointer;
    height: 240px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: 0.2s ease;
    visibility: hidden;
    width: 298px;

    p {
      color: $white;
      font-family: $large-heading-bold-font;
      font-size: $title-text-size;
      font-weight: $default-font-weight;
      line-height: 240px;
      margin: 0;
      text-align: center;
      text-transform: uppercase;
      vertical-align: middle;

      img {
        margin-right: 14px;
        width: 28px;
      }
    }
  }

  .content {
    height: 240px;
    overflow: hidden;

    &:hover > .overlay {
      opacity: 1;
      transition: 0.3s ease;
      visibility: visible;
    }

    &:hover > .overlay > .select-button {
      transform: translateY(200px);
    }
  }

  .hover-check {
    margin-right: 10px;
    width: 20px;
  }

  .selected-check {
    left: 13px;
    position: absolute;
    top: 14px;
    width: 20px;
  }

  .logo {
    left: 85px;
    position: absolute;
    top: 40px;
  }

  .label {
    color: $dark-background-color;
    font-size: $xs-title-text-size;
    line-height: 21px;
    margin-top: 184px;
    padding-left: 27px;
    padding-right: 27px;
    position: relative;
    text-align: center;
    width: 296px;
  }

  .inspect-button {
    background-color: $white;
    bottom: 0;
    cursor: pointer;
    height: 60px;
    position: absolute;
    width: 100%;

    p {
      color: $focused-text-color;
      font-size: $regular-text-size;
      font-weight: $title-font-weight;
      line-height: 30px;
      text-align: center;
      text-transform: uppercase;
      vertical-align: middle;
    }
  }
}
