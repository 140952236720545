@import '../../../../main.module';

.title {
  color: $default-text-color;
  font-size: $xsmall-title-text-size;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.description {
  color: $dark-gray;
  font-size: $regular-text-size;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 30px;
}

.results {
  margin-bottom: 20px;
}

.result-row {
  display: flex;
  margin-bottom: 10px;
}

.icon {
  height: 25px;
  margin-right: 12px;
  width: 25px;
}

.link {
  color: $focused-text-color;
  display: block;
  font-size: $regular-text-size;
  line-height: 22px;
  margin-bottom: 20px;
  padding-top: 2px;
}

.close-button {
  display: block;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
