@import '../../../main.module';

.selector-content {
  padding-left: 60px;
  padding-top: 48px;
}

.description-title {
  font-family: $default-font;
  font-size: $small-title-text-size;
  padding-bottom: 28px;
}

.description {
  padding-bottom: 44px;
}

.selector {
  max-width: 800px;
  padding-right: 25px;
}

:global(.ds-selectgroup__list) {
  grid-template-columns: repeat(auto-fit, minmax(365px, 1fr)) !important;
}

:global(.ds-selectgroup__list-item) {
  height: 124px;
}

:global(.ds-selectgroup__list-item__icon) {
  float: left;
  position: absolute;
  top: 35px;
}

:global(.ds-selectgroup__list-item__text) {
  padding-left: 70px;
  text-align: left;
}

:global(.ds-selectgroup__list-item--disabled .ds-selectgroup__list-item__text .ds-selectgroup__list-item__text__name) {
  color: $disabled-input-color;
}
