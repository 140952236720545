@import '../../main.module.scss';

.link-button {
  background: none;
  border: 0;
  color: $link-color;
  cursor: pointer;
  font-family: $default-font;
  font-size: $regular-text-size;
  height: 1em;
  line-height: 1em;
  outline: inherit;
  padding: 0;

  &:hover {
    color: $focused-text-color;
  }
}
