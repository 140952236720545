@import '../../../main.module';

.popup-dialog {
  padding-bottom: 22px;

  & .header {
    font-size: $regular-text-size;
    font-weight: $title-font-weight;
    padding-top: 30px;
  }
}
