@import '../../main.module.scss';

.phonebook-details-area {
  padding: 5em;
  width: 405px;

  @include breakpoint(phone-large-and-down) {
    padding: 0;
  }

  .delete-icon {
    cursor: pointer;
  }

  .results-area {
    padding-bottom: 2em;
  }

  .tags-area {
    padding-bottom: 1em;
  }
}
