@import '../../main.module.scss';

.history-page {
  padding-left: 100px;
}

.search-history-page {
  padding-left: 100px;
  padding-top: 36px;

  & .dropdown {
    width: 300px;
  }

  & div > div > button > span {
    box-sizing: content-box;
  }
}
