@import '../../main.module';

.date-info {
  font-weight: $title-font-weight;
}

.time-field {
  white-space: nowrap;
}

.availability-field {
  display: flex;

  .availability-icon {
    margin-top: 0.4em;
    margin-right: 0.3em;
  }
}

.forwarding-cell {
  font-size: $small-text-size;
  line-height: $small-text-size;
}

.custom-field {
  font-size: $xxs-text-size;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 20em;
}

.add-button {
  cursor: pointer;

  @include breakpoint(tablet-and-up) {
    text-align: right;
  }
}

.add-icon {
  position: relative;
  top: 0.2em;
}

.add-message {
  color: #00239d;
  cursor: pointer;
  font-size: $xs-text-size;
  line-height: $xs-text-size;
  padding-left: 0.5em;
}

.empty-table {
  font-weight: $title-font-weight;
  text-align: center;
}
