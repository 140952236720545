@import '../../../main.module.scss';

.container {
  border-bottom: 0;
  margin-bottom: 200px;
  margin-left: 106px;
  max-width: 1047px;
  padding-bottom: 200px;
}

.content-container {
  padding-left: 0;
}

.subheader-container {
  padding-bottom: 30px;
  padding-left: 60px;
  font-weight: bold;
}
