@import '../../../../../main.module';

.title {
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
}

.description {
  color: $label-color;
  font-size: $xs-text-size;
  line-height: 17px;
}

.tooltip {
  color: $default-text-color;
  font-weight: $default-font-weight;
  margin-left: 10px;
}

.pw-input {
  width: 250px;
}
