@import '../../../main.module.scss';

.column-select {
  font-family: "Open Sans", sans-serif;
  border: 1px solid #d2d2d6;
  width: 100%;
  padding: 25px;
  font-size: 16px;

  .title-text {
    font-weight: bold;
  }

  .column-group {
    margin-top: 20px;
    display: inline-block;
    vertical-align: top;
  }
}
