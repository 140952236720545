@import '../../../../../main.module';

.detail {
  margin-bottom: 25px;
  max-width: 400px;
}

.header {
  display: flex;
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
  justify-content: space-between;
}

.tooltip {
  color: $default-text-color;
  font-weight: $default-font-weight;
  margin-left: 10px;
}
