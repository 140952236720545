@import '../../../main.module.scss';

.user-grid {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 35px;
  max-height: 580px;
  min-height: 260px;
  overflow-y: auto;
  width: 999px;

  &--empty {
    justify-content: center;
  }
}
