@import '../../main.module.scss';

.contact-selector {
  box-sizing: content-box;
  padding-bottom: 36px;
  text-align: left;

  &--description {
    padding-bottom: 22px;
  }
}
