@import '../../../main.module';

.input-field {
  float: left;
  padding-top: 0;
  width: 407px;
}

@include breakpoint(tablet) {
  .create-callforwarding-container {
    float: right;
    margin-right: 0;
    padding-top: 7px;

    svg {
      margin-right: 0;
    }
  }

  .section-description {
    width: 90%;
  }

  .user-call-forwardings-container {
    margin-top: 60px;
  }
}

@include breakpoint(phone-large) {
  .user-call-forwardings-container {
    margin-top: 60px;
  }

  .create-callforwarding-container {
    float: right;
    height: 84px;
    margin: 60px 0 0;

    svg {
      margin-right: 0;
    }
  }

  .section-description {
    width: 90%;
  }
}

@include breakpoint(phone) {
  .create-callforwarding-container {
    float: right;
    height: 84px;
    margin: 0;
    padding-top: 35px;

    svg {
      margin-left: 0;
      margin-right: 0;
      width: 20px;
    }
  }

  .section-description {
    width: 90%;
  }

  .section-title {
    font-size: $xs-title-text-size;
    margin-bottom: 10px;
  }
}

.no-search-results-container {
  margin-left: auto;
  margin-right: auto;
  padding-top: 144px;
  text-align: center;
  width: 50%;

  h2 {
    color: $focused-text-color;
    font-family: $default-font;
    font-size: $large-text-size;
    font-weight: 400;
    height: 24px;
    line-height: 24px;
    margin: 0;
    position: relative;
    top: -41px;
  }
}
