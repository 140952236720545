@import '../../main.module.scss';

.container {
  align-items: center;
  display: flex;
  width: 100%;
}

.drag-handle {
  margin-right: 12px;
}

.ordinal {
  color: $default-text-color;
  margin-right: 12px;

  &::after {
    content: '.';
  }
}

.children {
  flex: 1;
}
