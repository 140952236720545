@import '../../main.module.scss';

.container {
  overflow-y: scroll;
  z-index: 101;
}

.overlay {
  position: fixed;
}

.upper-area {
  @include breakpoint(phone-large-and-down) {
    width: 100%;
  }

  @include breakpoint(tablet-and-up) {
    max-width: 520px;
  }

  background-color: $white;
  height: 70px;
  max-width: 520px;
  min-width: 320px;
  padding-left: 50px;
  padding-right: 32px;
  padding-top: 22px;
  text-align: center;
}

.time-area-container {
  background-color: $white;
  margin-top: -10px;
  max-width: 520px;
  padding-top: 20px;

  & .time-picker-area {
    display: flex;

    & .remove-button {
      padding-left: 10px;
      padding-top: 2em;
    }
  }

  & .title-area {
    display: flex;
    padding-bottom: 15px;
    text-align: left;

    & .icon-area {
      padding-right: 12px;
    }
  }

  & .toggle-info {
    padding-bottom: 32px;
    padding-right: 82px;
  }

  & .toggle-container {
    display: flex;
    margin-bottom: 32px;
  }

  & > * {
    @include breakpoint(tablet-and-up) {
      margin-left: 66px;
    }
  }
}

.button-area {
  @include breakpoint(phone-large-and-down) {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 520px;
    min-width: 320px;
    padding-left: 0;
    width: 100%;
  }

  @include breakpoint(tablet-and-up) {
    padding-left: 66px;
    max-width: 520px;
  }

  background-color: $white;
  padding-bottom: 36px;
  padding-top: 50px;
}

.stretch-date--start-end {
  @include breakpoint(phone-large-and-down) {
    width: 101px !important;
  }

  @include breakpoint(tablet-and-up) {
    width: 122px !important;
  }

  margin-left: -30px !important;
  margin-right: -30px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

:global(.ea-input__addon) {
  display: none;
}

.timedate-area {
  left: 20%;
  position: absolute;
  width: 520px;
  z-index: 100;

  :global(.react-datepicker__month) {
    border-bottom: 1px solid $content-border-color !important;
    margin: 0;
    padding-bottom: 53px;
  }

  :global(.react-datepicker__month-container) {
    font-size: $large-text-size !important;
    padding-top: 25px !important;
  }

  :global(.react-datepicker) {
    @include breakpoint(phone-large-and-down) {
      max-width: 520px !important;
      width: 100% !important;
    }

    @include breakpoint(tablet-and-up) {
      width: 520px !important;
    }
  }

  :global(.react-datepicker__day) {
    @include breakpoint(phone-large-and-down) {
      margin: 4px !important;
    }

    @include breakpoint(tablet-and-up) {
      margin: 15px !important;
    }
  }

  :global(.react-datepicker__day-name) {
    @include breakpoint(phone-large-and-down) {
      width: 37px !important;
    }

    @include breakpoint(tablet-and-up) {
      width: 58px !important;
    }
  }

  :global(.react-datepicker__day-names) {
    padding-top: 40px !important;
  }

  :global(.react-datepicker__navigation--previous) {
    @include breakpoint(phone-large-and-down) {
      left: 20px !important;
    }

    @include breakpoint(tablet-and-up) {
      left: 70px !important;
    }

    transform: scale(2) !important;
  }

  :global(.react-datepicker__navigation--next) {
    @include breakpoint(phone-large-and-down) {
      right: 20px !important;
    }

    @include breakpoint(tablet-and-up) {
      right: 70px !important;
    }

    transform: scale(2) !important;
  }

  :global(.react-datepicker__current-month) {
    @include breakpoint(phone-large-and-down) {
      font-size: $xs-title-text-size !important;
    }

    @include breakpoint(tablet-and-up) {
      font-size: $small-title-text-size !important;
    }
  }
}
