@import '../../../main.module.scss';

.row {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 75px;
  justify-content: flex-start;

  & > * {
    width: 100%;
  }

  .fields {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    & > * {
      width: 140px;
    }
  }
}

.even {
  background-color: $light-background-color;
}

.header {
  border-bottom: 1px solid $black;
  height: 100px;
}

.import-button {
  margin-right: 10px;
}
