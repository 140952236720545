@import '../main.module.scss';

.modal {
  width: 575px;
}

.success-view {
  height: 660px;
  overflow: hidden;
  padding: 19px;
  text-align: center;

  & .success-image-content {
    padding-top: 134px;
    width: 100%;
  }

  & .success-image {
    height: 103px;
    width: 110px;
  }

  & .success-text {
    padding: 0 82px;
  }

  & .button-content {
    padding-top: 126px;

    & .ok-button {
      height: 48px;
      width: 176px;
    }
  }

  & h2 {
    font-size: $xsmall-title-text-size;
    font-weight: $title-font-weight;
  }
}

.feedback-container {
  overflow: hidden;

  & .title {
    margin-bottom: 0;
  }

  & .content {
    padding: 30px 10px 30px 30px;

    & .contact-info {
      color: $label-color;
      font-size: $xs-text-size;
      height: 48px;
      line-height: 17px;
      margin-bottom: -48px;
      padding-left: 40px;
    }

    & .validation-error {
      color: $error-text-color;
      margin-bottom: -24px;
    }

    & .sending-error {
      color: $error-text-color;
    }

    & h2 {
      font-size: $xsmall-title-text-size;
      font-weight: $title-font-weight;
      margin-bottom: 16px;
      margin-top: 0;
    }

    & h3 {
      font-size: $regular-text-size;
      font-weight: $title-font-weight;
      margin-bottom: 7px;
    }

    & .button-area {
      padding-top: 41px;

      & .send-button {
        margin-right: 30px;
      }
    }

    & .rating {
      color: $focused-text-color;
      font-size: $small-title-text-size;
      margin-right: 9px;

      &--empty {
        color: $light-text-color;
        margin-right: 9px;
      }
    }
  }
}
