@import '../../../../../../main.module';

.row {
  display: flow-root;
  width: 300px;
}

.name {
  color: $default-text-color;
  font-size: 16px;
  line-height: 24px;
  width: 12em;
}

.type {
  color: $label-color;
  font-size: 16px;
  line-height: 24px;
}

.remove {
  float: right;
}
