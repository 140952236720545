@import '../../../../../../main.module';

.header {
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
}

.value {
  font-size: $regular-text-size;
  margin-bottom: 25px;
  min-height: 24px;
}
