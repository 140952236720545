@import '../../main.module.scss';

.error-wrapper {
  text-align: left;
}

.loader-wrapper {
  text-align: center;
  width: 225px;
}

.flex-wrapper {
  background-color: $white;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 225px;
  z-index: 100;
}

.error-container-style {
  align-items: baseline;
}

.slider-area {
  display: flex;
}

.toggle-play {
  cursor: pointer;
  height: 40px;
  margin-right: 1em;
  width: 40px;
}

.progress-bar-wrapper {
  box-sizing: border-box;
  margin-left: 5px;
  padding: 12px 4% 0 0;
  position: relative;
  width: 145px;
}

.progress-bar {
  background: $label-color;
  box-sizing: border-box;
  height: 1px;
  left: 0;
  width: 100%;
}

.time {
  color: $dark-gray;
  font-size: 11px;
}

.inactive {
  color: $border-color;
}

.download-button {
  margin-left: 1em;
  margin-top: 0.2em;
}

.filename {
  color: $label-color;
  font-size: $xs-text-size;
}
