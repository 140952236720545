@import '../../../main.module.scss';

.modal {
  height: auto;
  padding-left: 89px;
  padding-right: 60px;
  padding-top: 55px;
  width: 1156px;
}

.section-title {
  color: $section-title-color;
  font-family: $default-font;
  font-size: $medium-title-text-size;
  font-weight: $default-font-weight;
  line-height: 36px;
  margin-top: 0;
}
