@import '../../../main.module.scss';

%user-grid-item {
  width: 270px;
}

.user-grid-item {
  box-sizing: border-box;
  height: 80px;
  overflow: hidden;
  width: 333px;

  & .user-select {
    display: flex;
  }

  & > div > label > span {
    margin-top: 9px !important;
  }

  .user-grid-item-details {
    @extend %user-grid-item;

    display: inline-block;
    padding: 10px 6px;
    vertical-align: top;
    width: 250px;
  }

  .user-grid-item-details-title {
    @extend %user-grid-item;

    color: $label-color;
  }
}
