@import '../../../main.module.scss';

.border-bottom {
  margin-left: 0;
}

.table {
  @include breakpoint(laptop-and-up) {
    margin-right: 107px;

    & .number-cell {
      white-space: normal;
      word-spacing: 100vw;
    }
  }

  margin-right: 15px;

  & .small-row {
    padding-bottom: 22px;
    width: 100%;

    & .type {
      display: flex;
      justify-content: space-between;
    }

    & .name-container {
      display: inline;

      &:last-of-type {
        padding-right: 10px;
      }
    }

    & .name {
      color: $light-text-color;
      font-size: $xs-text-size;
    }

    .link {
      color: $focused-text-color;
      text-decoration: underline;
    }
  }
}
