@import '../../../../../../main.module';

.title {
  color: $default-text-color;
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
  margin-bottom: 5px;
  margin-top: 5px;
}

.separator {
  margin-bottom: 20px;
  margin-top: 10px;
}

.description {
  color: $label-color;
  font-size: $xs-text-size;
  line-height: 17px;
  margin-bottom: 20px;
}

.radio {
  box-sizing: content-box;
}

.columns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
  width: 620px;
}

.input {
  display: flex;
  font-size: $regular-text-size;
  line-height: 24px;

  & .acd-input-description {
    padding-left: 10px;
    padding-top: 10px;
  }
}

.forwarding-selection {
  padding-bottom: 10px;
  padding-top: 10px;

  .search-area {
    width: 22em;
  }

  :global(.ds-combobox) {
    white-space: nowrap;
  }

  :global(.ds-combobox--input-clear) {
    visibility: hidden;
  }
}

.label-container {
  display: flex;
}

.tooltip {
  padding-left: 20px;
}

.loading-spinner {
  padding-left: 18em;
  padding-top: 4em;
  position: absolute !important;
  z-index: 400;
}

.input-wrapper {
  width: 22em;
}
