@import '../../../main.module.scss';

.column-selector {
  margin: 7px;
}

%generic-header {
  color: $default-text-color;
  font-size: $xs-title-text-size;
  line-height: 26px;
  padding: 20px 17px;
}

.header {
  @extend %generic-header;
}

.description {
  font-size: $regular-text-size;
  letter-spacing: 0;
  line-height: 22px;
  padding: 20px 17px;
}

.file-header {
  @extend %generic-header;

  line-height: 56px;
  padding-left: 0;
}

.column-selector-container {
  overflow-y: auto;
  padding-bottom: 17px;
  padding-left: 17px;
  padding-right: 17px;
  max-height: 300px;
}

.file-action-container {
  border-top: 1px solid $border-color;
  padding-left: 17px;
  padding-right: 17px;
}

.import-export-button {
  font-family: $default-font !important;
  font-size: $small-text-size !important;
  line-height: $small-text-size !important;
  padding: 0 !important;
  text-align: left !important;
}

.button-icon {
  padding-left: 5px;
}
