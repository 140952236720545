@import '../../../main.module.scss';

.container {
  border: 1px solid $border-color;
  box-shadow: 4px 4px 6px 1px $light-shadow-color;
  height: 586px;
  max-height: 586px;
  padding: 36px 0;
  width: 406px;

  .inner-container {
    margin-left: 54px;
    margin-right: 54px;
    text-align: center;

    .input-row {
      margin-bottom: 100px;
    }

    .button-row {
      display: flex;
      justify-content: space-between;
      padding-top: 40px;
      width: 300px;
    }

    .link-row {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
      width: 300px;

      .link-button {
        text-decoration: underline;
      }
    }

    .checkmark {
      padding-bottom: 20px;
      padding-top: 154px;
    }

    .info-container {
      font-size: $xs-title-text-size;
      font-weight: $title-font-weight;
      padding-bottom: 154px;
    }

    .department-browser {
      width: 298px;
    }
  }

  & .icon {
    background: url('/assets/public/unidentified-department.svg') no-repeat center;
    height: 107px;
    margin-bottom: 20px;
  }

  & > h3 > * {
    color: $default-text-color;
    font-size: $xsmall-title-text-size;
    font-weight: $title-font-weight;
    margin-bottom: 6px;
  }

  & > p > * {
    color: $label-color;
    font-size: $regular-text-size;
    font-weight: $default-font-weight;
  }

  .selections {
    margin-top: 24px;

    & > p {
      color: $default-text-color;
      font-size: $regular-text-size;
      font-weight: $default-font-weight;
      margin-bottom: 18px;
      text-align: left;
    }

    & > button {
      background-color: $gray-variant;
      border: 0;
      border-radius: 2px;
      color: $default-text-color;
      cursor: pointer;
      font-size: $regular-text-size;
      font-weight: $default-font-weight;
      height: 48px;
      margin-bottom: 20px;
      width: 276px;
    }
  }

  .tooltip-header {
    color: $default-text-color;
    font-size: $large-text-size;
    font-weight: $title-font-weight;
    margin-bottom: 12px;
  }

  .tooltip-text {
    font-size: $regular-text-size;
    font-weight: $default-font-weight;
  }

  .tooltip-segment {
    color: $label-color;
  }

  .tooltip-segment-last {
    color: $default-text-color;
  }
}
