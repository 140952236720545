@import '../../main.module.scss';

.call-forwardings-page {
  padding-bottom: 50px;

  .content-container {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 33px;
  }
}

.title {
  color: $focused-text-color;
  font-size: $medium-title-text-size;
  font-weight: $title-font-weight;
  line-height: 36px;
}

@include breakpoint(phone) {
  .call-forwardings-page {
    .content-container {
      padding-top: 15px;
    }
  }

  .title {
    font-size: $xsmall-title-text-size;
    line-height: 36px;
  }
}

@include breakpoint(tablet-and-up) {
  .title {
    font-size: $large-title-text-size;
    font-weight: $title-font-weight;
  }
}
