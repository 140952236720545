@import '../../main.module.scss';

.link {
  color: unset;
}

.row {
  cursor: initial;
  display: flex;
  padding-top: 22px;

  & .info-field {
    color: $light-text-color;
    font-size: $xs-text-size;
  }

  & .label-field {
    line-height: $regular-text-size;
  }

  & .text-area {
    &--empty-title {
      padding-top: 8px;
    }
  }
}
