@import '../../main.module.scss';

.time-area {
  @include breakpoint(phone-large-and-down) {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 520px;
    min-width: 300px;
    padding-left: 0;
  }

  box-sizing: initial;
  line-height: 3em;
  padding-bottom: 2em;

  & .input-description {
    color: $label-color;
    font-size: $xs-text-size;
    line-height: 17px;
  }

  & .field-area {
    display: flex;
  }

  & .input-mid-text {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 1em;
  }
}

.error {
  color: $error-text-color;
}
