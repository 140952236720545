@import '../../../../../../main.module.scss';

.title-area {
  display: flex;
  padding-bottom: 15px;
  text-align: left;
}

.icon-area {
  padding-right: 12px;
}

.save-button {
  margin-right: 20px;
}
