@import '../../main.module.scss';

.selector {
  padding-bottom: 42px;
}

.selection {
  background: none;
  border: 0;
  color: $focused-text-color;
  font-size: $regular-text-size;
  height: 43px;
  line-height: 30px;
  margin-left: 9px;
  margin-right: 9px;
  text-align: center;
}

.active-selection {
  border-bottom: 3px solid $focused-text-color;
}
