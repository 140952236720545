@import '../../../../../main.module';

.row {
  display: flex;
  flex-wrap: wrap;
}

.left-area {
  margin-right: 2em;
}
