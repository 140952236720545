@import '../../../../main.module';

.upper-area {
  display: flex;
}

form {
  & h3 {
    font-family: $default-font;
    font-size: $xs-title-text-size;
    font-weight: $title-font-weight;
    color: $default-text-color;
  }

  & h4 {
    font-family: $default-font;
    font-size: $regular-text-size;
    font-weight: $title-font-weight;
    color: $default-text-color;
  }
}

button.site-button {
  &::before {
    background: transparent url('/assets/public/trashbin.svg') center center no-repeat;
    background-size: contain;
    content: '';
    display: inline-block;
    height: 18px;
    margin-right: 12px;
    margin-top: -3px;
    vertical-align: middle;
    width: 16px;
  }

  color: $focused-text-color;
  font-size: $xs-text-size;
  font-weight: $title-font-weight;
  margin-bottom: 20px;
}

.name-edit-container {
  margin-top: 31px;
}

.delete-button {
  margin-top: 30px;
}

.icon {
  padding-right: 12px;
  position: relative;
  top: 5px;
}

.name-input {
  float: left;
}

.input-section {
  width: 300px;

  &--without-title {
    width: 300px;
  }
}

.input-field {
  color: $label-color;
  padding-top: 20px;
  width: 300px;
}

.last-input-field {
  padding-bottom: 10px;
}

.add-site-button {
  background-color: $link-color;
  border-color: $link-color;
  color: $white;
  margin-bottom: 20px;

  &:hover {
    border-color: $link-color;
    color: $link-color;
  }
}

.actions {
  padding-top: 60px;
}

.modify-button {
  margin-top: 55px;
}

.submit-validation-error-text {
  color: $error-text-color;
}

.edit-site-address-container {
  float: left;
  margin-right: 20px;
}
