@import '../../../main.module.scss';

.page-container {
  background: url('/assets/public/phonebook-login-bg.svg') no-repeat center bottom;
  background-color: #6f9dff;
  background-size: cover;
  padding: 200px;

  @include breakpoint(phone) {
    padding: 0;
  }

  @media (max-width: 992px) {
    padding: 0;
  }

  .content-container {
    margin: auto;
    max-width: 460px;
    border-radius: 4px;
    padding: 16px;

    .form-container {
      padding: 32px;

      button {
        width: 100%;
      }

      .info-text {
        width: 100%;
        text-align: center;
        margin: 10px;
        margin-bottom: 35px;
        color: #0019af;
        font-family: 'Jost Regular', sans-serif;
      }
    }
  }
}
