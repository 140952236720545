@import '../../../../../../main.module.scss';

.input-wrapper {
  padding-left: 1em;
  padding-top: 1.5em;
  width: 22em;

  & .loading-spinner {
    padding-left: 15em;
    padding-top: 4em;
    position: absolute !important;
    z-index: 400;
  }

  :global(.ds-combobox) {
    white-space: nowrap;
  }

  & .search-internal-destination {
    :global(.ds-combobox__button) {
      overflow-x: hidden;
    }

    :global(.ds-combobox--input-clear) {
      visibility: hidden;
    }

    :global(.ds-combobox__errormessage) {
      float: left;
    }

    :global(.ds-combobox__listbox[data-show="true"]) {
      width: 19em;
    }
  }
}
