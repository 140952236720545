@import '../../../main.module.scss';

.info-content {
  background-color: $disabled-input-bg-color;
  border: 1px solid $border-color;
  margin-bottom: 22px;
  padding: 30px;

  .header {
    font-size: $regular-text-size;
    font-weight: $title-font-weight;
  }

  .info-link {
    color: $link-color;
    cursor: pointer;
    font-weight: $title-font-weight;
  }
}
