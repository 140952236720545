@import '../../../../main.module';

.container {
  display: flow-root;
  justify-content: space-between;
  padding-top: 25px;
}

.topic-icon {
  margin-left: 0;
}

.title {
  font-size: $small-title-text-size;
  margin-bottom: 50px;
  margin-left: 60px;
  margin-top: -40px;
}

.modify-button {
  float: left;
  margin-left: 60px;
  margin-top: 25px;
  width: 100%;
}

.subtitle {
  font-size: $regular-text-size;
  font-weight: bold;
  margin-bottom: 20px;
  margin-left: 60px;
}
