@import '../../../main.module.scss';

.container {
  cursor: pointer;
  display: inline-block;
  height: 100%;
  width: 100%;
}

.segment {
  font-weight: 400;

  &.bold {
    font-weight: 600;
  }
}
