@import '../../main.module.scss';

.top-area {
  display: flex;
  align-items: center;
}

.image-content {
  height: auto;
  flex: 1;
}

.text-content {
  flex: 2;
  margin-left: 1.5em;
}

.title {
  color: $new-default-text-color;
  font-family: $large-heading-font;
  font-size: 28px;
  font-weight: $title-font-weight;
  line-height: 32px;
  text-align: start;
  width: 100%;
}

.description {
  font-size: $regular-text-size;
  padding-top: 1em;
  text-align: start;
  width: 100%;
}
