@import '../../main.module.scss';

.link {
  color: unset;

  &:hover {
    text-decoration: none;
  }
}

$padding: $breakpoint-phone-min-margin;

.row {
  min-height: 60px;
  position: relative;

  .check-container {
    display: flex;
    height: 100%;
    left: -30px;
    position: absolute;

    .check {
      display: none;
      height: 15px;
      margin-top: 21px;
    }

    .check-selected {
      height: 15px;
      margin-top: 21px;
    }
  }

  .hover-button-container {
    align-items: center;
    display: flex;
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;

    .hover-button {
      background-color: $white;
      color: $link-color;
      cursor: pointer;
      display: none;
      font-weight: $title-font-weight;
      line-height: 24px;
      padding: 10px;
      pointer-events: initial;
      text-transform: uppercase;
    }
  }

  &.hover-enabled {
    &:hover {
      color: $link-color;
    }

    &:hover > .check-container > .check {
      display: block;
    }

    &:hover > .hover-button-container > .hover-button {
      display: block;
    }
  }

  .row-selector-container {
    display: flex;
    height: 100%;
    left: -40px;
    margin-right: 300px;
    position: absolute;
    top: 25%;
  }

  @include breakpoint(phone-large-and-down) {
    .hover-button-container {
      display: none;
    }

    .check-container {
      left: unset;
      right: 0;
    }
  }
}

.border-top {
  border-bottom: $global-border-normal solid $color-smoke;
  margin-left: -40px;
}

.border-bottom {
  border-bottom: $global-border-normal solid $color-smoke;
  margin-left: 60px;
}

.row > .cells {
  box-sizing: initial;
  color: $color-dark-grey;
  display: flex;
  margin-bottom: 0;
  min-height: 60px;
  padding: 0;

  & .link {
    color: $focused-text-color;
    text-decoration: underline;
  }

  &:focus {
    outline: 0;
  }

  & > div {
    @include breakpoint(phone) {
      font-size: $xs-text-size;
    }

    cursor: pointer;
    flex-grow: 1;
    margin-bottom: 0;
    min-width: 78px;
    padding: $global-baseline * 0.6;
    padding-left: 0;

    &.x-small {
      max-width: 60px;
      min-width: 60px;
    }

    &.small {
      max-width: 78px;
    }

    @include breakpoint(phone-large-and-down) {
      &.small {
        max-width: 0;
        min-width: 58px;
      }
    }

    &.medium {
      flex-basis: 10%;
    }

    &.large {
      flex-basis: 25%;
    }
  }
}

.cell {
  align-items: center;
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;
  white-space: nowrap;
}

.cell-content {
  display: inline-block;
  line-height: $global-baseline * 2;
  max-width: 100%;
  overflow-x: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;
  vertical-align: top;

  .ea-icon {
    padding-left: $global-baseline * 2 + 25;
  }

  .icon-after {
    padding-right: 25px;

    & + span {
      position: relative;

      &::before {
        left: -25px + $global-baseline;
        top: 25px - $global-baseline * 2;
      }

      .ea-icon--northeast-arrow {
        &::before {
          height: 13px;
          left: -25px + $global-baseline;
          top: 4px;
          width: 13px;
        }
      }
    }
  }
}
