@import '../../../../../../main.module';

.container {
  margin-bottom: 50px;
}

.error {
  color: $error-text-color;
}

.header {
  font-size: $large-text-size;
  font-weight: $default-font-weight;
  margin-bottom: 12px;
}

.description {
  font-size: $xs-text-size;
  font-weight: $default-font-weight;
  margin-bottom: 40px;
}

.option-row {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}

.option-number-input {
  float: left;
  margin-bottom: 20px !important;
  margin-right: 20px !important;
  margin-top: 2px !important;
  width: 100px;
}

.option-next-step-input {
  float: left;
  margin-right: 20px !important;
  margin-top: 2px !important;
}

.new-step {
  color: $link-color;
}

.caller-input {
  display: inline-block !important;
  margin-right: 20px !important;
}

.trashbin {
  display: inline-block;
  padding-top: 42px;
}

.add-button {
  display: block;
}
