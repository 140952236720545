.dialog {
  height: 200px;
  padding: 80px 50px 50px;
  width: 300px;

  & .dropdown-icon {
    padding-right: 15px;
    padding-top: 60px;
  }
}
