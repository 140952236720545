@import '../../../../../../main.module';

.container {
  margin-top: 40px;

  & .dropdown {
    width: 300px;
  }

  & div > div > button > span {
    box-sizing: content-box;
  }

  :global(.ea-input__input) {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :global(.ea-combobox__item) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.combobox-description-area {
  display: flex;
}

.tooltip {
  margin-top: -5px;
  padding-left: 15px;
}

.combobox-description {
  color: $label-color;
  font-size: $xs-text-size;
  line-height: 17px;
}
