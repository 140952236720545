@import '../../../main.module.scss';
@import './DepartmentTreeBrowser.module.scss';

$department-treelist-column-margin: 10;
$department-treelist-card-height: 72;

.list {
  height: $department-browser-column-height + px;
  left: $department-browser-column-width + px;
  margin-left: 0;
  min-height: $department-browser-column-height + px;
  position: absolute;
  top: 0;
  width: ($department-browser-column-width - $department-treelist-column-margin) + px;
  z-index: 99;

  &:first-child {
    left: 0;
    position: relative;
  }
}
