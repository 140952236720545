@import '../../main.module';

.description {
  color: $focused-text-color;
  font-family: $default-font;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 33px;
  margin-bottom: 50px;
  margin-right: 20px;
  max-width: 1000px;
}

.language {
  line-height: 16px;
}
