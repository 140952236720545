@import '../../../main.module.scss';

h3 {
  color: $default-text-color;
  font-size: $large-text-size;
  font-weight: $default-font-weight;
}

.checkbox {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}

.unidentified-departments-container {
  margin: 24px 0;

  & > div {
    display: inline-block;
    margin: 0 20px 24px 0;
    vertical-align: middle;
  }
}

.unidentified-departments-checkbox {
  display: flex;
}

.tooltip-header {
  color: $default-text-color;
  font-size: $large-text-size;
  font-weight: $title-font-weight;
  margin-bottom: 12px;
}

.tooltip-text {
  color: $default-text-color;
  font-size: $regular-text-size;
  font-weight: $default-font-weight;
}
