@import '../../main.module.scss';

.icon {
  background-image: url('/assets/public/grid-inactive.svg');
  display: inline-block;
  height: 36px;
  width: 36px;

  &.active {
    background-image: url('/assets/public/grid-active.svg');
  }

  &:hover {
    background-image: url('/assets/public/grid-hover.svg');
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.3;
  }

  &.disabled:hover {
    cursor: default;
    background-image: url('/assets/public/grid-inactive.svg');
  }
}
