@import '../../../../../main.module';

.container {
  background-color: $content-background-color;
  border: 1px solid $dark-border-color;
  color: $default-text-color;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 25px;
  padding: 6px;
}

.text {
  margin-bottom: 6px;
  margin-top: 6px;
}

.error {
  float: left;
  height: 24px;
  margin: 12px 12px 30px;
  width: 24px;
}

.left-padding {
  margin-left: 50px;
}
