@import '../../main.module.scss';

.dismiss {
  color: $light-text-color;
  cursor: pointer;
  float: right;

  &:hover {
    color: $black;
  }
}
