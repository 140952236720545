@import '../../main.module.scss';

.loader {
  border-width: 3px;
  display: inline-block;
  height: 20px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  width: 20px;
}

.text {
  display: inline;
  padding-left: 8px;
  vertical-align: middle;
}
