@import '../../main.module';

.link {
  @include breakpoint(phone-large-and-down) {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  cursor: pointer;
  font-size: $regular-text-size;
  line-height: 24px;
  margin: 17px 0 37px;
  outline: none;

  img {
    margin-right: 12px;
  }
}
