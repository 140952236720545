@import '../../main.module.scss';

.container {
  display: inline-block;
  position: relative;
  width: 100%;

  .table {
    margin-right: 160px;
    width: 100%;
  }

  @include breakpoint(tablet-and-down) {
    .table {
      margin-right: 0;
    }
  }

  @include breakpoint(phone-large-and-down) {
    .table {
      /* add top border to first table row */
      & a:nth-child(1) > div > div > div {
        border-top: $global-border-normal solid $color-smoke;
      }
    }
  }

  .corner {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 49px;
  }

  .left-corner {
    align-items: center;
    display: block;
    height: 60px;
    justify-content: center;
    left: -2.5em;
    position: absolute;
    top: 20px;
    width: 49px;
  }
}
