.create-link-button {
  .hover-button {
    display: none;
  }

  :hover {
    .hover-button {
      display: block;
    }

    .active-button {
      display: none;
    }
  }

  .create-button {
    display: inline-block;
    white-space: nowrap;
  }
}
