@import '../../../../../main.module';

.button-area {
  display: flex;
  margin-left: 60px;
  padding-right: 10em;
  padding-top: 1.5em;
}

.error-message {
  color: $error-text-color;
  margin-left: 60px;
  margin-top: 10px;
}

.delete-button {
  float: right;
  margin-left: 2em;
}

.save-button {
  margin-right: 2em;
}
