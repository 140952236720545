@import '../../../main.module.scss';

.forwarding-details-page {
  margin-left: 100px;
  padding-bottom: 200px;

  & .info-box-container {
    margin-bottom: 62px;
    margin-left: 55px;
  }
}

.label {
  width: 100%;
}

.bottom-area {
  display: flex;
}

.left-area {
  margin-right: 2em;
  min-width: 22.8em;
  width: 100%;
}

.right-area {
  padding-right: 5em;
  width: 100%;
}

.form-area {
  width: 100%;
}

:global(.ds-card--outlined) {
  padding-left: 1em;
}
