@import '../../../../main.module';

.grid-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 127px;
}

.no-select {
  cursor: default;
}

.title {
  background-color: $light-background-color;
  color: $default-text-color;
  font-family: $default-font;
  font-size: 13px;
  font-weight: $title-font-weight;
  line-height: 16px;
  margin-top: -8px;
  text-align: center;
  width: 9em;
}

.grid-image {
  height: 75px;
  margin-bottom: 6px;
  width: 75px;
}

.details-container {
  background-color: $light-background-color;
  color: $default-text-color;
  display: flex;
  font-family: $default-font;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  overflow: hidden;
  max-height: 45px;
  mask-image: linear-gradient(180deg, #000 30px, transparent);
}
