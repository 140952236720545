@import '../../../../../main.module';

.strong {
  color: $default-text-color;
  font-family: $default-font;
  font-weight: $title-font-weight;
}

.copy-summary {
  margin-top: 12px;
}

.infobox {
  @include breakpoint(phone-large-and-down) {
    display: none;
  }
}
