@import '../../../main.module.scss';

.container {
  display: flex;

  .image {
    height: 25px;
    margin-right: 13px;
    width: 25px;
  }
}
