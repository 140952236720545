@import '../../../../main.module.scss';

.group-displayed-labels-area {
  padding-bottom: 5px;
  text-align: left;

  & .title-area {
    display: flex;
  }

  & .title {
    font-size: $regular-text-size;
    font-weight: $title-font-weight;
    padding-bottom: 15px;
    padding-right: 20px;
  }

  & .info {
    color: $label-color;
    font-size: $xs-text-size;
    line-height: 32px;
  }
}
