@import '../../main.module.scss';

.upper-container {
  @include breakpoint(phone) {
    justify-content: unset;
  }

  .icon-area {
    padding-left: 0.5em;
    padding-top: 0.45em;
  }

  @include breakpoint(tablet-and-up) {
    display: flex;
  }

  justify-content: space-between;

  & .search-container {
    display: flex;
    height: 54px;
    margin-bottom: 11px;
    margin-top: 17px;
    padding-top: 3px;

    & .category-field {
      float: left;
      padding-top: 0;
      width: 250px;

      @include breakpoint(tablet-and-down) {
        margin-left: 0;
        width: 100%;
      }
    }

    @include breakpoint(tablet-and-down) {
      display: block;
      max-width: 100%;

      & .input {
        width: 100%;
      }

      & :global(.ea-input) {
        margin-left: 0 !important;
      }

      & .search-input {
        padding-top: 0;
      }
    }
  }

  & .dropdown {
    margin-left: 1em;
    margin-top: 0;

    @include breakpoint(tablet-and-down) {
      margin-left: 0;
      width: 100%;
    }
  }

  & .external-link-container {
    margin-bottom: 37px;

    & .active-button {
      display: flex;
    }

    & .hover-button {
      display: none;
    }

    &:hover {
      & .hover-button {
        display: flex;
      }

      & .active-button {
        display: none;
      }
    }

    & .link-text {
      margin-top: 5px;
    }
  }
}

.external-contact-link {
  background: none;
  border: 0;
  color: $focused-text-color;
  cursor: pointer;
  font-family: $default-font;
  font-size: $regular-text-size;
  height: 54px;
  margin-top: 17px;
  outline: inherit;

  img {
    padding-left: 12px;
  }
}
