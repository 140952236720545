@import '../../main.module.scss';

.upper-area {
  padding-top: 35px;
}

.selector-area {
  display: flex;
  justify-content: space-between;
  width: 30em;

  & div > div > button > span {
    box-sizing: content-box;
  }

  & .reseller-select {
    width: 20em;
  }
}

.bulletin-area {
  padding-left: 50px;

  & .time-input {
    padding-top: 35px;
    width: 200px;
  }

  :global(textarea) {
    height: 200px;
  }
}

.save-bulletin-button {
  height: 60px;
  width: 180px;
}

.delete-bulletin-button {
  height: 60px;
  margin-left: 30px;
  width: 180px;
}

.button-container {
  padding-top: 35px;
}

.attachment-div {
  padding-right: 35px;
}

.error {
  color: $invalid-data-color;
}

:global(.ea-combobox__modal-header) {
  display: initial;
}
