@import '../../../../../main.module';

.container {
  margin-bottom: 25px;
  margin-top: 40px;
}

.title {
  color: $default-text-color;
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
  line-height: 24px;
  margin-bottom: 5px;
}

.description {
  color: $label-color;
  font-size: $xs-text-size;
  line-height: 17px;
  margin-bottom: 20px;
}
