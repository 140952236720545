@import '../../main.module.scss';

$user-details-width: 427;
$user-details-border-width: 10;
$user-details-filler: 40;

.error {
  float: left;
  margin-bottom: 200px;
  margin-right: 107px;
  margin-top: 0;
  padding-left: 32px;
  padding-right: inherit;
  width: 100%;

  .message {
    color: $focused-text-color;
    font-family: $default-font;
    font-size: $large-text-size;
    letter-spacing: 0.67px;
    line-height: 24px;
    margin-top: -30px;
  }
}

.container {
  min-height: $app-min-height;
  padding-bottom: 200px;

  & .error {
    background-color: $error-background-color;
  }
}

.users-container {
  width: 100%;

  .user-details-container {
    bottom: 0;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    right: -20px;
    top: 0;
    width: $user-details-width + 2 * $user-details-border-width + px;
    z-index: 100;

    @include breakpoint(phone) {
      left: 0;
      position: fixed;
      right: 0;
      width: 100%;
    }

    @media (min-width: #{2 + $user-details-filler}) {
      padding-left: $break-laptop-max - $user-details-width + $user-details-filler;
      right: unset;
    }

    .user-details-poser {
      background-color: $white;
      box-shadow: 0 0 $user-details-border-width + px 0 $black-medium-shadow-color;
      height: 100%;
      min-height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      pointer-events: auto;
      position: relative;
      width: $user-details-width + px;

      @include breakpoint(tablet-and-down) {
        top: 0;
        width: 100%;
      }
    }

    .user-details-poser-hidden {
      display: none;
    }

    &--details-open {
      box-shadow: 0 0 $user-details-border-width + px 0 $black-medium-shadow-color;
    }

    &--open {
      left: $menu-bar-width - $user-details-border-width;
      width: 100%;

      & .user-details-poser {
        width: 100%;
      }

      @include breakpoint(tablet-and-down) {
        left: 0;
      }
    }
  }

  .user-active {
    background-color: $white;
    z-index: 101;
  }

  & .content {
    @include breakpoint(tablet-and-up) {
      padding-left: 3.6em;
      padding-right: 3.6em;
    }

    @include breakpoint(phone) {
      margin-top: -100px;
      width: 100%;
    }

    @include breakpoint(desktop) {
      padding-left: 6.2em;
      padding-right: 6.2em;
    }

    @include breakpoint(tablet-and-down) {
      padding-top: 0;
    }

    padding-top: 13px;
    position: relative;

    & .small-table {
      display: none;
    }

    & .lock-icon {
      margin-left: 12px;
      width: 19px;
    }

    & .large-table {
      min-width: 805px;
    }

    @include breakpoint(phone-large-and-down) {
      & .small-table {
        display: block;
      }

      & .large-table {
        display: none;
      }
    }
  }

  .title {
    color: $default-text-color;
    font-size: $small-title-text-size;
  }
}

.card-list {
  background: url('/assets/public/users-bg.svg') no-repeat center;
  background-position: 53% 100%;
  background-size: cover;
  height: 282px;
  padding-top: 12px;

  @include breakpoint(tablet-and-down) {
    background: none;
    height: 190px;
  }

  @include breakpoint(phone-large) {
    height: 150px;
  }

  @include breakpoint(phone) {
    height: 230px;
  }

  .selector {
    cursor: default;
    margin-bottom: 5px;
    padding-left: 93px;

    @include breakpoint(tablet-and-down) {
      background: none;
      padding-left: 32px;
    }

    @include breakpoint(phone) {
      padding-left: 6px;
    }

    .selection {
      background: none;
      border: 0;
      color: $focused-text-color;
      font-size: $regular-text-size;
      height: 43px;
      line-height: 30px;
      margin-left: 9px;
      margin-right: 9px;
      text-align: center;
      width: 132px;
    }

    .active-selection {
      border-bottom: 3px solid $focused-text-color;
    }
  }

  .cards-container {
    margin-left: -11px;

    @include breakpoint(tablet-and-down) {
      margin-left: 0;
    }

    @include breakpoint(phone) {
      margin-top: -20px;
    }

    & .no-favorites {
      color: $section-title-color;
      font-size: $regular-text-size;
      padding-top: 2em;
      width: 625px;

      @include breakpoint(phone-large-and-down) {
        padding-left: 0;
        text-align: center;
        width: 100%;

        & div {
          display: none;
        }
      }
    }
  }

  .carousel-container {
    @include breakpoint(tablet-and-down) {
      padding-left: 5%;
      padding-right: 5%;
    }

    @include breakpoint(phone) {
      padding-top: 16px;
    }

    animation: fadein 1s;
    padding-left: 100px;
  }
}

/* stylelint-disable */
/* Hide unnecessary ellipses appearing on Chrome
 https://atlas.elisa.fi/jira/browse/RING-1870 */
:global(#usersTable div[id*='column-avatar'] span[class*='TableRow_cell-content']) {
  overflow: hidden;
  text-overflow: unset;
}

:export {
  userDetailsLeft: $user-details-border-width;
  userDetailsWidth: $user-details-width;
}
/* stylelint-enable */
