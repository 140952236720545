@import '../../../main.module.scss';

.container {
  padding-bottom: 207px;
  padding-right: 107px;

  .step-content {
    padding-bottom: 69px;
    padding-top: 69px;
  }

  .steps {
    text-align: center;
  }

  .return-button {
    padding-left: 50px;
  }

  h3 {
    color: $default-text-color;
    font-size: $small-title-text-size;
    font-weight: $default-font-weight;
  }

  .step-container {
    padding: 54px 0;
  }

  .export-failure {
    background-color: $error-background-color;
  }
}
