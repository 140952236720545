@import '../main.module.scss';

$size: 10px;

.steps-container {
  & > * {
    margin-right: $size / 2;
  }

  .step {
    background-color: $disabled-input-color;
    border-radius: 50%;
    display: inline-block;
    height: $size;
    width: $size;
  }

  .done {
    background-color: $disabled-input-color;
  }

  .clickable {
    cursor: pointer;
  }

  .current {
    background-color: $brand-blue;
    cursor: auto;
  }
}
