@import '../../../../../main.module';

.container {
  margin-bottom: 35px;
}

.input-title-area {
  display: flex;
}

.input-title {
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
  padding-right: 15px;
}

.input-description {
  color: $label-color;
  font-size: $xs-text-size;
  line-height: 17px;
}

.string-input {
  width: 250px;

  @include breakpoint(phone) {
    width: 100%;
  }
}
