@import '../main.module.scss';

.content-container {
  background-color: $white;
  border: 2px solid $border-color;
  border-radius: 2px;
  box-shadow: 0 0 0 9999px $light-shadow-color, 0 0 15px $light-shadow-color;
  box-sizing: border-box;
  margin-top: -8px;
}

.pop-over {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.pop-over-overlay {
  background-color: transparent;
  z-index: 100;
}

/* stylelint-disable */
:export {
  arrowWidth: 40;
  arrowHeight: 16;
}
/* stylelint-enable */
