@import '../../main.module.scss';

.title {
  align-items: center;
  display: flex;
  float: left;
  line-height: 36px;
  width: 374px;
  padding: 0;
  word-break: break-word;
}

.input {
  float: left;
  font-weight: $default-font-weight;
  margin-right: 20px;
  margin-top: 35px;
  width: 250px;
  padding-top: 4px;
}

.container {
  display: table;
  padding-top: 35px;

  table {
    display: table-cell;
    vertical-align: middle;
  }
}

.error {
  font-size: $small-text-size;
  font-weight: $light-font-weight;
  padding-top: 5px;
  width: 350px;
}

%button {
  cursor: pointer;
  display: inline-block;
  float: left;
  margin-right: 20px;
  position: relative;

  span {
    display: inline-block;
    font-size: 13px;
    letter-spacing: 0.81px;
    line-height: 18px;
    margin: 0;
    padding: 6px 6px 6px 12px;
    position: relative;
    text-transform: uppercase;
  }
}

.button-cell {
  vertical-align: bottom;
}

.edit-button {
  @extend %button;

  color: $default-text-color;
  display: flow;
  padding-left: 30px;
  padding-top: 10px;

  i {
    align-self: flex-end;
    display: inline-block;
    font-size: 27px;
  }

  span {
    vertical-align: top;
    visibility: hidden;
  }

  &:hover {
    i {
      font-size: 27px;
    }

    span {
      color: #00239d;
      vertical-align: center;
      visibility: visible;
    }
  }
}

.edit-area {
  & .button-area {
    display: flex;
    padding-top: 39px;

    & .save-button {
      margin-right: 12px;
    }
  }
}
