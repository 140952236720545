@import '../../main.module.scss';

.content {
  padding-top: 2em;
}

.title {
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
  padding-bottom: 1em;
  padding-right: 20px;
}

.day-list {
  padding-bottom: 2em;
}
