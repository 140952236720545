@import '../main.module.scss';

.tooltip {
  padding-top: 3px;
}

.tooltip-title {
  font-size: $large-text-size;
  font-weight: $title-font-weight;
  padding-bottom: 10px;
}

.tooltip-text {
  font-size: $regular-text-size;
  font-weight: $default-font-weight;
}
