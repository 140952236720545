@import '../../../main.module.scss';

.container {
  z-index: 101;

  .content {
    background-color: $white;
    border: 2px solid $border-color;
    border-radius: 2px;
    box-shadow: 0 0 0 9999px $light-shadow-color, 0 0 15px $light-shadow-color;
    box-sizing: border-box;
    height: auto;
    left: 10%;
    margin-top: -8px;
    padding-right: 15px;
    padding-top: 15px;
    position: fixed;
    top: 10%;
    z-index: 102;
    width: 700px;

    @include breakpoint(phone) {
      left: 0;
    }
  }
}

.error {
  color: $invalid-data-color;
}

.subheader {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 5px;
}

.infotext {
  font-weight: 100;
  margin-top: 5px;
  margin-bottom: 5px;
}

.flex-container {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  column-gap: 34px;

  & > div {
    min-width: 240px;
  }
}

.box {
  padding-bottom: 30px;
  padding-left: 23px;
  padding-top: 20px;

  p {
    &:last-of-type {
      margin-bottom: 25px;
      overflow-x: hidden;
    }
  }

  .buttons {
    margin-top: 20px;
    text-align: right;
  }
}

.checkbox-container {
  display: flex;

  & > span {
    margin: 8px 0 0 4px;
  }
}
