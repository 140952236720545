@import '../../main.module.scss';

.target-options {
  width: 405px;
}

@include breakpoint(tablet-and-down) {
  .target-options {
    width: 100%;
  }
}
