@import '../../../../../../main.module';

.title-padding {
  padding-top: 40px;
}

.description {
  color: $label-color;
  font-size: $xs-text-size;
  line-height: 17px;
  margin-bottom: 20px;
}

.midnight {
  color: $light-gray-color;
  left: 2px;
  position: relative;
  top: -12px;
}
