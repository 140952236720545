@import '../../main.module';

.status {
  display: flex;
}

.status-icon {
  margin-right: 0.5em;
  margin-top: 0.5em;
}

.date-input-area {
  display: flex;
  line-height: 28px;
}

.date-input {
  padding-right: 1em;
  width: 11em;
}

.edit-field {
  color: #00239d;
  cursor: pointer;
  text-transform: uppercase;
}

.time-input {
  height: 3em;
}

.time-start-field {
  padding-left: 3em;
  padding-top: 1.8em;
}

.time-fields {
  display: flex;

  &--middle {
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.7em;
  }
}

.checkbox {
  padding-bottom: 1em;
  padding-top: 1em;
  z-index: 0 !important;
}

.repetition-area {
  padding-bottom: 1em;
}

.day-list {
  display: flex;
}

.error {
  color: $error-text-color;
}

.cf-checkbox {
  padding-bottom: 1em;
  z-index: 0 !important;
}

.presence-modal {
  top: 5em !important;
}

:global(.ds-modal-buttons button) {
  font-size: $small-text-size;
}
