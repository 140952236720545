@import '../../main.module.scss';

.user-item-details {
  color: $default-text-color;
  display: inline-block;
  padding: 0 8px;
  vertical-align: top;
  width: calc(100% - 48px);
}

.no-title {
  overflow-wrap: anywhere !important;

  @include breakpoint(laptop-and-up) {
    padding-top: 11px;
  }
}

.user-item-avatar {
  @include breakpoint(laptop-and-up) {
    padding-top: 3px;
  }
}

.user-item-details-name {
  width: 100%;
  overflow-wrap: anywhere !important;

  @include breakpoint(tablet-and-down) {
    margin-top: 8px;
  }
}

.user-item-details-title {
  @include breakpoint(laptop-and-up) {
    display: block;
  }

  color: $label-color;
  display: none;
  width: 100%;
  overflow-wrap: anywhere !important;
}

.address-number {
  color: $label-color;
}
