@import '../../../main.module.scss';

/* stylelint-disable */
:export {
  rowHeight: 80;
}
/* stylelint-enable */

.selector {
  cursor: default;
  margin-bottom: 14px;

  .selection {
    border-bottom: 3px solid $focused-text-color;
    color: $focused-text-color;
    font-size: $regular-text-size;
    height: 45px;
    line-height: 30px;
    margin-left: 9px;
    margin-right: 9px;
    text-align: center;
    width: 132px;
  }
}

.search-input {
  width: 407px;
}

.dismiss {
  cursor: pointer;
  float: right;
}

.close-button {
  margin-bottom: 60px;
  position: absolute;
  right: 40px;
  top: 882px;
}

.move-button {
  margin-right: 50px;
}

.move-title {
  font-size: $large-text-size;
  font-weight: $title-font-weight;
  padding-bottom: 34px;
  padding-top: 32px;
}

.modify-button {
  &:disabled {
    border-color: $border-color;
    color: $light-text-color;

    &:hover {
      border-color: $border-color;
      color: $light-text-color;
    }
  }
}

.no-people-content {
  border-bottom: 1px solid $light-text-color;
  margin-top: 63px;
  padding-bottom: 180px;

  .add-people-link {
    text-decoration: underline;
  }

  .icon {
    float: left;
    margin-right: 30px;
  }

  .no-people-text {
    padding-top: 30px;
  }
}

.no-people-without-department-content {
  margin-top: 63px;

  .icon {
    float: left;
    margin-right: 30px;
  }

  .no-people-without-department-text {
    padding-top: 30px;
    width: 400px;
  }
}

.user-selector-container {
  margin-bottom: 24px;
  margin-top: 75px;

  .add-people-title {
    font-size: $large-text-size;
    font-weight: $title-font-weight;
  }

  .add-people-description {
    font-size: $large-text-size;
    margin-bottom: 26px;
    margin-top: 32px;
  }
}

.add-people-button {
  margin-right: 20px;
}

.delete-button {
  margin-bottom: 30px;
  margin-top: 30px;
}

.select-all-department-users-container {
  display: flex;
}

.selected-users-label {
  color: $label-color;
  float: right;
  margin-right: 7px;
  margin-top: -30px;
}

.search-section {
  margin-top: 6px;
}

.user-grid {
  margin-top: -10px;
}

.user-grid-container {
  border-bottom: 1px solid $light-text-color;
  margin-top: 30px;
}

.button-container {
  margin-bottom: 60px;
  padding-top: 36px;
}

.static-dropdown {
  & :global(.ea-combobox__options) {
    position: static;
    width: 300px;
  }
}

.no-search-results-container {
  text-align: center;

  h2 {
    color: $focused-text-color;
    font-size: $large-text-size;
    font-weight: 400;
    height: 24px;
    line-height: 24px;
    margin: 0;
    position: relative;
    top: -41px;
  }
}
