@import '../../../main.module.scss';

.container {
  @include breakpoint(laptop-and-down) {
    width: 100%;
  }

  padding-bottom: 11px;
  padding-top: 11px;
  position: relative;
  width: 1119px;
}

.left-arrow-container {
  left: 0;
  position: absolute;
  top: -11px;
}

.right-arrow-container {
  position: absolute;
  right: 0;
  top: -11px;
}

.cards {
  margin-top: 11px;
}

.spacer {
  @include breakpoint(tablet-and-down) {
    height: 92px;
  }

  @include breakpoint(phone-large-and-down) {
    height: 58px;
  }

  height: 182px;
  width: 182px;
}
