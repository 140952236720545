@import '../../../../../main.module';

:global(.ds-selectgroup__list-item) {
  height: fit-content !important;
  width: 20em !important;
}

:global(.ds-selectgroup__list-item__text) {
  padding-right: 2em !important;
  padding-left: 2em !important;
  text-align: center !important;
}
