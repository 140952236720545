@import '../../main.module';

.container {
  @include breakpoint(phone-large-and-down) {
    margin-bottom: 0;
    padding-left: 0;
  }
}

.cell {
  color: $label-color;
  display: inline-block;
  font-size: 14px;
  margin-right: 40px;

  @include breakpoint(phone-large-and-down) {
    color: $light-text-color;
    font-size: $xs-text-size;
  }
}
