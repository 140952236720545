@import '../../main.module.scss';

.text-link {
  background: none;
  border: 0;
  color: $focused-text-color;
  cursor: pointer;
  font-family: $default-font;
  font-size: $regular-text-size;
  height: 21px;
  line-height: 21px;
  outline: inherit;
  padding: 0;
}
