@import '../../main.module.scss';

.changelog-container {
  overflow: hidden;
  display: flex;

  & .tab-navigation {
    width: 100%;

    div {
      margin: 0 !important;
    }
  }

  & .changelog-browser {
    width: 412px;
    flex-shrink: 0;
    border-right: 1px solid $light-border-color;
    display: flex;

    @include breakpoint(laptop-and-down) {
      width: 300px;
    }

    @include breakpoint(phone-large-and-down) {
      width: 100%;
    }

    & .changelog-items {
      overflow-y: auto;
      flex-grow: 1;

      @include breakpoint(tablet-and-down) {
        max-height: 100vh;
        height: calc(100vh - 120px) !important;
      }

      @include breakpoint(laptop-and-up) {
        max-height: 70vh;
      }

      & .changelog-browser-item {
        background-color: transparent !important;
        color: $default-text-color !important;
        border: none !important;
        border-radius: 0 !important;
        border-bottom: 1px solid $light-border-color !important;
        padding-left: 0 !important;

        & .changelog-browser-item-content {
          width: 100%;
          display: flex;
          text-transform: none;
          font-size: 14px;
          justify-content: space-between;
          line-height: 1em;
          font-family: $default-font;

          & .headline {
            text-align: left;
            margin-right: 16px;
            margin-left: 22px;
          }
        }

        & .unread {
          font-weight: bold;
        }
      }

      & .changelog-browser-item:hover {
        background-color: $dark-border-color !important;
      }

      & .no-unread-items {
        text-align: center;
        padding-top: 32px !important;
      }

      & .changelog-browser-item-selected {
        background-color: #deedff !important;
        color: $default-text-color !important;
        border-right: 2px solid $link-color !important;
      }
    }
  }

  & .right-column {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    & .text-container {
      overflow-y: auto;
      height: 50vh;
      padding: 0 32px;
      flex-grow: 1;

      @include breakpoint(laptop-and-up) {
        max-height: 70vh;
      }

      @include breakpoint(tablet-and-down) {
        height: calc(100vh - 140px) !important;
        padding: 0 22px;
      }

      @include breakpoint(phone-large-and-down) {
        height: calc(100vh - 120px) !important;
        border-top: 1px solid $light-border-color;
        border-bottom: 1px solid $light-border-color;
        padding-top: 32px;
      }

      .header {
        padding-right: 42px;

        & h1 {
          font-family: $large-heading-font;
          font-size: $large-title-text-size !important;
          line-height: 4rem !important;
        }

        & h2 {
          font-weight: bold;
          margin-top: 20px;
          padding-bottom: 0;
        }

        & h3 {
          font-weight: bold;
          margin-top: 20px;
          padding-bottom: 0;
        }
      }

      & .text-content {
        font-size: $regular-text-size;
        width: 100%;
        overflow-x: auto;

        & h1 {
          font-family: $large-heading-font;
          font-size: $large-title-text-size;
          font-weight: $title-font-weight;
          line-height: 4rem;
        }

        & p {
          color: $default-text-color;
        }

        & ol {
          list-style-type: auto;
        }

        & ul {
          list-style-type: initial;
          padding-left: 50px;
        }
      }
    }

    & .bottom-area {
      display: flex;
      justify-content: flex-end;
      flex-shrink: 0;
      padding: 1em;
      width: 100%;

      button {
        margin-left: 5px;
      }
    }
  }
}

.modal {
  padding: 0 !important;
  overflow: hidden;

  /* stylelint-disable */
  & > h3 {
    margin-left: 22px;
  }

  & > span {
    position: absolute !important;
    right: 10px;
    top: 10px;
  }

  & :global(#changeLogItemTabstab-button-0) {
    margin-left: 22px;
  }
  /* stylelint-enable */

  @include breakpoint(tablet-and-down) {
    width: 100%;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;
  }

  @include breakpoint(laptop-and-up) {
    width: 90%;
  }

  & .hide-on-mobile {
    @include breakpoint(phone-large-and-down) {
      display: none;
    }
  }

  & .hide-unless-mobile {
    @include breakpoint(tablet-and-up) {
      display: none;
    }
  }
}

:global(li.ql-indent-1) {
  margin-left: 50px;
}
