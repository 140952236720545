@import '../../../../../../main.module';

.container {
  display: flex;
  flex-direction: column;
  width: 341px;

  & .player-dismiss {
    margin-left: 13em;
  }

  & .dropzone-dismiss {
    align-self: flex-end;
    margin-left: 13em;
  }
}
