.favourite-button {
  background: transparent url('/assets/public/favorite.png') no-repeat center;
  background-size: 45px 45px;
  border: 0;
  height: 45px;
  padding: 0;
  width: 45px;

  &--selected {
    background: transparent url('/assets/public/favorite_selected.png') no-repeat center;
    background-size: 45px 45px;
  }

  &:hover {
    background: transparent url('/assets/public/favorite_hover.png') no-repeat center;
    background-size: 45px 45px;
  }

  &.disabled {
    &:hover {
      background: transparent url('/assets/public/favorite.png') no-repeat center;
      background-size: 45px 45px;
    }
  }
}
