@import '../../../main.module';

.forwarding-row {
  color: $default-text-color;
  font-size: $regular-text-size;
  line-height: 24px;
  margin: 0;
  padding-bottom: 19px;
  padding-right: 20px;
  padding-top: 17px;
}

.disabled-row {
  color: $light-text-color;
}
