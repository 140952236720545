@import '../../main.module.scss';

.select-enterprises-area {
  display: flex;
}

.enterprise-list {
  line-height: 18px;
}

.enterprises-area {
  border: 1px solid $border-color;
  height: 200px;
  margin-right: 30px;
  overflow-y: scroll;
  width: 50%;
}

.servicepack-area {
  border: 1px solid $border-color;
  height: 200px;
  overflow-y: scroll;
}

.buttons-area {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.search {
  padding-top: 35px;
  width: 250px;
}
