@import '../../../main.module';

.search-container {
  display: flex;
  height: 54px;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-right: 0;
  margin-top: 30px;
  width: 75%;

  & .toggle {
    margin-left: 20px;
    padding-top: 10px;
    width: 350px;
  }

  :global(.ea-input) {
    padding: 0;
    width: 400px;
  }

  @include breakpoint(tablet-and-down) {
    display: block;
    margin-bottom: 135px;
    margin-top: 55px;
    width: 380px;

    :global(.ea-input) {
      width: 100%;
    }
  }

  @include breakpoint(tablet-and-down) {
    margin-bottom: 185px;
    margin-top: 30px;
    width: 90%;
  }

  /* stylelint-disable */
  @include breakpoint(tablet) {
    :global(TableRow__x-large) {
      flex-basis: 10%;
    }
  }
  /* stylelint-enable */
}
