@import '../../main.module.scss';

.avatar {
  background-color: $white;
  border: 2px solid $white;
  border-radius: 999px;
  display: inline-block;

  &:focus {
    outline: 0;
  }

  :global(.UserAvatar--inner) {
    display: inline-flex;
  }

  &.large {
    font-family: $large-heading-font;
    height: 150px;

    :global(.UserAvatar--light) {
      color: $dark-background-color;
      font-size: $xl-title-text-size;
      font-weight: 300;
    }

    :global(.UserAvatar--dark) {
      color: $white;
      font-size: $xl-title-text-size;
      font-weight: 300;
    }
  }

  &.medium {
    height: 79px;
    width: 79px;

    :global(.UserAvatar--light) {
      color: $dark-background-color;
      font-size: 26px;
      font-weight: $default-font-weight;
    }

    :global(.UserAvatar--dark) {
      color: $white;
      font-size: 26px;
      font-weight: $default-font-weight;
    }
  }

  &.small {
    height: 42px;
    margin-right: 1em;
    width: 42px;

    :global(.UserAvatar--light) {
      color: $dark-background-color;
      font-size: 16px;
      font-weight: $default-font-weight;
    }

    :global(.UserAvatar--dark) {
      color: $white;
      font-size: 16px;
      font-weight: $default-font-weight;
    }
  }

  &.xsmall {
    border: 0;
    height: 32px;
    width: 32px;

    :global(.UserAvatar--light) {
      color: $dark-background-color;
      font-size: 14px;
      font-weight: $default-font-weight;
    }

    :global(.UserAvatar--dark) {
      color: $white;
      font-size: 14px;
      font-weight: $default-font-weight;
    }
  }

  .availability-indicator-container {
    position: relative;

    &.large {
      left: 116px;
      top: -26px;
    }

    &.medium {
      left: 51px;
      top: -15px;
    }

    &.small {
      left: 27px;
      top: -10px;
    }

    &.xsmall {
      left: 20px;
      top: -8px;
    }
  }

  .edit-button-container {
    position: relative;

    &.large {
      left: -30px;
      top: -60px;
    }
  }

  &.shadow {
    box-shadow: 0 3px 6px 0 $dark-shadow-color;
  }
}
