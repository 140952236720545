@import '../../../main.module.scss';

.top {
  background-color: $white;
  min-width: 550px;
  padding-bottom: 14px;
  padding-top: 34px;
  text-align: center;
  width: 100%;
  z-index: 101;

  .buttons-bar {
    height: 20px;

    .user-name {
      color: $default-text-color;
      font-size: $regular-text-size;
      font-weight: $title-font-weight;
      margin-left: -100px;
      margin-top: -23px;
    }
  }

  @include breakpoint(phone) {
    min-width: 0;
    padding-top: 0;

    .buttons-bar {
      .user-name {
        margin-left: 0;
      }
    }
  }
}
