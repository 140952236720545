@import '../../../main.module.scss';

.enterprise-settings-area {
  & .two-columns {
    display: flex;
  }

  & .section-title {
    color: $default-text-color;
    font-family: $default-font;
    font-size: $small-title-text-size;
    font-weight: $default-font-weight;
    line-height: 36px;
    margin-bottom: 22px;
    margin-top: 0;

    &--sub {
      font-size: $small-title-text-size;
      padding-bottom: 1em;
      padding-top: 1em;
    }
  }

  & .subsetting-area {
    margin-bottom: 2em;
    margin-left: 1em;
    margin-top: -1em;
  }

  & .info-header {
    padding-right: 1em;
  }

  & .setting-area {
    display: flex;
  }

  & .section-setting-area {
    margin-bottom: 2em;
  }

  & .button {
    margin-top: 3em;
  }

  & .input {
    width: 30em;
  }

  & .number-input {
    width: 15em;
  }

  & .dropdown {
    width: 30em;
  }

  & .indented {
    margin-left: 30px;
    margin-top: 20px;
  }

  & .input-label {
    font-size: $small-text-size;
    font-weight: bold;

    &--disabled {
      color: #70707a;
    }
  }
}
