@import '../../../main.module';

.table-container {
  margin-top: 30px;
}

@include breakpoint(phone) {
  .enterprise-callforwardings {
    margin-top: 28px;
  }
}

@include breakpoint(phone-large) {
  .enterprise-callforwardings {
    margin-top: 25px;
  }
}

@include breakpoint(tablet) {
  .enterprise-callforwardings {
    margin-top: 35px;
  }

  .table-container {
    .avatar {
      vertical-align: middle;
    }
  }
}
