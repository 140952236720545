@import '../../../../../../main.module';

.date-selector {
  padding-top: 1em;
  width: 15em;
}

.date-picker {
  position: initial !important;
}

.info-container {
  padding-top: 1em;
  width: 20em;
}

:global(.ds-disclaimer-buttons) {
  justify-content: flex-start !important;
}

:global(.ds-disclaimer-buttons button:not(:first-of-type)) {
  margin-left: 0 !important;
}
