@import '../../../../../main.module';

.title-area {
  display: flex;

  & .title {
    font-size: $regular-text-size;
    font-weight: $title-font-weight;
    margin-bottom: 12px;
    padding-right: 20px;
  }

  & .tooltip {
    margin-top: -3px;
  }
}

.wide-detail {
  margin-bottom: 40px;
}
