@import '../../../../main.module';

.time-input {
  margin: 8px;
  height: 34px;
}

.add-slot-button {
  margin-left: 8px;
}
