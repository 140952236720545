@import '../../main.module.scss';

%generic-button-text {
  display: flex;
  float: right;
  font-size: 13px;
  padding-left: 10px;
}

.container {
  text-transform: uppercase;
}

%generic-mode {
  cursor: pointer;
  display: flex;
  float: right;
  font: $default-font;
  font-size: $icon-font-size;
  text-align: right;
  visibility: visible;
}

.view-mode {
  @extend %generic-mode;
}

.edit-mode {
  @extend %generic-mode;
}

%generic-button {
  font-style: normal;
}

.modify-button-text {
  @extend %generic-button-text;

  color: $link-color;
  display: none;
}

.modify-button {
  @extend %generic-button;

  &:hover {
    color: $link-color;

    .modify-button-text {
      display: block;
    }
  }

  &.disabled {
    cursor: default;

    &:hover {
      :global(.ea-icon) {
        color: $light-text-color;
      }

      .modify-button-text {
        display: none;
      }
    }
  }
}

.save-button {
  @extend %generic-button;

  color: $focused-text-color;
  padding-left: 15px;

  &:hover {
    color: $link-color;
  }
}

.cancel-button {
  @extend %generic-button;

  color: $label-color;

  &:hover {
    color: $link-color;
  }
}

.edit-cancel {
  &:first-child {
    font-size: 16px;
  }
}

.cancel-button-text {
  @extend %generic-button-text;
}

.save-button-text {
  @extend %generic-button-text;
}

.saving-indicator {
  @extend %generic-mode;

  cursor: default;

  & > :global(.ea-loading) {
    border-width: 4px;
    height: 24px;
    margin-top: 7px;
    width: 24px;
  }
}

.save-success-indicator {
  @extend %generic-mode;

  cursor: default;

  & > :global(.ea-icon--check) {
    color: $green;
  }
}
