@import '../../../../main.module';

.title-padding {
  margin-bottom: 25px;
}

.description {
  color: $label-color;
  font-size: $xs-text-size;
  line-height: 17px;
  margin-bottom: 20px;
}

.no-message-checkbox {
  margin-bottom: 1em;
}

.small-title {
  color: $default-text-color;
  font-size: $small-text-size;
  font-weight: $title-font-weight;

  &--disabled {
    color: $disabled-input-color;
  }
}

.radio-container {
  float: left;
  width: 98%;
}

.radio {
  box-sizing: content-box;
  float: left;
}

.audio {
  float: left;
  margin-bottom: 20px;
  margin-left: 40px;
}

.container {
  margin-bottom: 1em;

  :global(.ds-inputhelp) {
    white-space: pre-line;
  }
}

.date-selector {
  padding-top: 1em;
  width: 15em;
}

.date-picker {
  position: initial !important;
}
