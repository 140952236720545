@import '../../main.module.scss';

.page-container {
  @include breakpoint(tablet-and-down) {
    background-color: $white;
  }

  @include breakpoint(laptop-and-up) {
    background-attachment: local;
    background-color: $bg-color;
  }

  box-sizing: border-box;
  min-width: 320px;
  padding-bottom: 100px;

  .content-container {
    margin: auto;
    max-width: 1439px;
    position: relative;

    & > * {
      @include breakpoint(phone-large-and-down) {
        padding: 10px 5%;
      }

      @include breakpoint(tablet) {
        padding: 22px 10%;
      }

      @include breakpoint(laptop-and-up) {
        padding: 44px 107px;
      }

      background-color: $white;
    }

    .title {
      @include breakpoint(laptop-and-up) {
        background-color: transparent;
      }

      background-color: $white;
      color: $new-default-text-color;
      font-family: $large-heading-font;
      font-size: 56px;
      height: 90px;
      padding-top: 50px;
    }

    .language {
      @include breakpoint(laptop-and-up) {
        background-color: transparent;
      }

      @include breakpoint(phone-large-and-down) {
        margin-right: 5%;
      }

      @include breakpoint(tablet) {
        margin-right: 10%;
      }

      padding: 0;
      position: absolute;
      right: 0;
      top: 146px;
    }

    .content {
      color: $default-text-color;
      font-family: $default-font;

      h1 {
        font-size: $small-title-text-size;
        font-weight: $default-font-weight;
        padding-bottom: 10px;
      }

      h2 {
        font-size: $xs-title-text-size;
        font-weight: $title-font-weight;
        padding: 25px 0;
      }

      p {
        font-size: $regular-text-size;

        &.error {
          color: $error-text-color;
          margin-top: 0;
          font-weight: bold;
        }

        &.text-style {
          margin-bottom: 40px;
        }

        img {
          display: block;
          margin: 46px auto;
          width: 387px;
        }
      }
    }
  }
}

.section-title {
  color: $new-default-text-color;
  font-size: $large-title-text-size;
  line-height: 2em;
}

.phonenumber-input {
  width: 12em;
}

.subsection-area {
  display: flex;
}

.subsection {
  padding-right: 5em;
  width: 40%;
}

.subsection-button {
  padding-bottom: 1em;
  padding-top: 1em;
}

.subsection-title {
  color: $new-default-text-color;
  font-size: $title-text-size;
  line-height: 2em;
}

.subsection-card {
  text-align: center;
}

.more-info {
  text-align: left;
}

.readmore-header {
  flex-grow: 0;
}

.link-button {
  font-size: $xs-text-size !important;
  margin-bottom: 1em;
}

/* Fix footer problem where it displayed incorrectly on small screens */
:global(.downloads-page .ea-fgrid__item--phone-large-ratio-dynamic-75::before) {
  padding-top: 0;
}
