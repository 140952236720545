@import '../../main.module.scss';

.notifications {
  height: 55px;
  text-align: center;
}

.adminui-page {
  padding: 0 80px 180px;

  & .rapi-admin-ui-button {
    font-size: 1rem;
    margin-bottom: 30px;
  }
}

.hide-shared-phonebook {
  :global(section#adminui-accordion3) {
    display: none;
  }
}
