@import '../../../../main.module';

.create-filter-on-target-number-section {
  margin-top: 55px;
}

.description-text {
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
  margin: 40px 0 12px;
}

.specific-validation-error {
  color: $error-text-color;
  font-size: $regular-text-size;
  margin-bottom: 12px;
}

.checkbox-container {
  margin-bottom: 18px;
}
