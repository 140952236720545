@import '../../../main.module.scss';

.move-users-indicator-container {
  color: $default-text-color;
  font-family: $default-font;
  margin-top: 120px;
  text-align: center;

  h2 {
    font-size: 34px;
    font-weight: 600;
  }

  div {
    font-size: 24px;
  }

  & .cancel-button {
    padding-top: 50px;
  }
}

.move-users-cancel-button {
  font-size: 16px;
  margin-top: 50px;
}
