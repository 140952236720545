@import '../../../../../../main.module';

.title {
  color: $default-text-color;
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
  margin-bottom: 5px;
}

.description {
  color: $label-color;
  font-size: $xs-text-size;
  line-height: 17px;
  margin-bottom: 20px;
}

.columns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
  width: 620px;
}

.tooltip {
  font-weight: $default-font-weight;
  margin-left: 15px;
}

.target-options {
  width: 300px;
}
