@import '../main.module.scss';

.container {
  display: inline-flex;
}

.selector {
  margin-bottom: 14px;

  .selection {
    color: $focused-text-color;
    cursor: pointer;
    font-size: $regular-text-size;
    height: 45px;
    line-height: 30px;
    margin-left: 9px;
    margin-right: 9px;
    text-align: center;

    &:hover {
      color: $link-color;
    }
  }

  .active-selection {
    border-bottom: 3px solid $focused-text-color;
    color: $focused-text-color;
    font-size: $regular-text-size;
    height: 45px;
    line-height: 30px;
    margin-left: 9px;
    margin-right: 9px;
    text-align: center;
  }

  .text {
    margin: 0 20px;
  }
}
