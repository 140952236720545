/* stylelint-disable */

$breakpoint-phone-max-width: 479px;
$breakpoint-phone-min-margin: 20px;
$breakpoint-phone-columns: $global-columns-small-screen;

$breakpoint-phone-large-min-width: 480px;
$breakpoint-phone-large-max-width: 639px;
$content-size-phone-large: 440px;
$breakpoint-phone-large-min-margin: ($breakpoint-phone-large-min-width - $content-size-phone-large) / 2;
$breakpoint-phone-large-columns: $global-columns-small-screen;
$breakpoint-phone-large-column-width: ($content-size-phone-large - ($breakpoint-phone-large-columns - 1) * $global-column-cap) / $breakpoint-phone-large-columns;

$breakpoint-tablet-min-width: 640px;
$breakpoint-tablet-max-width: 959px;
$content-size-tablet: 604px;
$breakpoint-tablet-min-margin: ($breakpoint-tablet-min-width - $content-size-tablet) / 2;
$breakpoint-tablet-columns: $global-columns-big-screen;
$breakpoint-tablet-column-width: ($content-size-tablet - ($breakpoint-tablet-columns - 1) * $global-column-cap) / $breakpoint-tablet-columns;

$breakpoint-laptop-min-width: 960px;
$breakpoint-laptop-max-width: 1399px;
$content-size-laptop: 904px;
$breakpoint-laptop-min-margin: ($breakpoint-laptop-min-width - $content-size-laptop) / 2;
$breakpoint-laptop-columns: $global-columns-big-screen;
$breakpoint-laptop-column-width: ($content-size-laptop - ($breakpoint-laptop-columns - 1) * $global-column-cap) / $breakpoint-laptop-columns;

$breakpoint-desktop-min-width: 1400px;
$breakpoint-desktop-max-width: 1919px;
$content-size-desktop: 1260px;
$breakpoint-desktop-min-margin: ($breakpoint-desktop-min-width - $content-size-desktop) / 2;
$breakpoint-desktop-columns: $global-columns-big-screen;
$breakpoint-desktop-column-width: ($content-size-desktop - ($breakpoint-desktop-columns - 1) * $global-column-cap) / $breakpoint-desktop-columns;

$breakpoint-large-desktop-min-width: 1920px;

@mixin breakpoint($point) {

    @if $point == phone {
        @media only screen and (max-width: $breakpoint-phone-max-width) { @content; }
    }
    @if $point == phone-large {
        @media only screen and (min-width: $breakpoint-phone-large-min-width) and (max-width: $breakpoint-phone-large-max-width) { @content; }
    }
    @if $point == phone-large-and-up {
        @media only screen and (min-width: $breakpoint-phone-large-min-width) { @content; }
    }
    @if $point == phone-large-and-down {
        @media only screen and (max-width: $breakpoint-phone-large-max-width) { @content; }
    }
    @if $point == tablet {
        @media only screen and (min-width: $breakpoint-tablet-min-width) and (max-width: $breakpoint-tablet-max-width) { @content; }
    }
    @if $point == tablet-and-up {
        @media only screen and (min-width: $breakpoint-tablet-min-width) { @content; }
    }
    @if $point == tablet-and-down {
        @media only screen and (max-width: $breakpoint-tablet-max-width) { @content; }
    }
    @if $point == laptop {
        @media only screen and (min-width: $breakpoint-laptop-min-width) and (max-width: $breakpoint-laptop-max-width) { @content; }
    }
    @if $point == laptop-and-up {
        @media only screen and (min-width: $breakpoint-laptop-min-width) { @content; }
    }
    @if $point == laptop-and-down {
        @media only screen and (max-width: $breakpoint-laptop-max-width) { @content; }
    }
    @if $point == desktop {
        @media only screen and (min-width: $breakpoint-desktop-min-width) { @content; }
    }
}
/* stylelint-enable */
