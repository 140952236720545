@import '../../main.module.scss';

.contact-selector {
  text-align: left;

  & .radio {
    box-sizing: content-box;
  }

  & .input-wrapper {
    margin-left: 42px;
    margin-top: 20px;
    padding-bottom: 20px;
    padding-top: 0;
    width: 275px;

    & .input-field {
      width: 275px;
    }
  }

  :global(.ea-input__addon--auto-status) {
    display: none;
  }
}
