@import '../../main.module.scss';

.text-label {
  color: $default-text-color;
  display: block;
  font-size: $regular-text-size;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;

  &--missing {
    color: $light-text-color;
    font-style: italic;
    font-weight: normal;
  }
}

@include breakpoint(tablet-and-up) {
  .reasons-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-right: 8px;

    & div {
      & .text-area {
        display: inline-block;
        hyphens: none !important;
        padding-right: 20px;
        white-space: normal !important;
        word-break: break-word !important;
      }
    }
  }
}

@include breakpoint(phone-large-and-down) {
  .reasons-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-top: 17px;

    & .text-area {
      max-width: 80%;
    }

    & .menu-icon {
      padding-right: 5px;
    }
  }
}
