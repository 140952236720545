@import '../../../main.module';

.action-button {
  cursor: pointer;
}

.name-item {
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin-right: 5px;
  overflow: hidden;
  text-decoration: none;
  width: 100%;

  & .name-text {
    align-self: center;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    @include breakpoint(phone) {
      color: $color-dark-grey;
      font-size: $global-base-font-size;
      max-width: 215px;
    }
  }
}

.action-container {
  color: $link-color;
  display: inline-block;
  white-space: nowrap;

  .action-button {
    float: right;

    svg {
      height: 20px;
      vertical-align: middle;
      width: 20px;
    }
  }

  .action-text {
    float: left;
    margin-right: 12px;
  }

  @include breakpoint(phone-large-and-down) {
    margin-top: 5px;

    .action-text {
      font-size: $regular-text-size;
    }
  }

  @include breakpoint(phone) {
    float: left;

    .action-text {
      float: right;
      margin-left: 12px;
      margin-right: 0;
    }

    .action-button {
      float: left;
    }
  }
}

@include breakpoint(tablet) {
  .avatar {
    margin-right: 25px;
  }
}

@include breakpoint(phone-large) {
  .avatar {
    margin-right: 25px;
  }
}

@include breakpoint(laptop-and-up) {
  .vertical-center {
    margin-top: 15px;
  }

  .avatar {
    float: left;
    margin-right: 20px;
  }

  .name-item {
    height: 60px;

    .name-text {
      align-self: center;
    }
  }
}

@include breakpoint(phone-large-and-down) {
  .user-table-container {
    margin-top: 55px;
  }

  .vertical-center {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}
