@import '../../../../../main.module';

.info-box {
  @include breakpoint(phone-large-and-down) {
    display: none;
  }

  & .title {
    font-weight: $default-font-weight;
  }
}
