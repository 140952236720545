@import '../../../main.module';

.arrow {
  margin-top: 6px;
}

.text-label {
  color: $default-text-color;
  display: block;
  font-size: $regular-text-size;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;

  &--missing {
    color: $light-text-color;
    font-style: italic;
    font-weight: normal;
  }
}

.forwarding-table {
  margin-bottom: 95px;
  margin-top: 32px;
  overflow-wrap: break-word;
  table-layout: fixed;
  width: 100%;

  .deactive {
    color: $light-text-color;
  }

  .forwarding-icon {
    height: 20px;
    width: 19px;
  }

  .avatar {
    border-top: 0;
    width: 2em;
  }

  .disabled {
    color: $light-text-color;
    fill: $light-text-color;
  }

  .modify {
    width: 1%;
  }

  .forwarding {
    width: 99%;
  }

  .reason {
    display: inline-block;
    width: auto;
  }

  .arrow {
    display: inline-block;
    width: 2em;
  }

  .destination {
    width: 186px;
  }

  tr {
    td {
      border-top: 1px solid $border-color;
      color: $default-text-color;
      line-height: 24px;
      padding-bottom: 20px;
      padding-right: 21px;
      padding-top: 20px;
    }

    &:last-child {
      td {
        border-bottom: 1px solid $border-color;
      }

      .avatar {
        border-bottom: 0;
      }
    }
  }
}

@include breakpoint(phone) {
  .forwarding-table {
    font-size: $regular-text-size;
    margin-bottom: 0;
    margin-top: 22px;
    padding: 0;
    width: 100%;

    .menu-icon {
      float: right;
    }

    .reason {
      display: table-cell;
      width: 80%;
    }

    td {
      padding: 10px 0 16px;
    }
  }
}

@include breakpoint(phone-large) {
  .forwarding-table {
    font-size: $regular-text-size;
    margin-bottom: 0;
    margin-top: 22px;
    padding: 0;
    width: 100%;

    .avatar {
      padding-right: 20px;
      width: 20px;
    }

    .menu-icon {
      float: right;
    }

    .reason {
      display: table-cell;
      padding-bottom: 20px;
      padding-top: 20px;
      width: 70%;

      svg {
        margin-right: 15px;
        margin-top: 20px;
      }
    }

    td {
      padding: 0;
    }
  }
}

@include breakpoint(tablet) {
  .avatar {
    vertical-align: middle;
  }
}
