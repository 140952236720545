.edit-button {
  background: transparent url('/assets/public/edit.png') no-repeat center;
  background-size: 45px 45px;
  border: 0;
  height: 45px;
  margin-left: 36px;
  width: 45px;

  &:hover {
    background: transparent url('/assets/public/edit_hover.png') no-repeat center;
    background-size: 45px 45px;
  }
}

.disabled {
  background: transparent url('/assets/public/edit.png') no-repeat center;
  background-size: 45px 45px;

  &:hover {
    background: transparent url('/assets/public/edit.png') no-repeat center;
    background-size: 45px 45px;
  }
}

.display-as-block {
  display: block;
}
