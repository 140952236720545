@import '../../../main.module.scss';

$avatar-container-size: 156px;

.top {
  padding-bottom: 10px;

  .buttons-bar {
    height: 20px;

    .close-button {
      float: left;
    }
  }

  .button-area {
    border-bottom: 1px solid $border-color;
    padding-bottom: 10px;
    margin-top: -10px;
    text-align: center;
  }

  .edit-button-container {
    margin-top: 17px;
    text-align: center;
  }

  .avatar-container {
    height: $avatar-container-size;
    margin: -25px auto 0;
    width: $avatar-container-size;
    z-index: 100;
  }

  .background {
    background-color: $focused-text-color;
    height: 83px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  .user-name-and-title {
    color: $black;
    margin-bottom: 30px;
    margin-top: 18px;
    text-align: center;
    top: 50px;

    .user-name {
      font-size: $small-title-text-size;
      font-weight: $title-font-weight;
      line-height: 28px;
      margin-top: -13px;

      & span {
        margin-right: 10px;
        vertical-align: middle;
      }
    }

    .title {
      font-size: $regular-text-size;
      line-height: 24px;
      overflow-x: hidden;
    }

    .badge-container {
      padding-top: 5px;
      padding-bottom: 5px;

      & span {
        font-size: 0.9em !important;
        padding-top: 4px !important;
        padding-bottom: 4px !important;
      }
    }
  }
}
