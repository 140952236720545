@import '../../main.module.scss';

.modal {
  @include breakpoint(phone-large-and-down) {
    width: 100%;
  }

  @include breakpoint(tablet-and-up) {
    width: 500px;
  }
}

.sp-container {
  margin-left: 25px;
  overflow: hidden;
  padding: 34px 34px 0;

  & .spinner-container {
    padding-top: 32px;
    text-align: center;
    width: 100%;
  }

  & .title {
    font-family: $large-heading-font;
    font-size: $large-title-text-size;
    font-weight: $title-font-weight;
    line-height: 36px;
    padding-bottom: 42px;
  }

  & .bottom-area {
    padding-top: 32px;
    text-align: center;
    width: 100%;
  }

  & .text-content {
    font-size: $regular-text-size;
    width: 100%;

    & ol {
      list-style-type: initial;
    }
  }
}
