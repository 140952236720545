@import '../../../../../main.module';

.infobox {
  @include breakpoint(phone-large-and-down) {
    display: none;
  }

  & .title {
    font-weight: $title-font-weight;
    padding-bottom: 15px;
  }

  & .avatar {
    margin-right: 15px;
  }
}
