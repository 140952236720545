@import '../../main.module.scss';

.no-search-results-container {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 88px;
  padding-top: 88px;
  text-align: center;
  width: 50%;

  h2 {
    color: $focused-text-color;
    font-size: $large-text-size;
    font-weight: 400;
    height: 24px;
    line-height: 24px;
    margin: 0;
    position: relative;
    top: -41px;
  }
}

.small-table {
  display: none;
}

.large-table {
  margin-left: 2.2em;
  min-width: 805px;
}

@include breakpoint(phone-large-and-down) {
  .small-table {
    display: block;
  }

  .large-table {
    display: none;
  }
}
