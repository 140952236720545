@import '../../../main.module.scss';

.phonebook-area {
  & .section-title {
    color: $default-text-color;
    font-family: $default-font;
    font-size: $small-title-text-size;
    font-weight: $default-font-weight;
    line-height: 36px;
    margin-bottom: 22px;
    margin-top: 0;

    &--sub {
      font-size: $xsmall-title-text-size;
      padding-bottom: 1em;
      padding-top: 1em;
    }
  }

  & .phonebook-select {
    display: flex;

    & .remove-button {
      height: 0.5em;
      margin-left: 2em;
      margin-top: 2em;
    }
  }

  & .phonebook-list {
    margin-top: 1em;
    width: 20em;
  }

  & .setting-area {
    display: flex;
  }

  & .phone-book-name {
    width: 20em;
  }

  & .settings-area {
    display: flex;
  }

  & .settings-list {
    border: 1px solid $border-color;
    height: 40em;
    margin-bottom: 2em;
    overflow-y: scroll;
    width: 20em;

    &--right {
      margin-left: 1em;
    }
  }

  & .add-tenant-input {
    margin-bottom: 25px;

    div {
      margin-bottom: 10px;
    }
  }

  & .tenant-list {
    width: 25em;

    & .tenant-list-item {
      display: flex;
      align-items: center;
      border: 1px solid #e0e0e1;
      border-radius: 4px;
      padding: 8px;
      margin-top: 5px;

      & .id-text {
        overflow: hidden;
      }

      & .remove-item-button {
        margin-left: auto;
      }
    }
  }
}
