@import '../../../../main.module';

.callflow-container {
  background-color: $content-background-color;
  border: 1px solid $content-border-color;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  overflow-x: hidden;
  padding-bottom: 26px;
  padding-left: 30px;
  margin-right: 2em;
  padding-top: 26px;

  &--wide {
    margin-right: 2em;
    max-width: initial;
  }
}

.content-container {
  display: flex;
  flex-direction: row;
}

.description-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  width: 260px;
}

.modify-button {
  margin-top: 44px;
}

.title {
  color: $default-text-color;
  font-family: $default-font;
  font-size: $xs-title-text-size;
  font-weight: $title-font-weight;
  line-height: 26px;
  margin-bottom: 23px;
  width: 200px;
}

.description {
  color: $label-color;
  font-family: $default-font;
  font-size: $xs-text-size;
  line-height: 17px;
  margin-top: 23px;
  width: 200px;
}

.content {
  width: 100%;
}
