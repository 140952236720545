@import '../../main.module.scss';

.success-image-content {
  margin-bottom: 30px;
  margin-top: 80px;

  .success-image {
    display: block;
    height: 103px;
    margin-left: auto;
    margin-right: auto;
    width: 110px;
  }
}

.success-title {
  font-size: $xsmall-title-text-size;
  font-weight: $title-font-weight;
  text-align: center;
}

.success-description {
  margin-bottom: 80px;
  text-align: center;
}
