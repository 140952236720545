@import '../../../main.module.scss';

.dropzone {
  border: 2px dashed $focused-text-color;
  border-radius: 4px;
  cursor: pointer;
  height: auto;
  width: 100%;

  & > div {
    margin: auto;
    text-align: center;
  }

  & .error {
    background: url('/assets/public/error-red.svg') no-repeat center 48px;
    padding-top: 109px;
    width: 700px;

    & .error-header {
      font-size: $xs-title-text-size;
      font-weight: $title-font-weight;
    }

    & .error-text {
      font-size: $regular-text-size;
    }

    & .error-link {
      color: $focused-text-color;
      margin-bottom: 64px;
      padding-top: 24px;
    }

    & .button-area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 31px;
      margin-top: 31px;

      & .retry-button {
        margin: 0 auto;
        width: 220px;
      }

      & .return-button {
        margin-top: 31px;
      }
    }
  }

  & .choose {
    background: url('/assets/public/upload-file.svg') no-repeat center 68px;
    color: $focused-text-color;
    padding-bottom: 100px;
    padding-top: 149px;
    width: 350px;

    & .choose-header {
      font-size: $xs-title-text-size;
      font-weight: $title-font-weight;
    }

    & .choose-text {
      font-size: $regular-text-size;
    }
  }

  & .loading {
    color: $focused-text-color;
    padding-bottom: 114px;
    padding-top: 54px;

    & .loading-text {
      font-size: $xs-title-text-size;
      font-weight: $title-font-weight;
    }
  }
}

.error-table {
  margin-bottom: 62px;
  margin-top: 0;
  padding-right: 0;
  text-align: left;
}

.dropzone-active {
  border: 2px dashed $link-color;
}

.dropzone-error {
  border: 2px dashed $error-text-color;
}
