@import '../../../main.module';

.section-title {
  color: $default-text-color;
  font-family: $default-font;
  font-size: $small-title-text-size;
  font-weight: $default-font-weight;
  line-height: 36px;
  margin-bottom: 22px;
  margin-top: 0;
}

.section-description-area {
  @include breakpoint(tablet-and-up) {
    display: flex;
  }

  color: $default-text-color;
  font-size: $regular-text-size;
  font-weight: $default-font-weight;
  justify-content: space-between;
  line-height: 22px;

  & .section-description {
    width: 710px;

    @include breakpoint(phone-large-and-down) {
      padding-bottom: 1em;
      width: 100%;
    }
  }
}
