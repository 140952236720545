@import '../../main.module.scss';

.dropzone {
  border: 2px dashed $focused-text-color;
  border-radius: 4px;
  box-sizing: initial;
  cursor: pointer;
  height: 108px;
  padding-top: 30px;
  width: 341px;

  &--disabled {
    border: 2px dashed $disabled-input-color;
  }

  & > div {
    margin: auto;
    text-align: center;
  }

  & .choose-header {
    color: $focused-text-color;
    font-size: $large-text-size;
    font-weight: $title-font-weight;

    &--disabled {
      color: $disabled-input-color;
    }
  }

  & .detail {
    color: $focused-text-color;
  }

  & .delay-warning {
    color: $error-text-color;
    font-size: $small-text-size;
    line-height: 14px;
    margin-left: 5%;
    width: 90%;
  }

  & .choose-text {
    color: $focused-text-color;

    &--disabled {
      color: $disabled-input-color;
    }
  }

  & .error {
    background: url('/assets/public/error-red.svg') no-repeat top;
    margin-top: -25px;

    & .error-header {
      font-size: $xs-title-text-size;
      font-weight: $title-font-weight;
      padding-top: 50px;
    }

    & .error-link {
      color: $focused-text-color;
      margin-bottom: 64px;
      padding-top: 0;
    }
  }
}

.dropzone-active {
  border: 2px dashed $link-color;
}

.dropzone-error {
  border: 2px dashed $error-text-color;
}
