@import '../../main.module.scss';

.no-content-found-container {
  color: $label-color;
  display: inline-flex;
  font-size: $large-text-size;
  font-weight: $default-font-weight;
  margin-bottom: 39px;
  margin-top: 51px;
  width: 100%;
}

.text-link-container {
  display: block;
  margin-left: 30px;
  margin-top: 29px;

  p {
    margin: 0;
  }
}

.link-style {
  color: $focused-text-color;
  font-family: $default-font;
  font-size: $large-text-size;
  line-height: 24px;
  text-decoration: underline;
}
