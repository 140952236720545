@import '../../main.module.scss';

/* stylelint-disable function-no-unknown */
$green-color: #00a950;
$green-border-color: lighten($green-color, 35%);
$yellow-color: #ffd503;
$yellow-border-color: lighten($yellow-color, 20%);
$red-color: #e44;
$red-border-color: lighten($red-color, 20%);
$grey-color: #b0b0b1;
$grey-border-color: lighten($grey-color, 15%);
/* stylelint-enable function-no-unknown */

.availability-indicator {
  border-radius: 50%;
  border: 2px solid white;
  display: block;

  &.xsmall {
    height: 8px;
    width: 8px;
  }

  &.small {
    height: 10px;
    width: 10px;
  }

  &.medium {
    height: 16px;
    width: 16px;
  }

  &.large {
    height: 26px;
    width: 26px;
  }

  &.green {
    background-color: $green-color;
  }

  &.yellow {
    background-color: $yellow-color;
  }

  &.red {
    background-color: $red-color;
  }

  &.dnd {
    background-color: $red-color;

    & > svg {
      left: 2px;
      position: relative;
      top: -12px;
    }

    &.large {
      & > svg {
        height: 6px;
        left: 4px;
        position: relative;
        top: -1px;
        width: 18px;
      }
    }

    &.medium {
      & > svg {
        height: 4px;
        left: 1px;
        position: relative;
        top: -8px;
        width: 14px;
      }
    }
  }

  &.grey {
    background-color: $grey-color;
  }
}
