@import '../../../main.module.scss';

.date-table {
  width: 100%;

  th {
    border-bottom: 1px solid #1f0d0d;
    padding: 8px;
    text-align: left;
  }

  tr {
    border-bottom: 1px solid #cfcece;
  }

  td {
    padding: 8px;
  }

  .action-column {
    text-align: right;
  }
}

.overridden {
  opacity: 0.5;
}
