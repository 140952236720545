@import '../../main.module.scss';

@include breakpoint(laptop-and-up) {
  .buttons-container {
    padding-bottom: 26px;
  }
}

.search-content {
  @include breakpoint(laptop-and-up) {
    border-bottom: 1px solid $border-color;
    flex-direction: row-reverse;
    height: 613px;
    max-width: 1400px;

    & > * {
      width: 50%;
    }
  }

  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-y: hidden;
  width: 100%;

  .search-input-container {
    @include breakpoint(laptop-and-up) {
      :global(.ea-input) {
        width: 334px;
      }
    }

    :global(.ea-input) {
      max-width: 100%;
      padding-top: 0;
    }

    .search-results {
      @include breakpoint(laptop-and-up) {
        background: 0;
        border: 0;
        height: 518px;
        margin-top: 34px;
        max-height: 518px;
        overflow: auto;
        position: static;
      }

      background-color: $white;
      border-bottom: 1px solid $border-color;
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      margin-top: -13px;
      max-height: 254px;
      overflow: auto;
      width: 100%;

      .result-user-item {
        @include breakpoint(laptop-and-up) {
          padding-bottom: 30px;
        }

        cursor: pointer;
      }

      .first-result-item {
        @include breakpoint(tablet-and-down) {
          display: block;
          padding-top: 8px;
        }

        display: none;
      }
    }
  }

  .selected-container {
    @include breakpoint(laptop-and-up) {
      border-right: 1px solid $border-color;
      height: 100%;
      margin-right: 40px;
      margin-top: 0;

      .selected-description {
        margin-bottom: 64px;
        padding-top: 5px;
      }

      .selected-items {
        max-height: 519px;
        overflow: auto;
        padding-right: 26px;
      }
    }

    border-right: 0;
    margin-top: 32px;

    .selected-description {
      @include breakpoint(laptop-and-up) {
        display: block;
      }

      display: none;
    }

    .selected-description-small {
      @include breakpoint(tablet-and-down) {
        display: block;
      }

      display: none;
    }

    .select-users {
      color: $label-color;
      padding-top: 57px;
    }

    .selected-item {
      display: flex;
      justify-content: space-between;

      .selected-user-item {
        @include breakpoint(laptop-and-up) {
          padding-bottom: 30px;
          width: 100%;
        }

        width: 100%;
      }

      :global(.ea-icon--cross) {
        font-size: 18px;
      }
    }
  }

  .buttons-container {
    position: relative;
  }
}

.deselect-button {
  @include breakpoint(laptop-and-up) {
    display: inline-block;
    left: 2px;
    margin-top: 24px;
  }

  display: none;
}

.select-button {
  @include breakpoint(laptop-and-up) {
    display: inline-block;
    left: 254px;
    margin-top: 24px;
  }

  display: none;
}

.specific-validation-error {
  color: $error-text-color;
  font-size: $regular-text-size;
  margin-bottom: 12px;
}

.link-button {
  background-color: transparent;
  border: 0;
  color: $link-color;
  height: 34px;
  padding-left: 38px;
  text-align: left;
  width: 100%;
}

.show-more-less-link-button {
  background-color: transparent;
  border: 0;
  color: $link-color;
  padding-left: 50px;
  text-align: left;
  width: 100%;
}

.hide {
  visibility: hidden;
}
