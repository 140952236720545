@import '../../../main.module.scss';

.result-container {
  margin-bottom: 300px;
}

.table {
  display: flex;
  flex-direction: column;
  width: 1300px;
}

.import-all-button {
  margin: 20px 0;
}
