@import '../../../../main.module';

.title {
  color: $default-text-color;
  font-size: $xsmall-title-text-size;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.description {
  color: $dark-gray;
  font-size: $regular-text-size;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 30px;
}

.spinner {
  margin-top: 100px;
}
