@import '../../../main.module.scss';

.header-area {
  display: flex;
  justify-content: space-between;
}

.spinner {
  margin-top: 50px;
}

.section-title {
  color: $default-text-color;
  font-family: $default-font;
  font-size: $small-title-text-size;
  font-weight: $default-font-weight;
  line-height: 36px;
  margin-bottom: 22px;
  margin-top: 0;
}

.add-location-button {
  font-size: 1rem !important;
}

.location-name {
  font-weight: bold;
}

.locations-area {
  display: flex;
  width: 100% !important;
}

:global(.ds-selectgroup--type-normal) {
  width: 100%;
}
