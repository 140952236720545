@import '../../../main.module.scss';

.container {
  height: 65px;
  margin-left: 106px;
  max-width: 1047px;
}

.header-container {
  display: block;
  padding-bottom: 25px;
  padding-top: 25px;
}

.title {
  color: $default-text-color;
  font-family: $default-font;
  font-size: $small-title-text-size;
  font-weight: $default-font-weight;
  line-height: 36px;
  margin-bottom: 11px;
  margin-top: 0;
}

.dropzone {
  border: 2px dashed $focused-text-color;
  border-radius: 4px;
  cursor: pointer;
  height: auto;
  margin-bottom: 20px;
  width: 50%;

  & > div {
    margin: auto;
    text-align: center;
  }

  & .choose {
    background: url('/assets/public/upload-file.svg') no-repeat center 68px;
    color: $focused-text-color;
    padding-bottom: 100px;
    padding-top: 149px;
    width: 350px;

    & .choose-header {
      font-size: $xs-title-text-size;
      font-weight: $title-font-weight;
    }

    & .choose-text {
      font-size: $regular-text-size;

      span {
        text-decoration: underline;
      }
    }
  }
}

.dropzone-active {
  border: 2px dashed $link-color;
}
