@import '../../main.module.scss';

.edit-button {
  background: transparent url('/assets/public/edit_avatar.svg') no-repeat center bottom;
  background-size: 100% auto;
  border: 0;
  height: 3em;
  margin-left: 1.6em;
  width: 3em;
}

.display-as-block {
  display: block;
}
