@import '../../../main.module.scss';

.margin-bottom-medium {
  margin-bottom: 20px;
}

.container {
  border-bottom: 0;
  margin-bottom: 200px;
  margin-left: 40px;
  max-width: 1047px;
  padding-bottom: 200px;
}

.content-container {
  padding-left: 0;
}

.section-title {
  font-size: 16px;
  font-weight: 600;
}

.info-text {
  font-size: 16px;
  color: #333;
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-section {
  margin-top: 60px;
}

.form-container {
  margin-bottom: 100px;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

:global(.timeslots-container) {
  overflow: scroll;
  max-height: 300px;
  max-width: 520px;
}

.subheader-container {
  font-weight: bold;
}

.button-section {
  & button {
    margin-left: 15px;
  }
}

.dialog-content {
  padding: 20px;

  .dialog-buttons {
    text-align: right;
  }
}
