@import '../../../main.module.scss';

.contact-card-container {
  bottom: 0;
  height: 0;
  min-height: 100%;
  overflow: visible;
  position: sticky;
  float: right;
  right: -20px;
  top: 0;
  width: 427px;
  z-index: 10;
  box-shadow: 0 0 10px 0 #0000001c;
  background-color: white;

  @include breakpoint(phone) {
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    min-height: 0;
  }

  .contact-card-content {
    background: white;
    z-index: -2;
    position: absolute;
    right: 0;
    width: 100%;
    min-height: 100vh;
    box-shadow: inherit;
    box-shadow: 0 0 10px 0 #0000001c;

    @include breakpoint(phone) {
      min-height: 0;
    }

    .top-bg {
      background-color: #0019af;
      height: 83px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    .close-button {
      float: left;
      margin: 10px;
    }

    .show-more-button {
      margin-bottom: 20px;
    }

    .item-container {
      margin-bottom: 17px;
      overflow: hidden;

      .item-label {
        color: #767677;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 1px;
      }
    }

    .avatar-container {
      height: 156px;
      margin: 0 auto;
      width: 156px;
    }

    .accordion-header {
      margin-right: 15px;
    }

    .quick-info-area {
      text-align: center;
      font-size: 14px;
      margin-top: 30px;
      margin-bottom: 30px;

      .display-name {
        font-size: 16px;
        font-weight: bold;
      }

      .availability-indicator {
        display: inline-block;
        margin-right: 10px;
      }

      .department {
        color: #70707a;
      }
    }
  }
}
