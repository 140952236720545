@import '../../../../../../main.module';

.title-padding {
  margin-bottom: 25px;
}

.audio-field {
  align-items: flex-start;
}

.audio-area {
  padding-bottom: 1.5em;
  padding-top: 1.5em;
}

.description {
  color: $label-color;
  font-size: $xs-text-size;
  line-height: 17px;
  margin-bottom: 20px;
}

.small-title {
  color: $default-text-color;
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
}
