@import '../main.module.scss';

.container {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.icon {
  background: url('/assets/public/tooltip-icon.svg') no-repeat center;
  cursor: pointer;
  height: 21px;
  width: 21px;
}

.active {
  background: url('/assets/public/tooltip-icon-active.svg') no-repeat center;
}

.content {
  background-color: $white;
  border-radius: 2px;
  box-shadow: 0 1px 5px 0 $shadow-variant-color;
  left: -182px;
  padding: 20px;
  pointer-events: none;
  position: absolute;
  text-align: left;
  top: 44px;
  user-select: none;
  width: 384px;
  z-index: 10000;

  .children {
    position: relative;
    top: -20px;
  }

  .children > :first-child {
    pointer-events: auto;
    position: relative;
    top: -10px;
  }

  .arrow {
    height: 30px;
    position: relative;
    top: -41px;
  }
}

/* stylelint-disable */
:export {
  triangleArrowWidth: 28;
  triangleArrowHeight: 21;
  triangleArrowOffsetLeft: 158;
  triangleStrokeWidth: 1;
}
/* stylelint-enable */
