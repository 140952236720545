@import '../../../../main.module.scss';

.group-displayed-number-area {
  text-align: left;

  & .title-area {
    display: flex;
  }

  & .title {
    font-size: $regular-text-size;
    font-weight: $title-font-weight;
    padding-right: 20px;
  }

  & .input-field {
    margin-top: -5px;
    width: 115px;
  }

  :global(.ea-input__addon--auto-status) {
    display: none;
  }
}
