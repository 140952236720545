@import '../../../main.module';

.subheader-box {
  @include breakpoint(phone-large-and-down) {
    padding-left: 5%;
    padding-right: 5%;
  }

  border-bottom: 0;

  .subheader-box-texts {
    color: $focused-text-color;
    padding-bottom: 30px;
    padding-left: 65px;

    .small {
      font-size: $regular-text-size;
      margin-bottom: 8px;
    }

    .big {
      @include breakpoint(phone-large-and-down) {
        font-size: $small-title-text-size;
      }

      font-size: $large-title-text-size;
      font-weight: $title-font-weight;
      line-height: $large-title-text-size;
      overflow-x: hidden;
      padding-bottom: 15px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @include breakpoint(phone-large-and-down) {
      padding-bottom: 47px;
      padding-left: 0;
    }
  }
}
