@import '../../../../main.module';

.caller-input {
  padding-left: 40px;
  padding-top: 0;
  width: 350px;
}

.specific-validation-error {
  color: $error-text-color;
  font-size: $regular-text-size;
  margin-bottom: 12px;
}

.radio {
  box-sizing: content-box;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.text {
  font-size: $regular-text-size;
  font-weight: $title-font-weight;
  margin: 40px 0 12px;
}
