@import '../../main.module.scss';

.no-search-results-container {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 88px;
  padding-top: 88px;
  text-align: center;
  width: 50%;

  h2 {
    color: $focused-text-color;
    font-size: $large-text-size;
    font-weight: 400;
    height: 24px;
    line-height: 24px;
    margin: 0;
    position: relative;
    top: -41px;
  }
}

.avatar {
  height: 38px;
  width: 38px;
}

.user-active {
  background-color: $white;
  z-index: 101;
}

.small-table {
  display: none;
}

.lock-icon {
  margin-left: 12px;
  width: 19px;
}

.large-table {
  margin-left: 2.2em;
}

.settings-button {
  float: right;
  font-size: 1rem !important;
}

.bulk-delete-button-container {
  margin-top: 16px;
}

@include breakpoint(phone-large-and-down) {
  .small-table {
    display: block;
  }

  .large-table {
    display: none;
  }
}

.users-table-settings {
  cursor: pointer;

  &:hover {
    color: $link-color;
  }
}
