@import '../../main.module';

.subheader-box {
  & .subheader-box-texts {
    border-bottom: 1px solid $border-color;
    color: $focused-text-color;
    padding-bottom: 83px;
    padding-left: 213px;

    & .small {
      font-size: $regular-text-size;
      margin-bottom: 8px;
    }

    & .big {
      font-size: $large-title-text-size;
      font-weight: $title-font-weight;
    }
  }
}

.link {
  padding-left: 106px;
}
