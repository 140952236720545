@import '../../../../main.module';

.confirm-description {
  color: $default-text-color;
  font-weight: $default-font-weight;
}

.confirm-description-extended {
  color: $default-text-color;
  font-weight: $title-font-weight;
}
