@import '../../../main.module.scss';

.center-vertically {
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.container {
  padding: 3em;
  padding-top: 1em;

  .process-modal {
    top: 40% !important;
  }

  & .total-info {
    color: $label-color;
    font-size: $xs-text-size;
  }

  & .button-area {
    background-color: $light-background-color;
    bottom: 0;
    box-shadow: 0 3px 22px 0 $light-shadow-color;
    left: 0;
    padding-bottom: 6px;
    padding-right: 250px;
    padding-top: 6px;
    position: fixed;
    text-align: right;
    width: 100%;
    z-index: 101;

    & .cancel-button {
      background-color: $light-background-color;
      padding-right: 40px;
    }

    @include breakpoint(tablet-and-down) {
      padding-right: 33px;
    }
  }

  & .top-area {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    margin-top: 1em;

    &--sub {
      display: flex;

      &--input {
        margin-left: 2em;
      }
    }
  }

  .infobox {
    max-width: initial;
    margin-bottom: 2em;

    & .strong {
      font-weight: bold;
    }

    & .icon-area {
      display: flex;
      padding-top: 1em;
    }

    & .icon-title {
      margin-left: 0.5em;
      margin-top: 0.5em;
    }
  }
}
