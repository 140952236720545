@import '../../../../../../../main.module';

.date-table {
  width: 100%;

  th {
    border-bottom: 1px solid #1f0d0d;
    padding: 8px;
    text-align: left;
  }

  tr {
    border-bottom: 1px solid #cfcece;
  }

  td {
    padding: 8px;
  }
}

.button {
  margin-top: 10px;
}

.flex-container {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  column-gap: 34px;
}

.section-title {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 5px;
}

.link-button {
  padding: 0 !important;
  text-transform: none !important;
}

.button-section {
  text-align: right;
  margin: 15px;
}

.template-selector {
  display: flex;
  align-items: center;

  .template-tooltip {
    margin-left: 80px;
    font-size: smaller;
    max-width: 200px;
    overflow: hidden;
  }

  .radio-group {
    margin-bottom: 8px;
    margin-top: 16px;

    .last-item {
      border-bottom: 1px solid $color-smoke;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }
}

.weekdayschedule {
  width: 860px;
  overflow: hidden;
}

.modal-content {
  @include breakpoint(laptop-and-up) {
    width: 600px;
    padding: 25px;
  }
}
