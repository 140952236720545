@import '../../main.module.scss';

.result {
  cursor: pointer;
  outline: none;
  overflow-x: hidden;
  padding: 12px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected {
  background-color: $light-background-color;
}

.invalid {
  color: $light-gray-color;
}
