@import '../../main.module.scss';

.content-container {
  @include breakpoint(laptop-and-up) {
    padding-left: 106px;
  }

  padding-bottom: 45px;
  padding-left: 15px;
  padding-top: 33px;

  & .no-callflows-area {
    @include breakpoint(tablet-and-down) {
      text-align: center;
    }

    max-width: 1024px;
    padding-top: 60px;

    & .image-area {
      @include breakpoint(laptop-and-up) {
        text-align: center;
      }

      margin-bottom: -20px;
    }

    & .text-area {
      @include breakpoint(tablet-and-down) {
        text-align: center;
        width: 100%;
      }

      @include breakpoint(laptop-and-up) {
        text-align: center;
        width: 100%;
      }

      & .title-text {
        color: $focused-text-color;
        font-size: $large-text-size;
        padding-bottom: 12px;
        text-align: center;
      }

      & .info-text {
        color: $label-color;
        font-size: $xs-text-size;
        line-height: 18px;
        text-align: center;
      }
    }
  }

  & .search-container {
    @include breakpoint(tablet-and-down) {
      height: 150px;
      margin: 0;
      max-width: 400px;
      padding-top: 20px;
    }

    display: flex;
    justify-content: space-between;
    height: 65px;
    max-width: 1047px;

    & .search-area {
      display: flex;
    }

    & .search-name {
      @include breakpoint(laptop-and-up) {
        float: left;
        margin-right: 21px;
      }

      float: none;
    }

    & .search-view-mode {
      @include breakpoint(tablet-and-down) {
        display: none;
      }

      float: right;
    }
  }

  h4 {
    @include breakpoint(laptop-and-up) {
      font-family: 'Open Sans', sans-serif;
      font-size: $small-title-text-size;
      margin-bottom: 22px;
    }

    color: $default-text-color;
    font-size: $large-text-size;
    font-weight: $default-font-weight;
    line-height: 36px;
    margin-bottom: 11px;
    margin-top: 0;
  }

  .section-description {
    @include breakpoint(laptop-and-up) {
      display: flex;
      font-size: $regular-text-size;
      justify-content: space-between;
      padding-bottom: 50px;

      &--button {
        margin-top: -5px;
      }
    }

    color: $default-text-color;
    flex-wrap: nowrap;
    font-size: $xs-text-size;
    font-weight: $default-font-weight;
    line-height: 22px;
    max-width: 1048px;
    padding-bottom: 0;
  }

  .call-flows-container {
    margin-top: 30px;
    min-width: 320px;

    &--blocks {
      max-width: 1100px;
    }
  }
}

.export-button {
  height: 24px;
  margin-left: 20px;
}

.title {
  @include breakpoint(laptop-and-up) {
    display: none;
  }

  color: $focused-text-color;
  font-size: $xsmall-title-text-size;
  font-weight: $title-font-weight;
  padding-left: 15px;
  padding-top: 15px;
}

.category-field {
  width: 300px;
}

/* stylelint-disable */
:export {
  laptopMinWidth: 960;
  desktopMinWidth: 1400;
}
/* stylelint-enable */
