@import '../../../main.module.scss';
@import './DepartmentTreeList.module.scss';

.card {
  cursor: pointer;
  display: block;
  margin-bottom: 10px;
  min-height: $department-treelist-card-height + px;
  outline: none;
  padding: 14px;

  &.selected {
    background-color: $light-background-color;
  }

  .content {
    display: inline-block;

    .icon {
      display: inline-block;
      width: 20px;

      i {
        font-size: 17px;
        margin-right: 8px;
        position: relative;
        top: 2px;
      }
    }

    %name-generic {
      display: inline-block;
      font-size: $regular-text-size;
      font-weight: $title-font-weight;
      padding-left: 13px;
      width: 214px;
    }

    .name {
      @extend %name-generic;

      color: $dark-gray;
    }

    .name-selected {
      @extend %name-generic;

      color: $focused-text-color;
    }

    .arrow {
      background: url('/assets/public/arrow-navi.svg') no-repeat center;
      display: inline-block;
      float: right;
      height: 22px;
      padding-left: 9px;
      width: 9px;
    }
  }

  .links {
    color: $default-text-color;
    padding: 31px 0 0;

    span {
      font-size: 25px;
      padding-left: 30px;

      .text {
        font-family: $default-font;
        font-size: 13px;
        line-height: 18px;
        padding-left: 10px;
        text-decoration-line: underline;
        vertical-align: 5px;
      }

      .add-department {
        font-family: $default-font;
        font-size: 13px;
        line-height: 18px;
        margin-left: -33px;
        vertical-align: -10px;
      }

      &:hover {
        color: $focused-text-color;
      }
    }
  }
}
