@import '../../main.module';

.state-area {
  border: 1px solid $dark-border-color;
  padding: 2em;
  width: 15em;

  @include breakpoint(tablet-and-down) {
    margin-left: 2em;
  }
}

.title {
  font-size: 1.25em;
  font-weight: $title-font-weight;
  padding-bottom: 2em;
}

.availability-field {
  display: flex;
  padding-bottom: 2em;

  .availability-icon {
    margin-top: 0.5em;
    margin-right: 0.3em;
  }
}

.extra-info-field {
  padding-bottom: 1em;
  overflow: hidden;

  &--title {
    font-weight: $title-font-weight;
  }
}

.state-info-field {
  &--title {
    font-weight: $title-font-weight;
  }
}

.edit-field {
  color: #00239d;
  cursor: pointer;
  padding-top: 1em;

  & .edit-message {
    padding-left: 0.5em;
  }
}

.delete-field {
  color: #00239d;
  cursor: pointer;
  padding-top: 1em;

  & .delete-message {
    padding-left: 0.5em;
  }
}
