@import '../../../../main.module';

.grid-container {
  display: grid;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  width: 100%;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(0 0 0 / 50%);
    border-radius: 8px;
  }

  & .grid-sub-container {
    margin-top: 20px;
    position: relative;
    width: 100%;

    & .grid-element {
      cursor: pointer;
      height: 125px;
      position: absolute;
      width: 125px;
    }

    %background-generic {
      border: 1px solid $grid-line-color;
      position: absolute;
      z-index: 1;
    }

    .background-horizontal {
      @extend %background-generic;

      height: 0;
      left: 60px;
      top: 35px;
      width: 105px;
    }

    .background-vertical {
      @extend %background-generic;

      height: 95px;
      left: 62px;
      top: 60px;
      width: 0;

      &--up {
        top: -75px;
      }
    }

    .content {
      position: absolute;
      top: 0;
      z-index: 2;
    }
  }
}
