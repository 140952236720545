@import '../../../main.module.scss';

.absolute-time-row {
  display: flex;

  & .link {
    text-align: left;
    width: 360px;
  }

  & .remove-button {
    font-size: 14px;
    padding-left: 20px;
  }
}
