@import '../../../../main.module.scss';

.lock-icon {
  width: 20px;

  & g {
    fill: $light-gray-color;
  }
}

.container {
  background: $white;
  padding: 16px;
  position: relative;
  z-index: 0;

  & .small-title {
    display: none;
  }

  @include breakpoint(phone) {
    & .large-title {
      display: none;
    }

    & .small-title {
      display: block;
    }
  }
}

.badge-container {
  padding-top: 20px;
  padding-bottom: 20px;

  & span {
    font-size: 0.9em !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
}

.dialog-container {
  z-index: 101;

  .dialog-content {
    padding: 20px;
    background-color: $white;
    border: 2px solid $border-color;
    border-radius: 2px;
    box-shadow: 0 0 0 9999px $light-shadow-color, 0 0 15px $light-shadow-color;
    box-sizing: border-box;
    height: auto;
    left: 20%;
    padding-right: 15px;
    padding-top: 15px;
    position: fixed;
    top: 20%;
    z-index: 102;
    max-width: 600px;

    @include breakpoint(phone) {
      left: 0;
    }

    .dialog-buttons {
      text-align: right;
    }
  }
}

.static-dropdown {
  width: 405px;
}

.info {
  padding-right: 20px;
  padding-top: 0.7em;
  width: 100%;

  @include breakpoint(phone-large-and-up) {
    .personal-call-forwardings {
      width: 300px;

      &--edit {
        width: 100%;
      }
    }
  }

  @include breakpoint(tablet-and-down) {
    padding-right: 0;
  }

  @include breakpoint(phone) {
    & h2 {
      font-size: $xsmall-title-text-size;
    }
  }

  &--open {
    padding-left: 0;
  }

  & .link-button {
    padding-bottom: 36px;
    text-decoration: underline;
  }

  & .title {
    padding: 0;
  }

  & .forwarding {
    border-top: $global-border-normal solid $color-smoke;
    padding-bottom: 8px;
    padding-top: 4px;

    .disabled-row {
      color: $label-color;
    }

    .forwarding-row {
      font-size: $regular-text-size;
      padding-bottom: 10px;

      @include breakpoint(phone) {
        font-size: $small-text-size;
      }

      & img {
        padding-right: 20px;
      }
    }
  }

  & .form-section {
    @include breakpoint(tablet-and-down) {
      padding-left: 0;
    }

    & .individual-field-area {
      display: flex;

      & .hide-field {
        padding-left: 30px;
        padding-top: 30px;
      }

      & .hide-field-more-padding {
        padding-left: 135px;
        padding-top: 10px;
      }

      &--view {
        width: 305px;
      }

      & .input-field {
        width: 405px;

        &--forceview {
          width: 343px;
        }
      }

      & .search-field-area {
        width: 405px;
      }

      & .static-dropdown {
        padding-left: 0;
        padding-right: 0;
      }
    }

    & h2 {
      font-family: $default-font;
      font-size: 24px;
      padding-bottom: 20px;
    }

    @include breakpoint(phone) {
      width: 100%;
    }
  }

  & .edit-area {
    display: flex;
    padding-bottom: 73px;

    & :global(.ds-dropdown__options) {
      position: initial;
    }

    & .accordion-header {
      width: 100%;
    }

    & .form-section {
      margin-top: 20px;

      &--last {
        padding-top: 5em;
      }

      &--edit {
        width: 100%;
      }
    }

    & .field-area {
      display: flex;
      flex-flow: column wrap;
      margin-top: 33px;
      padding-top: 33px;
      width: 100%;
    }

    & .left-container {
      display: flex;

      & .avatar-container {
        padding-right: 40px;
      }

      & .action-container {
        padding-top: 53px;
        width: 300px;

        div {
          margin-bottom: 15px;
        }
      }
    }

    @include breakpoint(phone-large-and-down) {
      & .left-container {
        flex-flow: column wrap;
        text-align: center;

        & .action-container {
          display: flex;
          justify-content: center;
          padding-top: 0;
          width: 100%;

          div {
            text-align: left;
            width: 250px;
          }
        }
      }
    }

    @include breakpoint(tablet-and-down) {
      flex-flow: column wrap;
      margin-left: 0;
      padding-top: 25px;

      & .accordion-header {
        padding-left: 0;
        width: 100%;
      }

      & .form-section {
        padding: 0;
        width: 100%;

        & .input-field {
          width: 100%;
        }

        & .search-field-area {
          width: 100%;
        }

        :global(.ds-input--inputarea-textarea) {
          width: 100% !important;
        }
      }

      & .left-container {
        & .avatar-container {
          padding-right: 0;
          margin-right: 0;
          text-align: center;
        }

        & .action-container {
          padding-right: 0;
          margin-right: 0;
        }
      }

      & .field-area {
        margin-top: 0;
        padding-top: 0;
        width: 100%;
      }

      & .individual-field-area {
        width: 100%;
      }

      & .static-dropdown {
        padding-left: 2em;
        padding-right: 2em;
        width: 100%;
      }
    }

    & .button-area {
      background-color: $light-background-color;
      bottom: 0;
      box-shadow: 0 3px 22px 0 $light-shadow-color;
      padding-bottom: 6px;
      padding-right: 33px;
      padding-top: 6px;
      position: fixed;
      right: 0;
      text-align: right;
      width: 100%;
      z-index: 101;

      & .cancel-button {
        background-color: $light-background-color;
        padding-right: 40px;
      }
    }
  }

  .search-results {
    background: $white;
    border: 1px solid $border-color;
    border-top: 0;
    margin-top: -1.5em;
    position: absolute;
    width: 300px;
    z-index: 100;
  }

  .search-content {
    width: 100%;

    &--last {
      margin-bottom: 17px;
    }
  }

  .list-edit-item {
    display: inline-block;
    width: 100%;

    &--label {
      float: left;
    }

    &--id {
      color: $light-text-color;
    }
  }

  .list-view-item {
    display: inline-block;
    width: 100%;

    &--label {
      color: $dark-gray;
      cursor: pointer;
      float: left;
      text-decoration: underline;
    }
  }

  .remove-button {
    color: $focused-text-color;
    cursor: pointer;
    float: right;
    font-size: $xs-text-size;
    font-weight: $title-font-weight;
  }

  & :global(.ea-combobox__options) {
    margin-left: 2px;
  }

  & .no-content {
    color: $label-color;
    font-style: italic;
  }

  & .reset-title {
    font-size: $large-text-size;
    font-weight: $title-font-weight;
    line-height: 24px;
    padding-bottom: 12px;
  }

  & .reset-info {
    color: $label-color;
    padding-bottom: 25px;
    width: 320px;
  }

  & .error-message {
    color: $error-text-color;
    font-size: $regular-text-size;
    margin-top: 2px;
  }

  & .radio {
    & :global(input + label) {
      margin: 0 0 10px;
      padding: 0;
    }

    & :global(.ea-input__label) {
      color: $label-color;
    }

    & :global(.ea-radio__icon) {
      height: 26px;
      margin-right: 10px;
      width: 26px;
    }

    & :global(input:checked:focus + label .ea-radio__icon) {
      box-shadow: none;
    }

    & :global(.ea-radio__icon-inner) {
      border-width: 4px;
    }
  }

  & .send-reset-button-container {
    width: 100%;
    text-align: right;

    & .send-reset-button-error {
      margin-top: 7px;
    }
  }
}

.notification {
  display: flex;
}

.notification-text {
  background: transparent;
  color: $black;
  font-size: $large-text-size;
  font-weight: $title-font-weight;
  line-height: 24px;
  padding: 0;
  width: 300px;
}

.notification-icon {
  color: $green;
  height: 20px;
  margin-right: 12px;
  width: 20px;
}

.notification-icon--error {
  color: $error-text-color;
  height: 20px;
  margin-right: 12px;
  width: 20px;
}

:global(.ds-input--inputarea-textarea) {
  width: 405px !important;
}

:global(#language .ds-dropdown__options) {
  position: initial;
}

:global(.ds-accordion__section--open .ds-accordion__section__content) {
  height: 100% !important;
}
