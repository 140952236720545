@import '../../main.module.scss';

.container {
  color: $label-color;
  font-size: $xs-text-size;
  line-height: 18px;
  padding-bottom: 100px;
  padding-top: 100px;
  text-align: center;
}
