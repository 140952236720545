@import '../../main.module.scss';

.loading-view {
  left: 50%;
  margin-top: -60px;
  position: absolute;
  top: 50%;
}

.page-container {
  @include breakpoint(tablet-and-down) {
    background-color: $white;
  }

  @include breakpoint(laptop-and-up) {
    background: url('/assets/public/login-bg.svg') no-repeat center bottom;
    background-size: cover;
  }

  min-width: 320px;
  padding-bottom: 100px;

  .content-container {
    margin: auto;
    max-width: 460px;
    position: relative;
    z-index: 100;

    h4 {
      color: $default-text-color;
      font-family: $default-font;
      font-size: $xs-text-size;
      line-height: 24px;
    }

    & > * {
      @include breakpoint(phone-large-and-down) {
        padding: 10px 5%;
      }

      @include breakpoint(tablet) {
        padding: 22px 10%;
      }

      @include breakpoint(laptop-and-up) {
        padding: 39px 47px;
      }

      background-color: $white;
    }

    .extra-content {
      margin-bottom: -20px;
    }

    .input-container {
      @include breakpoint(phone-large-and-down) {
        max-width: 300px;
      }

      margin-top: 20px;
      width: 295px;
    }

    .input {
      @include breakpoint(phone-large-and-down) {
        max-width: 300px;
      }

      width: auto;
    }

    .title {
      @include breakpoint(laptop-and-up) {
        background-color: transparent;
      }

      @include breakpoint(phone-large) {
        padding-bottom: 94px;
      }

      @include breakpoint(phone) {
        padding-bottom: 1em;
        padding-top: 1em;
      }

      background-color: $white;
      color: $focused-text-color;
      font-family: $large-heading-font;
      font-size: 64px;
      line-height: 64px;
      padding-top: 92px;
      text-align: center;
    }

    .language {
      @include breakpoint(laptop-and-up) {
        background-color: transparent;
      }

      @include breakpoint(phone-large-and-down) {
        margin-right: 5%;
      }

      @include breakpoint(tablet) {
        margin-right: 10%;
      }

      padding: 0;
      position: absolute;
      right: 0;
      top: 230px;
    }

    .content {
      color: $default-text-color;
      font-family: $default-font;

      h1 {
        font-size: $small-title-text-size;
        font-weight: $default-font-weight;
        padding-bottom: 10px;
      }

      h2 {
        font-size: $xs-title-text-size;
        font-weight: $title-font-weight;
        padding: 25px 0;
      }

      p {
        font-size: $regular-text-size;

        &.error {
          color: $error-text-color;
          margin-top: 0;
        }

        img {
          display: block;
          margin: 46px auto;
          width: 387px;
        }
      }

      a {
        color: $link-color;
        text-decoration: underline;
      }
    }
  }
}

.login-input {
  @include breakpoint(laptop-and-up) {
    width: 365px;
  }
}

.login-description {
  background-color: $bg-blue;
  color: $white;
  font-family: $default-font;
  font-size: $xs-text-size;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 20px;
  padding: 20px;
}

.button-area {
  text-align: center;
}

.login-button {
  margin-bottom: 1em;
}

/* Fix footer problem where it displayed incorrectly on small screens */
:global(.login-page .ea-fgrid__item--phone-large-ratio-dynamic-75::before) {
  padding-top: 0;
}

.notif-error {
  background-color: $error-background-color;
  background-image: url('/assets/error-icon.svg');
  background-position: 12px 13px;
  background-repeat: no-repeat;
  padding-left: 48px;
}
