@import '../../../main.module';

.menu-item {
  color: $default-text-color;
  cursor: pointer;
  padding-top: 36px;

  &:hover {
    color: $link-color;
  }
}

.menu-item-link-button {
  color: $default-text-color;
  white-space: nowrap;

  &:hover {
    color: $link-color;
  }
}
