@import '../../main.module.scss';

.error {
  float: left;
  margin-bottom: 200px;
  margin-right: 107px;
  margin-top: 42px;
  padding-left: auto;
  padding-right: auto;
  text-align: center;
  width: 100%;

  .message {
    color: $focused-text-color;
    font-size: $large-text-size;
    letter-spacing: 0.67px;
    line-height: 24px;
    padding-bottom: 40px;
  }
}
