@charset "UTF-8";

$global-borderradius: 2px;
$global-borderradius-thick: 3px;

$global-border-normal: 1px;
$global-border-thick: 2px;
$global-border-focus: 3px;

$loading-indicator-line-width: 4px;

$global-font-normal: 'Open Sans', arial, sans-serif;
$global-font-brand-book: 'Jost Bold', sans-serif;
$global-font-brand: 'Jost Regular', sans-serif;
$global-font-brand-bold: 'Jost Medium', sans-serif;
$global-font-brand-light: 'Jost Light', sans-serif;
$global-font-icon: 'Elisaicons';
$global-font-icon-custom: 'Elisaicons-custom';

/* colors used */

$color-blue: #0019af;
$color-orange: #ff6400;
$color-black: #000;
$color-white: #fff;
$color-coal-grey: #303031;
$color-dark-grey: #404041;
$color-grey: #767677;
$color-light-grey: #b0b0b1;
$color-dark-smoke: #d0d0d1;
$color-smoke: #e0e0e1;
$color-light-smoke: #fafafa;
$color-white-smoke: #f5f5f5;
$color-linkblue: #006dfc;
$color-dark-blue: #002447;

$color-cyan: #00baf2;
$color-light-cyan: #aae0fa;
$color-light-orange: #fcaf17;
$color-yellow: #ffd503;
$color-green: #00a950;
$color-medium-green: #54bc7b;
$color-light-green: #a1d5ae;

$color-error: #e44;

/* global margins */
$global-spacing-mobile: 1.6rem;
$global-spacing-desktop: 1.6rem;

$global-base-font-size: 16px;

$global-baseline: 12px;
$global-column-cap: 20px;
$global-padding-horizontal-s: $global-column-cap;
$global-padding-horizontal-m: 1.5 * $global-column-cap;
$global-padding-horizontal-l: 2 * $global-column-cap;
$global-padding-horizontal-xl: 3 * $global-column-cap;
$global-fluid-spacing: 2.5vw;

$global-columns-small-screen: 4;
$global-columns-big-screen: 12;

$global-columns-small-screen-fluid: 6;
$global-columns-big-screen-fluid: 12;

$global-transparency: 0.95;
$global-color-press-darken: 4%;
$global-color-hover-tint: 15%;

/* Z-index defaults */
$zindex1: 10;
$zindex2: 20;
$zindex3: 30;
$zindex4: 40;
$zindex5: 50;
$zindex6: 60;
$zindex7: 70;
$zindex8: 80;
$zindex9: 90;

$zindex-icons: $zindex1;
$zindex-dropdowns: $zindex2;
$zindex-overlay: $zindex8;
