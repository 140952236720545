@import '../../main.module.scss';

.row {
  min-height: 60px;
  position: relative;
}

.action-cell {
  align-self: center;
}

.title-row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.user-details {
  display: flex;
}
