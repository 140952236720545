@import '../../../main.module.scss';

.card {
  @include breakpoint(tablet-and-down) {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    height: 109px;
    text-align: left;
    width: 84px;

    :global(.UserAvatar--inner) {
      border-radius: 42px !important;
      height: 84px !important;
      width: 84px !important;
    }
  }

  @include breakpoint(phone-large-and-down) {
    height: 60px;
    width: 50px;

    :global(.UserAvatar--inner) {
      border-radius: 25px !important;
      font-size: $regular-text-size;
      height: 50px !important;
      width: 50px !important;
    }
  }

  background-color: $white;
  border: 1px solid $alternative-shadow-color;
  box-shadow: 3px 3px 9px 3px $light-shadow-color;
  cursor: pointer;
  display: inline-block;
  height: 182px;
  margin-bottom: 10px;
  outline: none;
  text-align: center;
  vertical-align: top;
  width: 182px;

  .avatar-container {
    margin: 23px auto 35px;

    & > div {
      background-color: transparent;
      border: 0;
    }

    @include breakpoint(tablet-and-down) {
      margin-bottom: 20px;
      margin-top: 0;
    }

    @include breakpoint(phone-large-and-down) {
      height: 55px;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .text-container {
    margin-top: 5px;
    position: relative;

    &--medium {
      display: none;
    }

    @include breakpoint(tablet-and-down) {
      &--medium {
        display: block;
      }

      &--large {
        display: none;
      }
    }

    .text-container-wrapper {
      @include breakpoint(tablet-and-down) {
        padding-left: 0;
        padding-right: 0;
        width: 84px;
      }

      @include breakpoint(phone-large-and-down) {
        padding-left: 0;
        padding-right: 0;
        width: 50px;
      }

      left: 1em;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 9em;

      h4 {
        @include breakpoint(tablet-and-down) {
          text-align: center;
        }

        @include breakpoint(phone-large-and-down) {
          font-size: $xs-text-size;
        }

        color: $black;
        font-family: $default-font;
        font-size: $regular-text-size;
        font-weight: $default-font-weight;
        line-height: 20px;
        margin: 0;
      }

      .title {
        color: $label-color;
        font-size: $xs-text-size;
        line-height: 18px;
      }
    }
  }
}
