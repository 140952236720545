@import '../../../main.module.scss';

.modal {
  padding-left: 89px;
  padding-right: 60px;
  padding-top: 55px;
  width: 1261px;
}

.name-field {
  width: 405px;
}

.selector {
  cursor: default;
  margin-bottom: 14px;

  .selection {
    border-bottom: 3px solid $focused-text-color;
    color: $focused-text-color;
    font-size: $regular-text-size;
    height: 45px;
    line-height: 30px;
    margin-left: 9px;
    margin-right: 9px;
    text-align: center;
    width: 132px;
  }
}

.button-container {
  margin-bottom: 40px;
  margin-top: 91px;
}

.section-title {
  color: $default-text-color;
  font-size: $small-title-text-size;
  font-weight: $default-font-weight;
  line-height: 36px;
  margin-top: 0;
}

.close-button {
  margin-left: 20px;

  &:disabled {
    border-color: $border-color;
    color: $light-text-color;

    &:hover {
      border-color: $border-color;
      color: $light-text-color;
    }
  }
}

.user-selector-title {
  font-size: $large-text-size;
  margin-bottom: 30px;
  margin-top: 50px;
}

.move-user-indicator {
  padding-bottom: 150px;
}

.save-error {
  color: $error-text-color;
}
