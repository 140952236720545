@import '../main.module.scss';

%generic-item {
  margin-bottom: 17px;
  overflow: hidden;

  .label {
    color: $label-color;
    font-size: $xs-text-size;
    line-height: 18px;
    margin-bottom: 1px;
  }

  .value {
    color: $dark-gray;
    line-height: 24px;
    word-break: break-all;
  }
}

.item {
  @extend %generic-item;

  .link {
    cursor: pointer;
    text-decoration: underline;
  }
}

.disabled-item {
  @extend %generic-item;

  .label {
    color: $light-text-color;
  }

  .value {
    color: $light-text-color;
  }

  .link {
    color: $light-text-color;
  }
}
