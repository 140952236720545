@import '../../main.module.scss';

.image-container {
  margin-bottom: 1.5em;
  margin-top: 1.5em;
}

.button-container {
  margin-bottom: 0.4em;
  margin-top: 0.4em;
}

.box {
  align-items: center;
  background-color: $focused-text-color;
  border: 0;
  color: $light-background-color;
  display: flex;
  flex-direction: column;
  font-family: $default-font;
  font-size: $small-title-text-size;
  font-weight: $title-font-weight;
  height: 9em;
  justify-content: center;
  text-align: center;
  width: 9em;
}

input {
  &[type='range'] {
    -webkit-appearance: none;
    background: $content-border-color no-repeat;
    background-size: 70% 100%;
    height: 0.2em;
    margin-right: 15px;
    width: 16em;
  }

  &[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: $grid-line-color;
    border-radius: 50%;
    cursor: ew-resize;
    height: 1em;
    transition: background 0.3s ease-in-out;
    width: 1em;
  }
}

.inline {
  align-items: center;
  background-color: transparent;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  color: $grid-line-color;
  display: flex;
  padding: 0;

  .element {
    margin-left: 0.75em;
  }
}

.error {
  color: $invalid-data-color;
}
