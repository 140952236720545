@import '../../main.module.scss';

.menu-icon {
  cursor: pointer;

  &:hover {
    & path {
      fill: $link-color;
    }
  }
}

.default-fwd {
  color: $label-color;
  display: inline-block;
  font-weight: $light-font-weight;
  white-space: normal;
  word-break: break-word;
}

.inactive-fwd {
  color: $light-text-color;
  margin-left: 8px;
  white-space: normal;
  word-break: break-word;
}

.destinations-row {
  white-space: normal;

  & :global(.ds-popover) {
    max-width: initial !important;
  }
}

.text-area {
  margin-right: 8px;
  white-space: normal;
  word-break: break-word;
}

@include breakpoint(tablet-and-up) {
  .destinations-row {
    display: flex;
    justify-content: space-between;

    & div {
      & .text-area {
        display: inline-block;
        hyphens: none;
      }
    }
  }
}

@include breakpoint(phone-large) {
  .destinations-row {
    & svg {
      margin-left: 10px;
      margin-right: 20px;
    }
  }
}

@include breakpoint(phone) {
  .destinations-row {
    margin-bottom: 16px;

    & svg {
      margin-left: 0;
      margin-right: 15px;
    }
  }
}

.small-destination {
  & svg {
    margin-right: 20px;
  }
}
