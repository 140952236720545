@import '../../main.module.scss';

.item {
  display: flex;
}

.dismiss {
  padding-top: 0.8em;
}

.search-content {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;

  .search-input-container {
    :global(.ea-input) {
      max-width: 100%;
      padding-top: 0;
    }

    .search-results {
      background-color: $white;
      border-bottom: 1px solid $border-color;
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      margin-top: -13px;
      max-height: 254px;
      overflow: auto;
      width: 100%;

      .result-user-item {
        cursor: pointer;
        display: inline-flex;
      }

      .first-result-item {
        display: block;
        padding-top: 8px;
      }
    }
  }

  .selected-container {
    border-right: 0;
    margin-top: 32px;

    .selected-description-small {
      display: block;
      display: none;
    }

    .select-users {
      color: $label-color;
      padding-top: 57px;
    }

    .selected-item {
      display: flex;
      justify-content: space-between;

      .selected-user-item {
        display: flex;
        width: 100%;
      }

      :global(.ea-icon--cross) {
        font-size: 18px;
      }
    }
  }

  .buttons-container {
    position: relative;
  }
}

.deselect-button {
  display: none;
  margin-right: 165px;

  &:disabled {
    background-color: $border-color;
  }
}

.select-button {
  display: none;

  &:disabled {
    background-color: $border-color;
  }
}

.specific-validation-error {
  color: $error-text-color;
  font-size: $regular-text-size;
  margin-bottom: 12px;
}

.link-button {
  background-color: transparent;
  border: 0;
  color: $link-color;
  height: 34px;
  padding-left: 38px;
  text-align: left;
  width: 100%;
}

.show-more-less-link-button {
  background-color: transparent;
  border: 0;
  color: $link-color;
  padding-left: 50px;
  text-align: left;
  width: 100%;
}

.hide {
  visibility: hidden;
}
